import {AxiosInstance} from "axios";

interface PalcoVirtualInterface {
	getSettings(): Promise<any>;
	getHealth(): Promise<any>;
}
export class PalcoVirtualService implements PalcoVirtualInterface {
	constructor(private readonly api: AxiosInstance) {}
	
	async getSettings(): Promise<any> {
		return await this.api.get('/settings');
	}
	
	async getHealth(): Promise<any> {
		return await this.api.get('/health');
	}
	
}