import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Auditions from '@components/Auditions';
import NoContent from '@components/NoContent';
import useSession from '@context/session/SessionHook';
import BiometricsDebug from '@components/Biometrics';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 0 }}>
					<Typography component="div">{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function BasicTabs() {
	const [value, setValue] = React.useState(0);

	const { auditions } = useSession();

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
					<Tab label="Suas Gravações" {...a11yProps(0)} />
					<Tab label="Suas Biometrias" {...a11yProps(1)} />
					{/* <Tab label="Suas Estatísticas" {...a11yProps(2)} /> */}
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				{
					auditions && auditions.user_existing_auditions.length > 0 ? <Auditions /> : <NoContent />
				}
			</TabPanel>
			<TabPanel value={value} index={1}>
				{/* <NoContent /> */}
				<BiometricsDebug />
			</TabPanel>
			{/* <TabPanel value={value} index={2}>
                <NoContent />
            </TabPanel> */}
		</Box>
	);
}