import axios from "axios";

export const ecossistema = axios.create({
	baseURL: "https://prod-api-ecossistema.aiamis.com.br/api/ecossistema",
	// baseURL: "https://homolog-api-ecossistema.aiamis.com.br/api/ecossistema",
});

ecossistema.interceptors.request.use((config) => {
	let user: any = localStorage.getItem("@user");
	let token: any = localStorage.getItem("token");

	if (token && config.headers) {
		user = JSON.parse(user);
		token = JSON.parse(token);

		config.headers["Authorization"] = `Bearer ${token}`;
		config.headers["ra"] = user?.ra;
	}

	return config;
});

ecossistema.interceptors.response.use((response) => {
		return response;
	}, (error) => {
		return Promise.reject(error);
	})