import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function NoContent() {
	return (
		<>
			<Box sx={{
				width: "auto",
				height: "256px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				padding: "0 10%",
				textAlign: "center"
			}}>
				<Typography variant="h5" component="div">Nenhum conteúdo disponível</Typography>
				<Typography variant="body1" component="div">Não foi possível encontrar nenhuma informação nesta seção, por favor, retorne ao início e tente novamente.</Typography>
			</Box>
		</>
	)
}