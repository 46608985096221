import { fileManagerApi } from "@api/services/filemanager";
import MiniInfo from "@components/Dialogs/MiniInfo";
import useAuth from "@context/auth/AuthHook";
import useSession from "@context/session/SessionHook";
import { ActivityService } from "@modules/activity/activity.service";
import { Add } from "@mui/icons-material";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { Container } from "@mui/system";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import BiometryService from "../biometry/biometry.service";
import AuditionService from "./audition.service";
import ActivityTable from "./tables/activity.table.component";

/**
 *
 * @returns JSX Do Dashboard de usuário
 */
export default function StudentComponent() {
	const theme = useTheme();
	const { state } = useAuth();
	const {
		setActiveStep,
		setVideos,
		setImages,
		images,
		auditions,
		setAuditions,
	} = useSession();
	const [sample, setOpenSample] = useState<boolean>(false);

	useEffect(() => {
		// fileManagerApi.get(`/file-retrieve?ra=${user.ra}&type=image`)
		new BiometryService(fileManagerApi)
			.getAll(state.usuario.id)
			.then((response: AxiosResponse<any>) => {
				// setImages(response.data.files)
				setImages(response.data);
			})
			.catch((err: any) => {
				if (err.response) {
				} else if (err.request) {
					console.log(err.request);
				} else {
					console.log(err.message);
				}
				console.log(err.config);
			});

		// fileManagerApi.get(`/file-retrieve?ra=${user.ra}&type=video`)
		new AuditionService(fileManagerApi)
			.getAll(state.usuario.id, {
				transcription: 1,
				recognition: 1,
				activity: 1,
				user: 1,
			})
			.then((response: AxiosResponse<any>) => {
				setAuditions(response.data.data);
				setVideos(response.data.data);
			})
			.catch((err) => {
				if (err.response) {
				} else if (err.request) {
					console.log(err.request);
				} else {
					console.log(err.message);
				}
				console.log(err.config);
			});

		new ActivityService(fileManagerApi)
			.findAll({
				activity_id: 70,
			})
			.then((res: AxiosResponse<any>) => {
				console.log(res.data);
			})
			.catch(() => {})
			.finally(() => {});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<Container maxWidth="xl" style={styles.compound}>
				<Grid container spacing={3}>
					<Grid
						item
						p={3}
						sm={12}
						md={12}
						mt={3}
						mb={3}
						style={{
							textAlign: "center",
						}}
					>
						<Typography
							variant="h5"
							component={"h6"}
							style={styles.title}
						>
							Olá <br />
							{state && state.usuario.nome ? (
								<React.Fragment>
									<span
										style={{
											color: theme.palette.primary.main,
										}}
									>
										{state.usuario.nome}
									</span>
									<br />
								</React.Fragment>
							) : (
								<></>
							)}
							seja bem vindo(a) ao Palco Virtual
						</Typography>
						<Typography variant="body1" component={"p"}>
							Esse é o espaço destinado a apresentação em vídeo do
							seu relato de experiência. Siga as etapas para a
							submissão.
						</Typography>
					</Grid>

					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						mb={1}
						mt={0}
					>
						{/* <pre>
							{JSON.stringify(auditions, null, 4)}
						</pre> */}
						<Box
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								gap: "12px",
							}}
						>
							<Button
								variant="contained"
								disableElevation
								startIcon={<Add />}
								onClick={() => {
									setOpenSample(true);
								}}
							>
								Iniciar nova apresentação
							</Button>
							<MiniInfo
								open={sample}
								setOpen={setOpenSample}
								goAction={() => {
									if (images.length >= 5) {
										setActiveStep(1);
										window.location.href = "/recorder";
									} else {
										setActiveStep(0);
										window.location.href = "/biometrics";
									}
								}}
							/>
						</Box>
					</Grid>

					<Grid
						item
						md={12}
						sm={12}
						xs={12}
						lg={12}
						xl={12}
						mt={3}
						mb={3}
					>
						{/* {images.length}
						<pre>
							{JSON.stringify(images, null, 4)}
						</pre> */}
						<Box mb={3}>
							<Typography variant="h5" component="div">
								Listagem de Apresentações
							</Typography>
							<Typography variant="body1" component="div">
								Visualize a seguir suas apresentações submetidas
								para o Palco Virtual
							</Typography>
						</Box>
						<ActivityTable auditions={auditions} />
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	);
}

const styles = {
	compound: {
		marginTop: "36px",
		marginBottom: "10vh",
	},
	title: {
		marginBottom: "24px",
	},
	centerText: {
		textAlign: "center",
	},
};
