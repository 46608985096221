import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, IconButton, useTheme } from '@mui/material';
import { CopyAll, PlayArrow } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useState } from 'react';
import GenericDialog from '@components/Dialogs/GenericDialog';
import { fileManagerApi } from '@api/services/filemanager';
import { formatDistance } from 'date-fns';
import { pt } from 'date-fns/locale';
import NoContent from '@components/NoContent';

import { utcToZonedTime, format } from 'date-fns-tz';


export default function BasicTable(props: any) {

	const theme = useTheme();
	const [open, setOpen] = useState<boolean>(false);

	const [url, setUrl] = useState<any>(null);

	const getVideo = (path: string) => {
		fileManagerApi.get(`/video-retrieve?audition=${path}`)
			.then((response: any) => {
				setUrl(response.data.video);
				setOpen(true);
			});
	}

	return (
		<React.Fragment>
			<TableContainer
				component={Paper}
				style={{
					overflow: "hidden",
					overflowX: "scroll"
				}}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Número de Matrícula</TableCell>
							<TableCell align="left">Data de gravação</TableCell>
							<TableCell align="right">Assistir</TableCell>
							<TableCell align="center">Código da apresentação</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							props.videos.length === 0
								? <TableRow sx={{ textAlign: "center" }}>
									<TableCell colSpan={4}>
										<NoContent />
									</TableCell>
								</TableRow>
								: props.videos.map((row: any) => (
									<TableRow
										key={row.audition}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row">
											{row.ra}
										</TableCell>
										<TableCell align="left">

											<>
												{/* {row.updatedAt}<br /> */}
												{
													format(utcToZonedTime(row.updatedAt, 'America/Sao_Paulo'), 'dd/MM/yyyy HH:mm:ss', { timeZone: "America/Sao_Paulo" }).toString()
												}<br />
												{row.updatedAt
													? formatDistance(
														new Date(row.updatedAt),
														new Date(), {
														addSuffix: true,
														locale: pt
													}).toString()
													: ""
												}
											</>

										</TableCell>

										<TableCell align="right">
											<IconButton
												onClick={() => {
													getVideo(row.audition)
												}}
												size="small"
												style={{ backgroundColor: theme.palette.secondary.main, color: "white" }}>
												<PlayArrow />
											</IconButton>
										</TableCell>

										<TableCell align="right">
											<Button
												variant="text"
												startIcon={<CopyAll />}
												onClick={() => {
													navigator.clipboard.writeText(row.audition)
													toast.success("Código copiado com sucesso!", {
														position: "top-right"
													})
												}}
											>
												{row.audition}
											</Button>
										</TableCell>
									</TableRow>
								))
						}
					</TableBody>
				</Table>
			</TableContainer>

			<GenericDialog open={open} setOpen={setOpen} url={url} />

		</React.Fragment>
	);
}
