import { createContext, Dispatch, SetStateAction } from "react";

interface ActivityInterface {
	activities: any[],
	items: any[]
}
interface ContextInterface {
	state: ActivityInterface | any,
	setState: Dispatch<SetStateAction<ActivityInterface | any>>

}

export const INIT_STATE: ContextInterface = {
	state: {
		activities: [],
		items: []
	},
	setState: () => { }
}

export const ActivityContext = createContext<any>(null);