import Appbar from "@components/Appbar";
import useAuth from "@context/auth/AuthHook";
import { Alert, AlertTitle, Box, Button, Container, Grid } from "@mui/material";
import React from "react";

export default function NotAllowed() {
	const { user } = useAuth();
	return (
		<React.Fragment>
			<Appbar />
			<Container>
				<Grid container>
					<Grid item md={12}>
						<Box mt={3} mb={3}>
							<Alert
								severity="error"
								action={
									<Button
										color="inherit"
										size="small"
										onClick={() =>
											(window.location.href = "/")
										}
									>
										Retornar à navegação
									</Button>
								}
							>
								<AlertTitle> Ação não permitida!</AlertTitle>O
								usuário {user.name} não possui permissão para
								acessar este recurso.
							</Alert>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	);
}
