/**
 * 
 * @sumary      retorna o componente de gravação de vídeo
 * @typedef     Stage
 * @example		
 * return(<Stage />)
 * @module      src/pages/private/Stage/Stage.tsx
 * @component  	Stage
 * @description Componente desenvolvido para a etapa de gravação do vídeo
 * @version 	1.0.0
 * @author {@link https://github.com/gabrieldocs}
 * Date 		05/2023
 * 	
 *	
 * Ref do developer.apple -> https://developer.apple.com/forums/thread/92713
 * Ref do developer.apple
 */

import { RekognitionService } from "@api/classes/Rekognition.service";
import { fileManagerApi } from "@api/services/filemanager";
import { uploadFilesViaLambda } from "@api/services/lambda";
import ProgressIndicator from "@components/ProgressIndicator";
import useAuth from "@context/auth/AuthHook";
import useSession from "@context/session/SessionHook";
import useSettings from "@context/settings/Settings.hook";
import { Refresh, Stop, Upload, Videocam } from "@mui/icons-material";
import { Backdrop, Button, CircularProgress, Container, Grid, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { convertBase64ToBlob } from "@pages/private/Biometrics/helper";
import { AxiosResponse } from "axios";
import React, { useMemo } from "react";
import Countdown from "react-countdown";
import { toast } from "react-toastify";


export default function StageUI() {

	const theme = useTheme();

	const { user } = useAuth();
	const {
		setActiveStep,
		images,
		setImages,
		detections,
		setDetections,
		auditions,
		setAuditions,
		browserAgent
	} = useSession();

	const { $settings } = useSettings();

	const playbackRef = React.useRef<any>(null);
	const videoRef = React.useRef<any>(null);
	const downloadLinkRef = React.useRef<any>(null);
	const stopButtonRef = React.useRef<any>(null);
	const canvasRef = React.useRef<any>(null);
	const countDownRef = React.useRef<any>(null);
	const [url, setURL] = React.useState<string | null>(null);
	const [startRecording, setStartRecording] = React.useState<boolean>(false);
	const [showPreview, setShowPreview] = React.useState<boolean>(false);
	const [shouldStop, setShouldStop] = React.useState<boolean>(false);
	const [showBackdrop, setShowBackdrop] = React.useState<boolean>(false);

	/**
	 * @description Função responsável por iniciar o stream de vídeo e áudio
	 */
	const getUserAudioAndVideo = () => {
		var player = videoRef.current;
		let stopped = false;

		const handleRecording = function (stream: MediaStream) {
			// BrowserAgent true -> Safari; false -> Chrome based
			const options = { mimeType: browserAgent ? 'video/mp4' : 'video/webm' };
			const recordedChunks: any = [];
			const mediaRecorder = new MediaRecorder(stream, options)


			// Se o usuário está no Safari então usa o onstop, senão adiciona o evento por meio do listener
			if (browserAgent) {
				mediaRecorder.onstop = function () {
					downloadLinkRef.current.href = URL.createObjectURL(new Blob(recordedChunks, { type: browserAgent ? 'video/mp4' : 'video/webm' }));
					downloadLinkRef.current.download = 'acetest.mp4';

					setURL(URL.createObjectURL(new Blob(recordedChunks)));

					if (playbackRef.current) {
						try {
							playbackRef.current.src = URL.createObjectURL(new Blob(recordedChunks, { type: browserAgent ? 'video/mp4' : 'video/webm' }));
							playbackRef.current.controls = true;
							playbackRef.current.play();
						} catch (e) {
							console.error(e);
						}
					}
				}
			} else {
				mediaRecorder.addEventListener('stop', function () {
					downloadLinkRef.current.href = URL.createObjectURL(new Blob(recordedChunks));
					downloadLinkRef.current.download = 'acetest.webm';

					setURL(URL.createObjectURL(new Blob(recordedChunks)));

					if (playbackRef.current) {
						try {

							playbackRef.current.src = URL.createObjectURL(new Blob(recordedChunks));
							playbackRef.current.controls = true;
							playbackRef.current.play();
						} catch (e) {
							console.error(e);
						}
					}
				})
			}

			// captura o stream de vídeo
			mediaRecorder.addEventListener('dataavailable', function (e) {

				if (e.data.size > 0) {
					recordedChunks.push(e.data)
				}

				if (shouldStop === true && stopped === false) {
					mediaRecorder.stop()
					stopped = true
				}
			})

			// inicia o stream de vídeo
			mediaRecorder.start()

			if (stopButtonRef && stopButtonRef.current)
				stopButtonRef?.current?.addEventListener('click', function onStopClick() {
					// habilita a exibicao da previa
					setShowPreview(true);
					// interrompe o stream de video
					stopAudioAndVideoStream();
					// interrompe o recorder
					mediaRecorder.stop();
					// seta o active step para 2 (etapa de preview)
					setActiveStep(2);
					// seta o startRecording para false
					setStartRecording(false);
					// remove o evento de click do botao de stop
					stopButtonRef.current.removeEventListener('click', onStopClick);
					// interrompe o timer					
					if (countDownRef.current) {
						countDownRef.current.getApi().stop();
					}
				});

		}

		const handleSuccess = (stream: MediaStream) => {
			player.srcObject = stream;
			handleRecording(stream);
		}

		navigator.mediaDevices
			.getUserMedia({
				audio: true,
				video: {
					width: window.innerWidth < 800 ? (480 / 2) : (854 / 2),
					height: window.innerWidth < 800 ? (854 / 2) : (480 / 2)
				}
			})
			.then(handleSuccess)
			.then(() => player.play())
	}

	/**
	 * Stop audio and video stream
	 */
	const stopAudioAndVideoStream = () => {
		var stream = videoRef.current.srcObject
		var tracks = stream.getTracks()
		tracks.forEach((track: any) => {
			track.stop()
		})
		videoRef.current.srcObject = null;
		setShowPreview(true)
	}

	/**
	 * faz o upload do vídeo para o backend.
	 */
	const uploadVideoFile = async () => {
		// video 

		let formData = new FormData()
		let response = await fetch(downloadLinkRef.current)
		let videoBlob = await response.blob()

		formData.append('video', videoBlob, 'probe.webm')
		formData.append('ra', user.ra)
		formData.append('type', 'video')
		formData.append('label', 'Palco Virtual')
		formData.append('audition', auditions.user_existing_auditions_unfinished[0].audition)
		formData.append('audition_id', auditions.user_existing_auditions_unfinished[0].id)

		setShowBackdrop(true);

		await uploadFilesViaLambda(user, videoBlob, "probe.webm", auditions.user_existing_auditions_unfinished[0].audition, true)
			.then(async () => {
				await fileManagerApi.post('/media-path',
					{
						ra: user.ra,
						type: 'video',
						label: auditions.user_existing_auditions_unfinished[0].audition as string,
						path: `${process.env.REACT_APP_FILE_LOCATION}/videos/${auditions.user_existing_auditions_unfinished[0].audition}`,
						audition: auditions.user_existing_auditions_unfinished[0].audition as string,
						auditionId: auditions.user_existing_auditions_unfinished[0].id
					})
					.then(async (response: any) => {
						await fileManagerApi.put(`auditions/update?audition=${auditions.user_existing_auditions_unfinished[0].audition as string}`, {
							media: `${process.env.REACT_APP_FILE_LOCATION}/videos/${auditions.user_existing_auditions_unfinished[0].audition}`,
							media_id: response.data.id,
						})
					})
					.then(() => {
						toast.success("Vídeo enviado com sucesso", {
							position: "top-center"
						})
						setActiveStep(4)
					})
					.then(() => {
						fileManagerApi.get(`/auditions/index`, {
							params: {
								ra: user.ra
							}
						})
							.then((response: AxiosResponse<any>) => {
								setAuditions(response.data);
								localStorage.setItem("auditions", JSON.stringify(response.data));
								window.location.href = "/submission"
							})
					});
			})
			.finally(() => {
				setShowBackdrop(false)
			})
	}

	/**
	 * faz o upload da imagem do canvas para o backend.
	 * @param {*}
	 * @returns {*}
	 */
	const handleCanvasUploadLambda = async () => {
		console.log(new Date())
		if (canvasRef.current) {
			var el: HTMLCanvasElement | any = document.createElement('canvas')

			/**
			 * o trecho el.width = videoRef.current.videoWidth -> soluciona o problema de altura do canvas
			 * o trecho el.height = videoRef.current.videoHeight -> soluciona o problema de largura do canvas
			 */
			el.width = videoRef.current.videoWidth
			el.height = videoRef.current.videoHeight

			el.getContext('2d').drawImage(videoRef.current, 0, 0, videoRef.current.videoWidth, videoRef.current.videoHeight)
			canvasRef.current.appendChild(el)

			let formData = new FormData();
			formData.append('image', el.toDataURL("image/png"));

			const file = await convertBase64ToBlob(el.toDataURL("image/png"));
			const temp_postfix = new Date().getTime();
			const temp_path = `${process.env.REACT_APP_FILE_LOCATION}/${user.ra}/sample_${temp_postfix}`;

			await uploadFilesViaLambda(user, file, "image/png", `sample_${temp_postfix}`, false)
				.then(async () => {
					await fileManagerApi.post('/media-path',
						{
							ra: user.ra,
							type: 'image',
							label: 'sample',
							path: temp_path,
							// audition: auditions.user_existing_auditions_unfinished[0].audition,
							// audition_id: auditions.user_existing_auditions_unfinished[0].id
						})
						.then(async () => {
							await new RekognitionService().compareFaces({
								target: temp_path,
								source: images[Math.floor(Math.random() * 5) + 1].path ?? images[0].path,
							})
								.then(async (response: any) => {
									console.log("Passando por aqui hein")
									setDetections([
										...detections,
										{
											score: response.data.Similarity
										}
									]);
									await fileManagerApi.post('/rekognition', {
										input: response.data.Input,
										target: response.data.Target,
										similarity: response.data.Similarity,
										ra: user.ra,
										matches: JSON.stringify(response.data.matches),
										audition_id: auditions.user_existing_auditions_unfinished[0].id
									}).then((response: AxiosResponse<any>) => { })
								})
								.catch((err) => {
									// console.log(err)
								})
								.finally(() => {
									// console.log("Finalizado")
								})
						})
				})
		}
	}

	/**
	 * memoiza o tempo de execução do timer
	 */
	const time = useMemo(() => {
		return Date.now() + $settings.settings.audition_duration ?? 420000;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * renderiza o timer na tela do usuário
	 * @param param0 
	 * @returns 
	 */
	const renderer = ({ minutes, seconds, completed }: any) => {
		if (completed) {
			// setTimerOn(false)
			setShouldStop(true)
			return <span>Tempo esgotado!</span>
		} else {
			return <React.Fragment>
				<span>
					{minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}
				</span>
			</React.Fragment>;
		}
	}

	/**
	 * 	Metodo que inicia a gravação
	 * 	@description Inicia a gravação e o timer
	 * 	@author Lucas Gabriel
	 * 	30000 -> corresponde a cerca de 30 segundos
	 * 	1800000 -> corresponde a cerca de 30 minutos
	 * 	$settings.settings.audition_duration ?? 420000 -> corresponde a cerca de 7 minutos
	 * 	chamada alternativa: setInterval(handleCanvasUploadLambda, $settings.settings.rekognition_interaval ?? 10000);
	 */
	const handleStart = () => {
		const ct = countDownRef.current;

		if (ct) {
			const api = ct.getApi();
			getUserAudioAndVideo();
			api.start();
			setStartRecording(true);

			/** Descomentar para voltar a usar o rekognition */
			setInterval(handleCanvasUploadLambda, 30000);
		}
	}

	React.useEffect(() => {
		if (auditions.user_existing_auditions_unfinished.length === 0) {
			setShowBackdrop(true);
			(async () => {
				await fileManagerApi.post('/auditions/store', {
					ra: user.ra
				}).then(async (response: AxiosResponse<any>) => {
					fileManagerApi.get(`/auditions/index`, {
						params: {
							ra: user.ra
						}
					}).then((response: AxiosResponse<any>) => {
						setAuditions(response.data);
						localStorage.setItem("auditions", JSON.stringify(response.data));
					})
				})
					.finally(() => {
						setShowBackdrop(false);
					})
			})();

		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {

		fileManagerApi.get(`/file-retrieve?ra=${user.ra}&type=image`)
			.then((response: AxiosResponse<any>) => {
				setImages(response.data)
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		setActiveStep(1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Retorna o componente
	 */
	return (
		<React.Fragment>
			<Backdrop
				sx={{
					color: '#fff',
					zIndex: (theme) => theme.zIndex.drawer + 1
				}}
				open={showBackdrop}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Container>
				<ProgressIndicator />
				<Box>
					<Grid
						container
						spacing={2}>
						<Grid
							item
							p={3}
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							sx={{
								display: "none"
							}}>
							<Box
								mt={3}
								mb={1}>
								<Box
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
										textAlign: "center",
										height: "356px"
									}}>
									<Typography
										variant="h5">
										<Countdown
											date={time}
											autoStart={false}
											ref={countDownRef}
											onComplete={() => {
												stopButtonRef.current.click()
											}}
											renderer={renderer}
										/>
									</Typography>

									<Typography variant="body1">
										Clique <a ref={downloadLinkRef} href="/" style={{ textDecoration: "none", color: "#333", fontWeight: "bold" }}>aqui</a> para realizar o download do vídeo.
									</Typography>
								</Box>
							</Box>
						</Grid>

						{/*  CANVAS */}

						<Grid
							item
							p={0}
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}>
							<Box>
								<Box
									style={{
										// marginTop: "12px",
										marginBottom: '24px',
										backgroundColor: "transparent",
										borderRadius: "12px",
										width: "100%",
										position: "relative",
									}}>
									<Box style={{
										display: startRecording || showPreview
											? "flex"
											: "none",
										justifyContent: "space-evenly",
										alignItems: "center",
										zIndex: "9999",
										position: "absolute",
										color: "white",
										width: "100%",
										paddingTop: "12px",
										backgroundColor: "rgba(0, 0, 0, 0.1)"
									}}>
										<Box></Box>
										<Typography
											style={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												gap: "8px",
												color: startRecording
													? theme.palette.primary.main
													: "white",
											}}>
											<Videocam />
											{showPreview ? "Preview" : "Gravando"}
										</Typography>
										<Typography>
											<Countdown
												date={time}
												autoStart={false}
												ref={countDownRef}
												onComplete={() => {
													stopButtonRef.current.click()
												}}
												renderer={renderer}
											/>
										</Typography>
									</Box>
									<Box
										style={{
											zIndex: "9998"
										}}>
									</Box>
									{
										showPreview ?
											<React.Fragment>
												<video
													ref={playbackRef}
													height="auto"
													style={{
														backgroundColor: "#000",
														borderRadius: "4px",
														width: "100%",
													}}>
												</video>
											</React.Fragment>
											: <video
												ref={videoRef}
												autoPlay={true}
												playsInline={true}
												muted={true}
												height={window.innerWidth < 800 ? 496 : "auto"}
												style={{
													backgroundColor: "#000",
													width: "100%",
												}}>
											</video>
									}
								</Box>
							</Box>
							<Box
								style={{
									display: "flex",
									gap: "4px",
									flexGrow: "1",
								}}
								mb={3}>
								{
									startRecording || showPreview
										? <React.Fragment></React.Fragment>
										: <Button
											variant="outlined"
											sx={{ flexGrow: "1" }}
											onClick={() => {
												window.location.href = "/"
											}}>
											Cancelar
										</Button>
								}
								{
									showPreview
										? <Button
											size="medium"
											variant="contained"
											disableElevation
											onClick={() => {
												setShowPreview(false);
												setShouldStop(false);
												setURL(null);
											}}
											startIcon={<Refresh />}
											disabled={startRecording ? true : false}
											sx={{ flexGrow: "1" }}>
											Refazer
										</Button>
										: <Button
											size="medium"
											variant="contained"
											disableElevation
											onClick={handleStart}
											startIcon={<Videocam />}
											disabled={startRecording ? true : false}
											sx={{ flexGrow: "1" }}>
											Iniciar
										</Button>
								}
								{
									showPreview
										? <Button
											size="medium"
											variant="contained"
											disableElevation
											onClick={uploadVideoFile}
											startIcon={<Upload />}
											disabled={url ? false : true}
											sx={{ flexGrow: "1" }}>
											Enviar
										</Button>
										: startRecording
											? <Button
												size="medium"
												variant="contained"
												disableElevation
												ref={stopButtonRef}
												startIcon={<Stop />}
												disabled={startRecording ? false : true}
												sx={{ flexGrow: "1" }}
												onClick={() => {
													stopButtonRef.current.click()
												}}>
												Encerrar
											</Button>
											: <></>
								}
							</Box>
						</Grid>
						<Grid
							item
							md={12}
							style={{ display: "none" }}>
							<Box
								mt={3}>
								<Typography
									variant="h4"
									style={{ marginBottom: "12px" }}>
									Capturas
								</Typography>
								<Typography
									variant="body1">
									Veja suas apresentações anteriores
								</Typography>
								<div ref={canvasRef}></div>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</React.Fragment>
	);
}