import { fileManagerApi } from "@api/services/filemanager";
import { Close, Search } from "@mui/icons-material";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	Pagination,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ActivityService } from "../activity/activity.service";
import AuditionService from "../student/audition.service";
import ActivityTable from "../student/tables/activity.table.component";

import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";

import { ptBR } from "@mui/x-date-pickers";

export default function Submissions() {
	const history = useNavigate();
	const params = useParams();
	const theme = useTheme();

	const [activity, setActivity] = useState<any>(null);
	const [activityMeta, setActivityMeta] = useState<any>({
		currentPage: 1,
		lastPage: 1,
		count: 0,
	});
	const [activityData, setActivityData] = useState<any>(null);

	const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
	const [studentRa, setStudentRa] = useState<any>(null);
	const [submissionCode, setSubmissionCode] = useState<any>(null);

	// const [codAtividade, setCodAtividade] = useState<any>(params.submission);
	const [page, setPage] = useState(1);

	const [value, setValue] = useState<Dayjs | null>(
		dayjs("2014-08-18T21:11:54")
	);

	const [startDate, setStartDate] = useState<any>(null);
	const [endDate, setEndDate] = useState<any>(null);

	const handleDateChange = (newValue: Dayjs | null) => {
		setValue(newValue);
	};

	useEffect(() => {
		const controller = new AbortController();
		// alert(JSON.stringify(params, null, 4))
		if (params.submission) {
			new AuditionService(fileManagerApi)
				.findAll(params.submission, {
					page: 1,
				})
				.then((res: any) => {
					// console.log(res.data);
					setActivityData(res.data.data);
					setActivityMeta(res.data.meta);
				});
		}

		return () => {
			controller.abort();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setOpenBackdrop(true);
		setPage(value);
		new AuditionService(fileManagerApi)
			.findAll(params?.submission!, {
				page: value,
			})
			.then((res: any) => {
				// console.log(res.data);
				setActivityData(res.data.data);
				setActivityMeta(res.data.meta);
			})
			.finally(() => {
				setOpenBackdrop(false);
			});
	};

	useEffect(() => {
		if (params.submission)
			new ActivityService(fileManagerApi)
				.findOne(params.submission)
				.then((res: any) => {
					setActivity(res.data);
				});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function handleSearchFilter() {
		setOpenBackdrop(true);
		new AuditionService(fileManagerApi)
			.getAll(studentRa, {
				activity_id: params.submission,
				activity: 1,
				transcription: 1,
				code: submissionCode,
				gte: startDate,
				lte: endDate,
				user: 1,
			})
			.then((res: any) => {
				setActivityData(res.data.data);
				setActivityMeta(res.data.meta);
				if (res.data.length === 0)
					toast.error(
						"Nenhum resultado encontrao par o RA " +
							JSON.stringify(studentRa as string)
					);
			})
			.finally(() => {
				setOpenBackdrop(false);
			});
	}

	return (
		<>
			<Backdrop open={openBackdrop} sx={{ zIndex: 199999 }}>
				<CircularProgress />
			</Backdrop>
			<Dialog
				open={true}
				maxWidth="lg"
				fullWidth
				onClose={() => {
					history("/");
				}}
				fullScreen
			>
				<DialogTitle
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						backgroundColor: theme.palette.primary.dark,
						color: "white",
					}}
				>
					{/* <pre>
						{JSON.stringify(activity, null, 4)}
					</pre> */}
					<>
						{/* <Assignment /> */}
						<Typography>
							Atividade: {activity && activity.title}{" "}
							{activityData &&
								activityData.length > 0 &&
								activityData[0].title}
						</Typography>
					</>
					<IconButton
						size="small"
						sx={{
							color: "white",
						}}
						onClick={() => {
							history("/");
						}}
					>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Box p={2}>
						<Typography variant="h5" sx={{ mb: 1 }}>
							<strong>
								Listagem de submissões da atividade{" "}
								{params.submission}
							</strong>
						</Typography>
						<Typography variant="body1">
							Título da atividade: {activity && activity.title}
						</Typography>
						<Typography variant="body2">
							Descrição da atividade:{" "}
							{activity && activity.description}
						</Typography>
					</Box>
					<Box
						p={2}
						sx={{
							padding: "12px 24px",
						}}
					>
						<fieldset
							style={{
								border: "solid thin #ccc",
								borderRadius: "12px",
							}}
						>
							<legend>
								<Typography variant="caption">
									Filtros de busca{" "}
								</Typography>
							</legend>
							<Box
								sx={{
									display: "flex",
									gap: "12px",
								}}
								mb={2}
								mt={2}
							>
								<FormControl sx={{ flexGrow: 1 }}>
									<TextField
										label="Ra do aluno"
										// size="small"
										value={studentRa ?? ""}
										placeholder={"Ra do aluno"}
										onChange={(e) => {
											setStudentRa(e.target.value);
										}}
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												handleSearchFilter();
											}
										}}
									/>
								</FormControl>
								<FormControl
									sx={{ flexGrow: 1, display: "none" }}
								>
									<TextField
										label="Código da atividade"
										// size="small"
										value={params.submission}
										disabled
									/>
								</FormControl>
								<FormControl sx={{ flexGrow: 1 }}>
									<TextField
										label="Código da submissão"
										// size="small"
										value={submissionCode}
										disabled={false}
										onChange={(e) => {
											setSubmissionCode(e.target.value);
										}}
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												handleSearchFilter();
											}
										}}
									/>
								</FormControl>
								<FormControl
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											handleSearchFilter();
										}
									}}
								>
									<LocalizationProvider
										dateAdapter={AdapterDayjs}
										// adapterLocale={pt}
										localeText={
											ptBR.components
												.MuiLocalizationProvider
												.defaultProps.localeText
										}
									>
										<DatePicker
											onChange={(newValue: any) => {
												setStartDate(
													new Date(newValue)
														.toISOString()
														.split("T")[0]
												);
											}}
											label="Data de início"
										/>
									</LocalizationProvider>
								</FormControl>
								<FormControl
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											handleSearchFilter();
										}
									}}
								>
									<LocalizationProvider
										dateAdapter={AdapterDayjs}
									>
										<DatePicker
											onChange={(newValue: any) => {
												setEndDate(
													new Date(newValue)
														.toISOString()
														.split("T")[0]
												);
											}}
											label="Data de término"
										/>
									</LocalizationProvider>
								</FormControl>
								<Button
									variant="contained"
									disableElevation
									startIcon={<Search />}
									onClick={handleSearchFilter}
								>
									Buscar
								</Button>
							</Box>
						</fieldset>
					</Box>
					{/* <Divider /> */}
					<Box p={1}>
						<Box
							sx={{
								display: activityData ? "flex" : "none",
								m: 3,
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Pagination
								page={activityMeta.currentPage ?? 1}
								count={activityMeta.lastPage ?? 1}
								onChange={handleChange}
							/>
						</Box>

						{activityData && (
							<ActivityTable
								auditions={activityData}
								list={true}
								tutor={true}
							/>
						)}

						<Box
							sx={{
								m: 3,
								display: activityData ? "flex" : "none",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Pagination
								page={activityMeta.currentPage ?? 1}
								count={activityMeta.lastPage ?? 1}
								onChange={handleChange}
							/>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
}
