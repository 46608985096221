import { fileManagerApi } from "@api/services/filemanager";
import Appbar from "@components/Appbar";
import Cover from "@components/Cover";
import {
	ArrowDownward,
	ArrowUpward,
	Close,
	Face,
	QuestionAnswer,
	Search,
} from "@mui/icons-material";
import {
	Button,
	Divider,
	FormControl,
	Grid,
	IconButton,
	LinearProgress,
	TextField,
	Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { AxiosResponse } from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import AuditionService from "../student/audition.service";
import RevisionBoxComponent from "./revision.box.component";

/**
 * @description Tela de revisão do projeto submetido para o Palco Virtual
 * @returns
 */
export default function RevisionComponent() {
	const [audition, setAudition] = useState<any>(null);
	const [url, setUrl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [percent, setPercent] = useState<number>(0);
	const [rekognitions, setRekognitions] = useState<any>(null);
	const [grade, setGrade] = useState<any>(null);

	const [submission, setSubmission] = useState<any>(null);
	const [showImages, setShowImages] = useState<boolean>(false);

	const handleAudition = () => {
		setUrl(null);
		setPercent(0);
		setSubmission(null);

		if (audition.length > 0) setOpen(true);

		if (audition.length > 0)
			try {
				new AuditionService(fileManagerApi)
					.getOne(audition, undefined, {
						recognition: 1,
						transcription: 1,
						activity: 1,
						user: 1,
					})
					.then((res: AxiosResponse<any>) => {
						console.clear();
						console.log(res.data);
						if (res.data.length !== 0) {
							setSubmission(res.data);
							toast.success(
								"Foram encontrados " +
									res.data.length +
									" resultados para o código: " +
									JSON.stringify(audition)
							);
						} else {
							toast.error(
								"Nenhuma apresentação encontrada com o código " +
									JSON.stringify(audition)
							);
						}
					})
					.catch((err) => {
						toast.error("Falha ao carregar informações");
					})
					.finally(() => {
						setOpen(false);
					});
			} catch (err) {
				toast.error("Falha ao carregar informações");
			}
	};

	return (
		<React.Fragment>
			<Appbar />
			<Container>
				<Grid container spacing={1}>
					<Grid item md={12} mt={3} mb={3}>
						<Box
							style={{
								textAlign: "center",
								minHeight: "20vh",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<img
								width="40%"
								style={{
									alignSelf: "center",
									marginBottom: "24px",
								}}
								src="images/Writing room.svg"
								alt=""
							></img>
							<Typography variant="h5">
								Palco Virtual - Revisar apresentação
							</Typography>
							<Typography variant="body1">
								Olá! Insira o código do vídeo no campo abaixo
								para que possamos carregar os dados da
								apresentação
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<FormControl fullWidth>
							<TextField
								label="Código do evento"
								variant="outlined"
								value={audition}
								onChange={(e) => setAudition(e.target.value)}
								onKeyDown={(e) => {
									if (e.key === "Enter") handleAudition();
								}}
								InputProps={{
									endAdornment: (
										<>
											<IconButton
												onClick={() => {
													setAudition("");
													setSubmission(null);
													console.log("3321");
												}}
											>
												<Close />
											</IconButton>
											<IconButton
												onClick={handleAudition}
											>
												<Search />
											</IconButton>
										</>
									),
								}}
							/>
						</FormControl>
					</Grid>

					{/* {
						submission && (
							<Grid item md={12}>
								<pre>
									{JSON.stringify(submission, null, 4)}
								</pre>
							</Grid>
						)
					} */}

					<Grid
						item
						md={12}
						sx={{ display: submission ? "" : "none" }}
					>
						<Divider />
						<Box mt={3} mb={3}>
							<Box
								mb={3}
								sx={{
									display:
										submission &&
										submission[0].activity &&
										submission[0].activity
											? ""
											: "none",
								}}
							>
								<Typography
									variant="body1"
									sx={{ textAlign: "center" }}
								>
									{submission &&
										submission[0].activity &&
										submission[0].activity.title}
								</Typography>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "flex-end",
								}}
							>
								<Box>
									<Typography variant="body1">
										<strong>
											{submission &&
												submission[0].user_id}
										</strong>
									</Typography>
									<Typography variant="body1">
										{(submission &&
											submission[0] &&
											submission &&
											submission[0].user.name) ??
											"Nome Curso"}
									</Typography>
									<Typography variant="body1">
										{(submission &&
											submission[0] &&
											submission &&
											submission[0].user.course) ??
											"Nome Curso"}
									</Typography>
									<Typography variant="body1">
										{(submission &&
											submission[0] &&
											submission &&
											submission[0].user
												.learning_level) ??
											"Nível de Ensino"}
									</Typography>
								</Box>
								<Box>
									NOTA:{" "}
									<span
										style={{
											padding: "12px",
											border: "solid 1px gray",
											borderRadius: "4px",
											marginLeft: "12px",
										}}
									>
										NA
									</span>{" "}
								</Box>
							</Box>
						</Box>
						<Divider />
					</Grid>
					<Grid item md={6}>
						<Box mt={3}>
							{submission && (
								<Box mb={3}>
									<Typography
										sx={{
											textAlign: "center",
											textTransform: "uppercase",
										}}
									>
										{submission[0].code}
									</Typography>
								</Box>
							)}
							{submission ? (
								<>
									<video
										muted
										controls
										style={{
											width: "100%",
											height: "auto",
										}}
									>
										<source
											type={"video/mp4"}
											src={
												"https://dirin.s3.us-east-1.amazonaws.com/" +
												submission[0].file_key
											}
										/>
									</video>
									<Typography
										sx={{
											textAlign: "center",
											fontWeight: "bold",
											marginTop: "12px",
											color: "#212121",
										}}
									>
										ÍNDICE DE CONFIABILIDADE:{" "}
										{percent.toFixed(2)}%
									</Typography>
								</>
							) : (
								<></>
							)}
						</Box>
						{submission && (
							<Box
								mt={3}
								mb={3}
								sx={{
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									gap: "12px",
									paddingTop: "12px",
									paddingBottom: "12px",
									borderTop: "solid thin #ddd",
									borderBottom: "solid thin #ddd",
								}}
							>
								<Typography>Similaridade: </Typography>
								<Typography>Emoção predominante: </Typography>
								<Typography>
									Sentimento predominante:{" "}
								</Typography>
								<Typography>Neutro: </Typography>
								<Typography>Positivo: </Typography>
								<Typography>Negativo: </Typography>
							</Box>
						)}
					</Grid>
					<Grid
						item
						md={6}
						sx={{ display: submission ? "" : "none" }}
					>
						<Box p={1} mt={3}>
							<Box p={3}>
								<Typography
									sx={{
										textAlign: "left",
										mb: 3,
										fontWeight: "bold",
									}}
								>
									Resumo da Apresentação:{" "}
								</Typography>
								{submission &&
								submission[0].transcription !== null &&
								submission[0].transcription.length > 0 ? (
									<>
										<Typography variant="body1">
											{buscarInformacao(grade, "Média")}
											{buscarInformacao(
												grade,
												"Abrangência do conteúdo"
											)}
											{buscarInformacao(
												grade,
												"Atratividade"
											)}
											{buscarInformacao(
												grade,
												"Comunicabilidade"
											)}
											{buscarInformacao(
												grade,
												"Originalidade"
											)}
											{buscarInformacao(
												grade,
												"Relevância do trabalho"
											)}
											{buscarInformacao(
												grade,
												"Contribuição"
											)}
										</Typography>
									</>
								) : (
									<>
										<Typography>
											Ainda não há nenhum feedback
											disponível para o vídeo
										</Typography>
									</>
								)}
							</Box>
						</Box>
					</Grid>

					{submission && (
						<Grid item md={12}>
							<Button
								variant="contained"
								disableElevation
								startIcon={
									showImages ? (
										<ArrowUpward />
									) : (
										<ArrowDownward />
									)
								}
								sx={{ mt: 3 }}
								onClick={() => {
									setShowImages(!showImages);
								}}
							>
								Visualizar Biometria
							</Button>
							<Grid
								container
								spacing={1}
								sx={{
									mt: 3,
									display: showImages ? "" : "none",
								}}
							>
								{submission &&
									submission[0].recognition &&
									submission[0].recognition.map((d: any) => {
										return (
											<Grid item md={2} key={d.id}>
												<img
													width="100%"
													height="auto"
													src={
														"https://dirin.s3.us-east-1.amazonaws.com/" +
														d.file_key
													}
													alt=""
												/>
											</Grid>
										);
									})}
							</Grid>
						</Grid>
					)}
					{/* 
						///////////////////////////////////////////// OLD CODE
					*/}
					{grade ? (
						<Grid item md={12}>
							<Box p={1}>
								{/* <Typography variant="body1" sx={{ marginBottom: "12px" }}><strong>Feedback</strong></Typography> */}
								<Typography
									variant="h5"
									component="div"
									sx={{ marginBottom: "12px" }}
								>
									<span
										style={{
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											gap: "12px",
										}}
									>
										<QuestionAnswer /> Feedback
									</span>
								</Typography>
								<Typography variant="body2">
									{buscarFeedback(grade, "Feedback")}
								</Typography>
							</Box>
						</Grid>
					) : (
						<></>
					)}
					<Grid
						item
						md={12}
						sx={{ display: rekognitions ? "" : "none" }}
					>
						<Box mb={3} mt={3}>
							<Typography variant="h5">
								<span
									style={{
										display: "flex",
										justifyContent: "flex-start",
										alignItems: "center",
										gap: "12px",
									}}
								>
									<Face />{" "}
									{(rekognitions && rekognitions.length) ?? 0}{" "}
									amostras coletadas
								</span>
							</Typography>
						</Box>
						<Grid container gap={1}>
							{rekognitions &&
								rekognitions.map((rekognition: any) => {
									return (
										<RevisionBoxComponent
											image={rekognition.target as string}
											boundingbox={
												rekognition.matches[0]
													? rekognition.matches[0]
															.Face.BoundingBox
													: null
											}
											similarity={rekognition.similarity}
										/>
									);
								})}
						</Grid>
						<Grid item md={12}>
							<Box sx={{ height: "20vh" }}></Box>
						</Grid>
					</Grid>
				</Grid>
				<Box sx={{ minHeight: "20vh" }}></Box>
			</Container>

			<Cover open={open} setOpen={setOpen} />
		</React.Fragment>
	);
}

function buscarInformacao(texto: any, item: any) {
	const padrao = new RegExp(`${item}:\\s(\\d+\\.?\\d*)`);
	const correspondencia = texto?.match(padrao);

	if (correspondencia) {
		return (
			<React.Fragment>
				<Box mt={2}></Box>
				<Box
					sx={{
						display: "flex",
						gap: "6px",
						alignItems: "center",
						justifyContent: "flex-start",
						marginBottom: "6px",
					}}
				>
					<Typography
						variant="caption"
						style={{
							width: "200px",
							textTransform: "uppercase",
							textAlign: "right",
							fontWeight: "bold",
						}}
					>
						{correspondencia[0]?.split(":")[0]}
					</Typography>

					<Box
						sx={{
							flexGrow: 1,
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							gap: "12px",
						}}
					>
						<LinearProgress
							variant="determinate"
							value={parseFloat(
								correspondencia[0]?.split(":")[1]
							)}
							style={{ width: "100%", height: "8px" }}
							sx={{
								"& .MuiLinearProgress-bar1Determinate": {
									backgroundColor:
										parseFloat(
											correspondencia[0]?.split(":")[1]
										) >= 70
											? "#04724D"
											: "#CF1D05",
								},
							}}
						/>
						{parseFloat(correspondencia[0]?.split(":")[1]).toFixed(
							1
						)}
						%
					</Box>
				</Box>
			</React.Fragment>
		);
	} else {
		return "Informação não encontrada";
	}
}

function buscarFeedback(texto: any, item: any) {
	const padrao = new RegExp(/Feedback:(.*)/);
	const correspondencia = texto?.match(padrao);

	if (correspondencia) {
		return correspondencia[1];
	} else {
		return "Informação não encontrada";
	}
}
