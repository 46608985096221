import { Assignment, Close, InfoRounded } from "@mui/icons-material";
import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";

export default function TasksDialogComponent(props: any) {

	const theme = useTheme();

	return (
		<Dialog
			maxWidth="lg"
			fullWidth
			open={props.open}
			onClose={() => { props.setOpen(false) }}>
			<Box
				style={{
					backgroundColor: theme.palette.primary.main,
					color: "white",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					gap: "12px"
				}}>
				<Box p={1} sx={{ display: "flex", gap: "12px 8px" }}>
					{/* <AddTask /> */}
					<Assignment />
					<Typography variant="body1" component="div">Visualizar tarefa</Typography>
				</Box>
				<Box>
					<IconButton sx={{ color: "white", padding: "12px" }} onClick={() => props.setOpen(false)}>
						<Close />
					</IconButton>
				</Box>
			</Box>
			<DialogContent>
				{
					props && props.task
						? <>
							<Typography variant={"h5"}>Código da atividade: {props.task.id} </Typography>
							<Typography variant={"caption"}>{props.task.created_at}</Typography>
							<Box sx={{
								marginTop: "12px",
								padding: "12px 12px",
								borderRadius: "4px",
								border: "solid #f6f6f6",
								minHeight: "20vh"
							}}>
								<Typography variant={"body1"} sx={{ marginTop: "12px" }}>{props.task.title}</Typography>
								<Typography variant={"body2"} sx={{ marginTop: "12px" }}>{props.task.description}</Typography>

								{/*<pre>*/}
								{/*	{JSON.stringify(props.task.item, null, 4)}*/}
								{/*</pre>*/}

								<Box
									mt={3}
									sx={{
										display: "flex",
										gap: "6px"
									}}>
									{
										props && props.task && props.task.item && props.task.item.map((i: any) => {
											return (
												// <Chip key={i.id} label={i.title} avatar={<Avatar><Edit /></Avatar>}/>
												<Chip
													color="secondary"
													key={i.id}
													label={i.title}
													icon={
														<Tooltip title={i.description} arrow>
															<InfoRounded />
														</Tooltip>
													}
												></Chip>
											)
										})
									}
								</Box>
							</Box>
						</>
						: <>
							<Box>
								<Typography variant={"h5"}>Falha ao carregar atividade! </Typography>
							</Box>
						</>
				}
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					disableElevation
					onClick={() => { props.setOpen(false) }}>
					Fechar
				</Button>
			</DialogActions>
		</Dialog >
	)
}