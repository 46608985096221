import { fileManagerApi } from "@api/services/filemanager";
import { Close } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	Grid,
	IconButton,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import AuditionService from "../audition.service";

interface DeleteActivityDialogProps {
	submission: SubmissionType; // Replace SubmissionType with the actual type of submission
	openDelete: boolean;
	setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>;
	setDeleteConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
	deleteConfirmation: boolean;
}

interface SubmissionType {
	id: string; // Replace with actual properties of submission
	activity_id: string; // Replace with actual properties of submission
}

export default function DeleteActivityDialog(props: DeleteActivityDialogProps) {
	const theme = useTheme();

	return (
		<Dialog
			open={props.openDelete}
			onClose={() => props.setOpenDelete(false)}
			maxWidth="lg"
			fullWidth
		>
			<Box
				style={{
					backgroundColor: theme.palette.primary.main,
					color: "white",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					gap: "12px",
				}}
			>
				<Box p={2} sx={{ display: "flex", gap: "12px" }}>
					<Typography variant="body1">Excluir submissão</Typography>
				</Box>
				<Box p={1}>
					<IconButton
						onClick={() => {
							props.setOpenDelete(false);
							props.setDeleteConfirmation(false);
						}}
					>
						<Close />
					</IconButton>
				</Box>
			</Box>
			<DialogContent>
				<Grid container>
					<Grid item md={12}>
						<Typography variant="h5">
							Deseja prosseguir com a exclusão?
						</Typography>
						<Typography variant="body1">
							Digite "excluir" para prosseguir
						</Typography>
						<Box p={2}>
							<FormControl fullWidth>
								<TextField
									placeholder="excluir"
									onChange={(e) => {
										props.setDeleteConfirmation(
											e.target.value.toLocaleLowerCase() ===
												"excluir"
										);
									}}
								/>
							</FormControl>
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					variant="text"
					onClick={() => {
						props.setOpenDelete(false);
						props.setDeleteConfirmation(false);
					}}
				>
					Cancelar
				</Button>
				<Button
					variant="contained"
					disableElevation
					disabled={!props.deleteConfirmation}
					onClick={async () => {
						await new AuditionService(fileManagerApi)
							.delete(props.submission.id)
							.then(() => {
								toast.success("Excluindo apresentação...");
							})
							.catch(() => {
								toast.error("Falha ao excluir submissão.");
							})
							.finally(() => {
								window.location.href = "/";
								// history(
								// 	props.list
								// 		? "/list/" +
								// 				submission.activity_id +
								// 				"/reload"
								// 		: "/",
								// );
							});
					}}
				>
					Excluir
				</Button>
			</DialogActions>
		</Dialog>
	);
}
