import { useEffect, useState } from "react";
import SessionContext from "./SessionContext";

export type Audition = {
	id: any;
	ra: any;
	media: any;
	media_id: any;
	audition: any;
	created_at: any;
	deleted_at: any;
	updated_at: any;
};

const INI_VALUES = {
	auditions: {
		user_existing_auditions: [],
		user_existing_auditions_unfinished: [],
	},
};

export default function SessionProvider({ children }: any) {
	const [auditions, setAuditions] = useState<any | any[]>(
		INI_VALUES.auditions
	);
	const [activeStep, setActiveStep] = useState(
		() => JSON.parse(localStorage.getItem("@step") as string) || 0
	);
	const [skipped, setSkipped] = useState(new Set<number>());
	const [images, setImages] = useState<any | any[]>([]);
	const [videos, setVideos] = useState<any | any[]>(() => {
		const storedVideos = JSON.parse(
			localStorage.getItem("videos") as string
		);
		return storedVideos ? storedVideos : [];
	});

	const [browserAgent, setBrowserAgent] = useState<any>(null);
	// const [auditions, setAuditions] = useState<any[]|any>(null);

	const [detections, setDetections] = useState<any[]>([]);

	useEffect(() => {
		// Obtém a string de agente do usuário
		var userAgentString = navigator.userAgent;

		// Verifica se o usuário está usando o Safari
		if (
			userAgentString.indexOf("Safari") > -1 &&
			userAgentString.indexOf("Chrome") === -1
		) {
			setBrowserAgent(true);
		} else {
			setBrowserAgent(false);
		}
	}, []);
	// Implementa redirecionamento quando usuário clicar em avançar ou evento de finalizar captura tenha sido disparado
	useEffect(() => {
		localStorage.setItem("@step", JSON.stringify(activeStep));
	}, [activeStep]);

	useEffect(() => {
		localStorage.setItem("images", JSON.stringify(images));
	}, [images]);

	useEffect(() => {
		localStorage.setItem("videos", JSON.stringify(videos));
	}, [videos]);

	useEffect(() => {
		localStorage.setItem("detections", JSON.stringify(detections));
	}, [detections]);

	useEffect(() => {
		const detected = JSON.parse(localStorage.getItem("") as string);
		if (detected) {
			setDetections(detected);
		}

		const $auditions = JSON.parse(
			localStorage.getItem("auditions") as string
		);
		if ($auditions) {
			setAuditions($auditions);
		}

		const $images = JSON.parse(localStorage.getItem("images") as string);
		if ($images) {
			setImages($images);
		}
	}, []);

	return (
		<SessionContext.Provider
			value={{
				activeStep,
				skipped,
				setActiveStep,
				setSkipped,
				images,
				videos,
				setImages,
				setVideos,
				detections,
				setDetections,
				auditions,
				setAuditions,
				browserAgent,
				setBrowserAgent,
			}}
		>
			{children}
		</SessionContext.Provider>
	);
}
