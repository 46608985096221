import { fileManagerApi } from "@api/services/filemanager";
import { ActivityService } from "@modules/activity/activity.service";
import { Close, VideoCallOutlined } from "@mui/icons-material";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	IconButton,
	MenuItem,
	Select,
	Typography,
	useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface PropType {
	activities?: any[];
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	goAction: any;
}

export default function MiniInfo(props: PropType) {
	const theme = useTheme();
	const history = useNavigate();
	const [activityId, setActivityId] = useState<number>(0);

	const [activities, setActivities] = useState<any[]>([]);

	useEffect(() => {
		new ActivityService(fileManagerApi)
			.getAll()
			.then((res: AxiosResponse<any>) => {
				setActivities(res.data);
			});
	}, []);

	return (
		<Dialog open={props.open} onClose={() => props.setOpen(false)}>
			<Box
				style={{
					backgroundColor: theme.palette.primary.main,
					color: "white",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					gap: "12px",
				}}
			>
				<Box p={2} sx={{ display: "flex", gap: "12px" }}>
					<VideoCallOutlined />
					<Typography variant="body1" component="div">
						Palco Virtual
					</Typography>
				</Box>
				<Box p={1}>
					<IconButton
						sx={{ color: "white" }}
						onClick={() => props.setOpen(false)}
					>
						<Close />
					</IconButton>
				</Box>
			</Box>
			<DialogContent>
				<DialogContent>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							marginBottom: "24px",
						}}
					>
						<img src="images/saves-time.svg" width="250" alt="" />
					</Box>
					{/* <pre>
						{JSON.stringify(props.activities, null, 4)}
					</pre> */}
					<Box sx={{ textAlign: "center" }}>
						<Typography variant="body1" component="div">
							Selecione a <strong>atividade</strong> e clique em
							continuar:
						</Typography>
					</Box>
					<FormControl fullWidth sx={{ mt: 2 }}>
						{/* <TextField
							label="Código da Atividade"
							onChange={(e) => {
								setActivityId(Number(e.target.value))
							}} /> */}

						<Select
							value={activityId}
							onChange={(e) => {
								// alert(e.target.value)
								setActivityId(Number(e.target.value));
							}}
						>
							<MenuItem value={0}>Selecionar</MenuItem>
							{/* <MenuItem value={1}>Nenhuma</MenuItem> */}
							{activities &&
								activities.map((ac: any) => {
									return (
										<MenuItem key={ac.id} value={ac.id}>
											{ac.title}
										</MenuItem>
									);
								})}
						</Select>
					</FormControl>
				</DialogContent>
			</DialogContent>
			<DialogActions>
				<Button variant="text" onClick={() => props.setOpen(false)}>
					Cancelar
				</Button>
				<Button
					disabled={activityId !== 0 ? false : true}
					variant="contained"
					disableElevation
					onClick={() => history("/recorder/" + activityId)}
				>
					Continuar
				</Button>
			</DialogActions>
		</Dialog>
	);
}
