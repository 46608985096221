import axios from "axios";

/**
 * 
 * @param user 
 * @param file 
 * @returns 
 */
export const uploadFilesViaLambda = async (user: any, file: any, type?: any, label?: any, is_video_file?: boolean, location?: string, onUploadProgress?: (progressEvent: any) => void) => {

    let formData = new FormData()
    formData.append('image', file);

    const path = is_video_file 
		? `${process.env.REACT_APP_FILE_LOCATION}/chrome/${label ? label : file.name}` 
		: label
		// : `${process.env.REACT_APP_FILE_LOCATION}/${user.ra}/${label ? label : file.name}`;

    const baseUrl = `https://urlsigner.aiamis.com.br/urlsigner?b=dirin&f=${path}&c=${type ? type : file.type}`;

	return await axios.get(baseUrl,
		{
			headers: {
				key: "66556A586E5A7234753778214125442A472D4B6150645367566B597033733576"
			}
		})
		.then(async (response: any) => {
            await axios.put(response.data.url,
                file,
                {
                    headers: {
                        'Content-Type': type ? type : file.type,
                        // key: "66556A586E5A7234753778214125442A472D4B6150645367566B597033733576"
                    },
					onUploadProgress: onUploadProgress
                });
        })
        .catch((err) => {
            return err;
        });
}

/**
 * 
 * @param user 
 * @param file 
 * @returns 
 */
export const uploadBiometrySampleLambda = async (id: any, file: any, type?: any, label?: any, is_video_file?: boolean) => {

    let formData = new FormData()
    formData.append('image', file);

    const path = is_video_file 
		? `${process.env.REACT_APP_FILE_LOCATION}/videos/${label ? label : file.name}` 		
		: `${process.env.REACT_APP_FILE_LOCATION}/${id}/${label ? label : file.name}`;

    const baseUrl = `https://urlsigner.aiamis.com.br/urlsigner?b=dirin&f=${path}&c=${type ? type : file.type}`;

    return await axios.get(baseUrl,
        {
            headers: {
                key: "66556A586E5A7234753778214125442A472D4B6150645367566B597033733576"
            }
        })
        .then(async (response: any) => {
            await axios.put(response.data.url,
                file,
                {
                    headers: {
                        'Content-Type': type ? type : file.type,
                        // key: "66556A586E5A7234753778214125442A472D4B6150645367566B597033733576"
                    }
                });
        })
        .catch((err) => {
            return err;
        });
}

/**
 * 
 * @param payload 
 * @returns 
 */
export const compareFaces = async (payload: any) => {
    return await axios.post("https://9jjzixt9z2.execute-api.us-east-1.amazonaws.com/", {
        payload
    });
}