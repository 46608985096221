import { fileManagerApi } from "@api/services/filemanager";
import {
	BugReport,
	Close,
	DisplaySettings,
	Edit,
	Refresh,
	SendAndArchiveSharp,
} from "@mui/icons-material";
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	Divider,
	Grid,
	IconButton,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuditionService from "../audition.service";

export default function ActivityViewerDialog({
	open,
	state,
	setEnabled,
	setOpen,
	data,
	enabled,
	comprehend,
	analysis,
	showImages,
	setShowImages,
	getAIEvaluation,
	grade,
	setGrade,
	queryResult,
	url,
}: any) {
	const history = useNavigate();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Dialog
			open={open}
			onClose={() => {
				if (!state.user.professor && !state.user.moodle_admin) {
					history("/student");
				}
				setEnabled(false);
				setOpen(false);
			}}
			maxWidth="lg"
			fullWidth
		>
			<Box
				style={{
					backgroundColor: theme.palette.primary.main,
					color: "white",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					gap: "12px",
				}}
			>
				<Box p={2} sx={{ display: "flex", gap: "12px" }}>
					<Typography variant="body1">Dados da submissão</Typography>
				</Box>
				<Box p={1}>
					<IconButton
						sx={{
							color: "white",
						}}
						onClick={() => {
							setEnabled(false);
							setOpen(false);
							if (
								!state.user.professor &&
								!state.user.moodle_admin
							) {
								window.location.href = "/student";
							}
						}}
					>
						<Close />
					</IconButton>
				</Box>
			</Box>
			<DialogContent>
				<Grid container>
					<Grid item md={12} sx={{ mb: 3 }}>
						<Divider />
						<Box mt={3} mb={3}>
							<Box mb={1}>
								<Typography
									variant="body1"
									sx={{ textAlign: "center" }}
								>
									{data && data.activity && data.activity.id}{" "}
									-{" "}
									{data &&
										data.activity &&
										data.activity.title.toUpperCase()}
								</Typography>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "flex-end",
								}}
							>
								<Box>
									<Typography variant="body1">
										{(data &&
											data.user &&
											data.user.name) ??
											"Nome do usuário não disponível"}{" "}
										-{" "}
										{(data && data.user && data.user.id) ??
											"Nome Curso"}
									</Typography>
									<Typography variant="body1">
										{(data &&
											data.user &&
											data.user.course) ??
											"Nome do curso não disponível"}{" "}
									</Typography>
									<Typography variant="body1">
										{(data &&
											data.user &&
											data.user.learning_level) ??
											"Nível de ensino não disponível"}
									</Typography>
								</Box>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-start",
										gap: "12px",
									}}
								>
									{!enabled ? (
										<>
											<Box>
												NOTA:{" "}
												<span
													style={{
														padding: "8px",
														border: "solid 1px #ddd",
														borderRadius: "4px",
														marginLeft: "12px",
													}}
												>
													{data && data.grade
														? data.grade
														: grade}
													{/* {grade && grade !== 0
															? grade
															: data.grade
															? data.grade
															: "NA"} */}
												</span>{" "}
											</Box>
											{state.user &&
												(state.user.professor ||
													state.user
														.moodle_admin) && (
													<IconButton
														size="small"
														sx={{
															border: "solid thin #ddd",
														}}
														onClick={() => {
															setEnabled(true);
														}}
													>
														<Edit />
													</IconButton>
												)}
										</>
									) : (
										<>
											<IconButton
												size="small"
												sx={{
													border: "solid thin #ddd",
												}}
												onClick={() => {
													setEnabled(false);
												}}
											>
												<Close />
											</IconButton>

											<label>
												<span
													style={{
														marginRight: "12px",
													}}
												>
													NOTA:
												</span>
												<input
													style={{
														padding: "12px",
														border: "solid thin #ddd",
														borderRadius: "4px",
													}}
													type="number"
													min="0"
													max="10"
													defaultValue={
														data.grade ?? grade
													}
													// defaultValue={
													// 	grade !== 0
													// 		? grade
													// 		: (data &&
													// 		  data.grade
													// 				? data.grade
													// 				: grade) ||
													// 		  grade
													// }
													onChange={(e) => {
														setGrade(
															e.target.value
														);
													}}
												/>
											</label>

											<IconButton
												size="small"
												sx={{
													border: "solid thin #ddd",
												}}
												onClick={async () => {
													await new AuditionService(
														fileManagerApi
													)
														.update(data.id, {
															grade: Number(
																grade
															),
														})
														.then(
															(
																res: AxiosResponse<any>
															) => {
																console.log(
																	res
																);
																toast.success(
																	"Nota atualizada com sucesso"
																);
															}
														)
														.finally(() => {
															setEnabled(false);
														});
												}}
											>
												<SendAndArchiveSharp />
											</IconButton>
										</>
									)}
								</Box>
							</Box>
						</Box>
						<Divider />
					</Grid>
					<Grid item md={12}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-evenly",
								mb: 3,
							}}
						>
							<Typography>
								<strong>
									{data &&
										data.code &&
										data.code.toUpperCase()}
								</strong>
							</Typography>
							{/* Descomentar o bloco abaixo para recolocar o score */}
							{/* <Typography>
									SCORE:
									<span style={{ color: "green" }}>
										{analysis.gpt_avg === 0
											? data && data.gpt_avg
												? data.gpt_avg
												: 0
											: analysis.gpt_avg}
									</span>
								</Typography> */}
						</Box>
					</Grid>
					<Grid item md={12}>
						<Grid container>
							<Grid
								item
								md={6}
								// md={
								// 	state.user &&
								// 	(state.user.professor ||
								// 		state.user.moodle_admin)
								// 		? 6
								// 		: 12
								// }
							>
								<Typography variant="h5" sx={{ mb: 1 }}>
									Apresentação submetida
								</Typography>
								<video
									controls
									muted
									autoPlay
									src={`${url}`}
									width={matches ? "100%" : 480}
									height={matches ? "auto" : 320}
									// width="480"
									// height="320"
									style={{
										backgroundColor: "black",
									}}
								/>

								<Box
									mt={2}
									mb={2}
									sx={{
										display:
											state.user &&
											(state.user.professor ||
												state.user.moodle_admin)
												? "flex"
												: "none",
										flexDirection: "column",
										alignItems: "flex-start",
										justifyContent: "flex-start",
										gap: "8px",
									}}
								>
									{data &&
										data.recognition &&
										data.recognition.length > 0 &&
										(() => {
											const emotionTranslations: any = {
												HAPPY: "FELIZ",
												SAD: "TRISTE",
												ANGRY: "IRRITADO",
												CONFUSED: "CONFUSO",
												DISGUSTED: "DESGOSTO",
												SURPRISED: "SURPRESO",
												CALM: "CALMO",
												UNKNOWN: "DESCONHECIDO",
												FEAR: "MEDO",
											};

											let maxEmotion = "";
											let maxEmotionConfidence = 0;

											data.recognition.forEach(
												(item: any) => {
													if (
														item.emotion_confidence >
														maxEmotionConfidence
													) {
														maxEmotion =
															item.emotion;
														maxEmotionConfidence =
															item.emotion_confidence;
													}
												}
											);

											const translatedMaxEmotion =
												emotionTranslations[maxEmotion];

											return (
												<Typography>
													Emoção predominante:{" "}
													{translatedMaxEmotion} -{" "}
													{maxEmotionConfidence.toFixed(
														2
													)}
													%
												</Typography>
											);
										})()}

									{data &&
										data.recognition &&
										data.recognition &&
										data.recognition.forEach(
											(item: any) => {
												return <>{item.emotion}</>;
											}
										)}

									{Object.keys(comprehend).length > 0 ? (
										<>
											<Typography>
												Sentimento predominante:
												<span
													style={{
														color: "green",
													}}
												>
													{comprehend.Sentiment ===
													"POSITIVE"
														? "Positivo"
														: comprehend.Sentiment ===
														  "NEGATIVE"
														? "Negativo"
														: comprehend.Sentiment ===
														  "MIXED"
														? "Misto"
														: "Neutro"}
												</span>
											</Typography>
											<Typography>
												Positivo:{" "}
												{(
													comprehend.SentimentScore
														.Positive * 100
												).toFixed(2)}
												%
											</Typography>
											<Typography>
												Negativo:{" "}
												{(
													comprehend.SentimentScore
														.Negative * 100
												).toFixed(2)}
												%
											</Typography>
											<Typography>
												Neutro:{" "}
												{(
													comprehend.SentimentScore
														.Neutral * 100
												).toFixed(2)}
												%
											</Typography>
											<Typography>
												Misto:{" "}
												{(
													comprehend.SentimentScore
														.Mixed * 100
												).toFixed(2)}
												%
											</Typography>
										</>
									) : (
										<>
											<Typography>
												Sentimento predominante:
												<span
													style={{
														color: "green",
													}}
												>
													{data &&
													data.transcription &&
													data.transcription[0] &&
													data.transcription[0]
														.comprehend_sentiment ===
														"POSITIVE"
														? "Positivo"
														: data &&
														  data.transcription &&
														  data
																.transcription[0] &&
														  data.transcription[0]
																.comprehend_sentiment ===
																"NEGATIVE"
														? "Negativo"
														: data &&
														  data.transcription &&
														  data
																.transcription[0] &&
														  data.transcription[0]
																.comprehend_sentiment ===
																"MIXED"
														? "Misto"
														: "Neutro"}
												</span>
											</Typography>
											<Typography>
												Positivo:{" "}
												{data &&
													data.transcription &&
													data.transcription[0] &&
													data.transcription[0]
														.comprehend_feedback &&
													data.transcription[0]
														.comprehend_feedback &&
													(
														JSON.parse(
															data
																.transcription[0]
																.comprehend_feedback
														).SentimentScore
															.Positive * 100
													).toFixed(2)}
												%
											</Typography>
											<Typography>
												Negativo:{" "}
												{data &&
													data.transcription &&
													data.transcription[0] &&
													data.transcription[0]
														.comprehend_feedback &&
													data.transcription[0]
														.comprehend_feedback &&
													(
														JSON.parse(
															data
																.transcription[0]
																.comprehend_feedback
														).SentimentScore
															.Negative * 100
													).toFixed(2)}
												%
											</Typography>
											<Typography>
												Neutro:{" "}
												{data &&
													data.transcription &&
													data.transcription[0] &&
													data.transcription[0]
														.comprehend_feedback &&
													data.transcription[0]
														.comprehend_feedback &&
													(
														JSON.parse(
															data
																.transcription[0]
																.comprehend_feedback
														).SentimentScore
															.Neutral * 100
													).toFixed(2)}
												%
											</Typography>
											<Typography>
												Misto:{" "}
												{data &&
													data.transcription &&
													data.transcription[0] &&
													data.transcription[0]
														.comprehend_feedback &&
													data.transcription[0]
														.comprehend_feedback &&
													(
														JSON.parse(
															data
																.transcription[0]
																.comprehend_feedback
														).SentimentScore.Mixed *
														100
													).toFixed(2)}
												%
											</Typography>
										</>
									)}
								</Box>
							</Grid>
							{queryResult === "IDLE" ? (
								<>
									<Grid
										item
										md={6}
										sx={{
											display:
												state.user &&
												(state.user.professor ||
													state.user.moodle_admin)
													? ""
													: "",
										}}
									>
										<Box mb={3}>
											<Typography
												variant="h5"
												sx={{
													mb: 1,
													display: "flex",
													alignItems: "center",
												}}
											>
												<IconButton
													color="primary"
													sx={{
														display: "none",
													}}
													onClick={() => {
														getAIEvaluation(
															data.id
														);
													}}
												>
													<Refresh />
												</IconButton>
												Análise da apresentação
											</Typography>
											{analysis &&
												analysis.gpt_feedback === "" &&
												data &&
												data.transcription &&
												data.transcription.length ===
													0 && (
													<Box
														sx={{
															display: "flex",
															alignItems:
																"center",
															justifyContent:
																"center",
															height: "200px",
														}}
													>
														<Typography>
															Em breve estará
															disponível
														</Typography>
													</Box>
												)}
											{data && data.transcription ? (
												<div
													dangerouslySetInnerHTML={{
														__html:
															data &&
															data.transcription &&
															data.transcription
																.length > 0 &&
															data
																.transcription[0]
																.gpt_feedback
																? data.transcription[0].gpt_feedback.replace(
																		/\n/g,
																		"<br>"
																  )
																: "",
													}}
												/>
											) : analysis &&
											  analysis.gpt_feedback ? (
												<div
													dangerouslySetInnerHTML={{
														__html:
															analysis.gpt_feedback !==
															""
																? analysis.gpt_feedback.replace(
																		/\n/g,
																		"<br>"
																  )
																: "",
													}}
												/>
											) : (
												<>
													<Box
														sx={{
															display: "flex",
															alignItems:
																"center",
															justifyContent:
																"center",
															height: "156px",
														}}
													>
														<Typography>
															Dado indisponível no
															momento
														</Typography>
													</Box>
												</>
											)}
										</Box>
										{state.user &&
											(state.user.professor ||
												state.user.moodle_admin) && (
												<>
													<Typography
														variant="h5"
														sx={{ mb: 1 }}
													>
														Transcrição
													</Typography>
													<Box>
														{data &&
														data.transcription &&
														data.transcription
															.length > 0 ? (
															<>
																{data.transcription &&
																	data
																		.transcription[0] &&
																	data
																		.transcription[0]
																		.transcription}
															</>
														) : (
															<>
																<Box
																	sx={{
																		display:
																			"flex",
																		flexDirection:
																			"column",
																		gap: "12px",
																		justifyContent:
																			"center",
																		alignItems:
																			"center",
																		minHeight:
																			"200px",
																		// backgroundColor: "#f8f8f8"
																	}}
																>
																	<BugReport />
																	<Typography variant="body1">
																		Nenhuma
																		transcrição
																		disponível
																	</Typography>
																</Box>
															</>
														)}
													</Box>
												</>
											)}
									</Grid>
								</>
							) : (
								<Grid
									item
									sm={6}
									xs={6}
									md={6}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										flexDirection: "column",
									}}
								>
									<Box
										sx={{
											minHeight: "10vh",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<CircularProgress />
									</Box>
									<Box>
										Por favor, aguarde. Estamos carregando
										as informações.
									</Box>
								</Grid>
							)}
						</Grid>
					</Grid>
					<Grid item md={12}>
						<Button
							variant="contained"
							disableElevation
							startIcon={<DisplaySettings />}
							sx={{ mt: 3 }}
							onClick={() => setShowImages(!showImages)}
						>
							Visualizar Biometria
						</Button>
						<Grid
							container
							spacing={1}
							sx={{
								mt: 3,
								display: showImages ? "" : "none",
							}}
						>
							{data &&
								data.recognition &&
								data.recognition.map((d: any) => {
									return (
										<Grid item md={2} key={d.id}>
											<img
												width="100%"
												height="auto"
												src={
													"https://dirin.s3.us-east-1.amazonaws.com/" +
													d.file_key
												}
												alt=""
											/>
										</Grid>
									);
								})}
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}
