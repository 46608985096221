// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// import { Box, Typography } from "@mui/material";
import { useEffect, useRef } from "react";

export default function RevisionBoxComponent({ image, boundingbox, similarity }: any): JSX.Element {

	const canvasRef = useRef<HTMLCanvasElement>();
	// const imageRef = useRef<HTMLImageElement>(null);

	const initCanvas = () => {
		if (canvasRef.current !== null && canvasRef.current !== undefined) {


			var canvas = canvasRef.current
			var ctx = canvas.getContext('2d')

			ctx.strokeStyle = "#00ff00"
			ctx.lineWidth = 2
			ctx.roundRect(boundingbox.Left * canvas.width, boundingbox.Top * canvas.height, boundingbox.Width * canvas.width, boundingbox.Height * canvas.height, 12)

			var im = new Image()
			im.src = image


			im.onload = () => {
				canvasRef.current.setAttribute("width", im.width / 2)
				canvasRef.current.setAttribute("height", im.height / 2)


				// canvasRef.current.getContext('2d').drawImage(im, 0, 0, 427, 240)
				canvasRef.current.getContext('2d').drawImage(im, 0, 0, im.width / 2, im.height / 2)
				ctx.strokeStyle = "#00ff00"
				ctx.strokeRect(boundingbox.Left * canvas.width, boundingbox.Top * canvas.height, boundingbox.Width * canvas.width, boundingbox.Height * canvas.height, 12)
				// ctx.strokeRect(boundingbox.Left * canvas.width, boundingbox.Top * canvas.height, boundingbox.Width * canvas.width, boundingbox.Height * canvas.height, 12)
				ctx.fillStyle = "#00ff00"
				ctx?.fillRect(boundingbox.Left * canvas.width, (boundingbox.Top * canvas.height) + (boundingbox.Height * canvas.height), boundingbox.Width * canvas.width, 25)
				ctx.fillStyle = "green"
				ctx.font = "12px Arial"
				ctx.fillText(similarity + "%" || "99%", boundingbox.Left * canvas.width + 5, (boundingbox.Top * canvas.height) + (boundingbox.Height * canvas.height) + 18)
			}

			// canvasRef.current.addEventListener("click", function(){
			// 	ctx.fillStyle = "rgb(200,100,0,.3)"
			// 	ctx.fillRect(Math.floor(Math.random() * 427), Math.floor(Math.random() * 427), 100, 100)
			// })

		}
	}

	useEffect(() => {
		initCanvas()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (image && boundingbox && similarity) {
		return (
			<>
				{/* <img ref={imageRef} src={image} alt="alt"/> */}
				{/* <pre>{JSON.stringify(boundingbox, null, 4)}</pre> */}
				<canvas
					ref={canvasRef}
					// width="427"
					// height="240"
					style={{
						border: "none",
						// borderRadius: "12px"
					}} />
			</>
		)
	} else {
		return (
			<></>
		)
	}
}