import { fileManagerApi } from "@api/services/filemanager";
import MiniInfo from "@components/Dialogs/MiniInfo";
import useAuth from "@context/auth/AuthHook";
import useSession from "@context/session/SessionHook";
import { Search } from "@mui/icons-material";
import {
	Box,
	Button,
	Divider,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Typography,
	useTheme,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useActivity } from "../activity/activity.hook";
import { ActivityService } from "../activity/activity.service";
import TasksTableComponent from "./tasks/tasks.table.component";

/**
 *
 * @returns JSX Do Dashboard de usuário
 */
export default function AdminComponent() {
	const { state, setState } = useActivity();
	const history = useNavigate();
	const theme = useTheme();
	const { profile } = useAuth();
	const { setActiveStep, images } = useSession();
	const [sample, setOpenSample] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setAuditionError] = useState<any>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [biometriaError, setBiometriaError] = useState<any>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [videosError, setVideosError] = useState<any>(false);

	useEffect(() => {
		new ActivityService(fileManagerApi).findAll().then((res: any) => {
			setState({ ...state, activities: res.data });
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<Container maxWidth="xl" style={styles.compound}>
				<Grid container spacing={3}>
					{/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: (biometriaError || videosError) ? "" : "none" }}>
						<Alert severity="error" sx={{ marginBottom: "12px", display: biometriaError ? "" : "none" }} onClose={() => setBiometriaError(null)}>{biometriaError && "Nenhuma biometria encontrada!"}</Alert>
						<Alert severity="error" sx={{ marginBottom: "12px", display: videosError ? "" : "none" }} onClose={() => setVideosError(null)}>{videosError && "Nenhuma atividade encontrada!"}</Alert>
					</Grid> */}
					<Grid
						item
						p={3}
						sm={12}
						md={12}
						mt={3}
						mb={3}
						style={{
							textAlign: "center",
						}}
					>
						<Typography
							variant="h5"
							component={"h6"}
							style={styles.title}
						>
							Olá <br />
							{profile && profile.nome ? (
								<React.Fragment>
									<span
										style={{
											color: theme.palette.primary.main,
										}}
									>
										{profile.nome}
									</span>
									<br />
								</React.Fragment>
							) : (
								<></>
							)}
							seja bem vindo(a) ao Palco Virtual
						</Typography>
						<Typography variant="body1" component={"p"}>
							Esse é o espaço destinado a apresentação em vídeo do
							seu relato de experiência. Siga as etapas para a
							submissão.
						</Typography>
					</Grid>

					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						mb={1}
						mt={0}
					>
						<Box
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								gap: "12px",
							}}
						>
							<Button
								variant="outlined"
								disableElevation
								disabled
								sx={{ visibility: "hidden" }}
								// startIcon={<Add />}
								onClick={() => {
									// window.location.href = "/activity"
									history("/activity");
								}}
							>
								✨ Criar nova atividade
							</Button>
							<MiniInfo
								open={sample}
								setOpen={setOpenSample}
								goAction={() => {
									if (images.length >= 5) {
										setActiveStep(1);
										window.location.href = "/recorder";
									} else {
										setActiveStep(0);
										window.location.href = "/biometrics";
									}
								}}
							/>
						</Box>
					</Grid>

					<Grid
						item
						md={12}
						sm={12}
						xs={12}
						lg={12}
						xl={12}
						mt={3}
						mb={3}
					>
						<Box mb={3}>
							{/* <Typography variant="h5" component="p" style={{ marginTop: "24px", marginBottom: "12px", fontWeight: "400" }}> Apresentações</Typography> */}
							<Typography variant="h5" component="div">
								Listagem de atividades
							</Typography>
							<Typography
								variant="body2"
								component="div"
								sx={{ marginTop: "12px" }}
							>
								Visualize a seguir suas apresentações submetidas
								para o Palco Virtual
							</Typography>

							<Divider sx={{ marginTop: "24px" }} />
						</Box>
						<Grid
							container
							spacing={1}
							sx={{
								border: "solid thin #ccc",
								borderRadius: "4px",
								padding: "12px 24px",
								display: "none",
							}}
						>
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<Box
									sx={{
										padding: "6px 24px",
										display: "flex",
										justifyContent: "flex-start",
										alignItems: "center",
									}}
								>
									<Typography>Exibir: </Typography>
								</Box>
							</Grid>
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<Box>
									<FormControl fullWidth size="small">
										<InputLabel>
											Buscar atividade
										</InputLabel>
										<OutlinedInput
											endAdornment={
												<InputAdornment position="end">
													<IconButton>
														<Search />
													</IconButton>
												</InputAdornment>
											}
										/>
									</FormControl>
								</Box>
							</Grid>
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<Box>
									<FormControl fullWidth size="small">
										<InputLabel>
											Título da atividade
										</InputLabel>
										<OutlinedInput
											endAdornment={
												<InputAdornment position="end">
													<IconButton>
														<Search />
													</IconButton>
												</InputAdornment>
											}
										/>
									</FormControl>
								</Box>
							</Grid>
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<Box>
									<FormControl fullWidth size="small">
										<InputLabel>Autor</InputLabel>
										<OutlinedInput
											endAdornment={
												<InputAdornment position="end">
													<IconButton>
														<Search />
													</IconButton>
												</InputAdornment>
											}
										/>
									</FormControl>
								</Box>
							</Grid>
						</Grid>
						<TasksTableComponent
							activities={
								state.activities ? state.activities : []
							}
						/>
						{/* <Box sx={{ display: "flex", justifyContent: "center" }}>
							<Pagination count={5} sx={{ marginTop: "24px", marginBottom: "12px" }} />
						</Box> */}
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	);
}

const styles = {
	compound: {
		marginTop: "36px",
		marginBottom: "10vh",
	},
	title: {
		marginBottom: "24px",
	},
	centerText: {
		textAlign: "center",
	},
};
