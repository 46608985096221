import { Alert, AlertTitle, Box, Button, Container, Grid, Typography } from "@mui/material";
import Appbar from "@components/Appbar";

export default function ErrorPage() {
	return (
		<>
			<Appbar />
			<Container>
				<Grid>
					<Grid item md={12}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								marginTop: "36px"
							}}>
							{/* <img width="30%" style={{ alignSelf: "center", marginBottom: "24px" }} src="https://m.media-amazon.com/images/I/81de8gMVOsL.jpg" alt="" />							 */}
							<Alert severity="error">
								<AlertTitle>Falha ao carregar o(s) recurso(s)</AlertTitle>
								<Typography variant="body1">Não foi possível carregar o conteúdo, clique no botão abaixo para retornar para a página inicial</Typography>
							</Alert>

							<Box mt={3}>
								<Button
									variant="contained"
									disableElevation
									onClick={() => {
										window.location.href = "/"
									}}>
									Retornar à tela inicial
								</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</>
	)
}