import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogContentText, Typography, useTheme } from '@mui/material';
import { VideoCallOutlined } from '@mui/icons-material';

interface PropType {
	open: boolean,
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	url?: string;
}

export default function GenericDialog(props: PropType) {

	const theme = useTheme();
	const handleClose = () => {
		props.setOpen(false);
	};

	return (
		<React.Fragment>
			<Dialog
				fullWidth={true}
				maxWidth={'sm'}
				open={props.open}
				onClose={handleClose}>
				<Box
					p={1}
					style={{
						backgroundColor: theme.palette.primary.main,
						color: "white",
						display: "flex",
						gap: "12px"
					}}>
					<VideoCallOutlined />
					<Typography variant="body1">Reproduzindo sua apresentação</Typography>
				</Box>
				<DialogContent>
					<DialogContentText style={{ marginBottom: "12px" }}>
						Apresentação realizada por meio do <strong>Palco Virtual</strong>
					</DialogContentText>

					<Box mt={2}>
						<video
							width="100%"
							height="auto"
							autoPlay
							controls>
							<source src={props.url} type={"video/mp4"} />
						</video>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleClose}>Fechar</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}