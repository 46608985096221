import AuthProvider from "@context/auth/auth.provider";
import Router from "@router/index";
import React from "react";
// import Infoband from '@components/Debug/Infoband';

import "./App.css";

/**
 * @description App contendo os providers e as rotas do projeto
 * @returns
 */
function App() {
	return (
		<React.Fragment>
			<AuthProvider>
				<Router />
			</AuthProvider>
			{/* <Infoband /> */}
		</React.Fragment>
	);
}

export default App;
