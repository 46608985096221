import { useReducer } from "react";
import { ProfileContext } from "./profile.context";

const INIT_STATE = {
	ra: null,
	email: null,
	nome: null,
	funcao: null,
	is_aluno: null,
}

function reducer(state: any, action: any) {
	switch (action.type) {
		case 'RESET_PROFILE':
			return state.perfil = {
				ra: "",
				email: "",
				nome: "",
				funcao: null,
				is_aluno: null,
			}
		case 'SET_PROFILE':
			return state.perfil = action.payload
		case 'UPDATE_PROFILE':
			return state.perfil = action.payload
	}
}

export function ProfileProvider({ children }: any) {
	const [perfil, profileDispatch] = useReducer<any>(reducer, INIT_STATE)
	return (
		<ProfileContext.Provider
			value={{
				perfil,
				profileDispatch
			}}>
			{children}
		</ProfileContext.Provider>
	)
}