import { ecossistema } from "@api/services/ecossistema";
/**
 *
 * @returns {
 *       AccessToken: string,
 *       ExpiresIn: number,
 *       TokenType: string,
 * }
 * @description realiza a autenticação do usuário do tipo Aluno
 * @param props
 */
export const Login = async (
   props: {
       ra: String;
       password: String;
   }
): Promise<any> => {
   return await ecossistema.post("nti/login", {
       ra: props.ra,
       password: props.password,
   });
};


/**
 * 
 * @param props 
 * @returns 
 */
export const Profile = async(
    props: {
        ra: String
    },
) : Promise<any> => {
    return await ecossistema.get(`nti/profile/${props.ra}`)
}


/**
 * 
 * @param props 
 * @returns 
 */
export const Curso = async(
    props: {
        ra: String
    },
) : Promise<any> => {
    return await ecossistema.get(`nti/cursos/${props.ra}`)
}

/**
 * 
 * @returns 
 */
export async function Logout() {
    return await ecossistema.post("/logout");
}
