import { fileManagerApi } from "./filemanager"

/**
 * 
 * @param ra 
 * @returns 
 */
export const getBiometria = async (ra: number|string, label?: string) => {
    return fileManagerApi.get(`/biometrics?ra=${ra}&type=image&label=${label}`)
}

/**
 * 
 * @param id 
 * @returns 
 */
export const deleteFile = async (id: number|string) => {
    return fileManagerApi.delete(`/file-delete`,{
        params: {
            id: id
        }
    })
}