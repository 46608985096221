import useAuth from "@context/auth/AuthHook";
import NotAllowed from "@pages/NotAllowed";
import ReviewUI from "./revision.component";

export default function RevisionModule() {
	const { state } = useAuth();
	return state.user.professor || state.user.moodle_admin ? (
		<ReviewUI />
	) : (
		<NotAllowed />
	);
}
