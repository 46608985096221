import axios, { AxiosInstance } from "axios";

export class ActivityService {
		
	constructor(private api: AxiosInstance){
		this.api = axios.create({
			baseURL: process.env.REACT_APP_FILE_MANAGER_API
		})

		this.api.interceptors.request.use((config) => {
			let user: any = localStorage.getItem("@application");
			let token: any = localStorage.getItem("token");
		
			if (token && config.headers) {
				user = JSON.parse(user).usuario;
				token = JSON.parse(token);
		
				config.headers["Authorization"] = `Bearer ${token}`;
				config.headers["ra"] = user?.id;
			}
		
			return config;
		});

		this.api.interceptors.response.use(
            (response) => response,
            (error) => {
                const { status } = error.response;

                if (status === 401 || status === 403) {
                    // Clear user session and storage
                    localStorage.removeItem("@application");
                    localStorage.removeItem("token");

                    // Redirect to '/'
                    window.location.href = '/';
                }

                return Promise.reject(error);
            }
        );
	}
	
	async getAll(params?: any) {
		return this.api.get("/activities", {params: params})
	}

	async findAll(params?: any) {
		return this.api.get("/activities?item=1", {params: params})
	}

	async findOne(id: string|number, params?: any) {
		// alert("ALO")
		return this.api.get(`/activities/${id}?item=1&audition=1`, {
			params: params
		} )
	}
	
	async create(activity: any, user: string|number) {
		return await this.api.post("/activities", {
			...activity,
			user_id: user
		})
	}

	async update (data: any) {
		const { id } = data
		delete data.id
		delete data.created_at 
		delete data.updated_at
		delete data.audition
		delete data.item 
		return await this.api.put(`/activities/${id}`, data)
	}
	async delete(id: string|number) {
		return await this.api.delete(`/activities/${id}`)
	}
	
	async getAllCheckItems() {
		return await this.api.get("/items")
	}

	async getAllCheckItemsByActivityId(id: number|string) {
		return await this.api.get(`/items/${id}`)
	}
	
	async createCheckItem(data: any) {
		return await this.api.post("/items", data)
	}
	
	async updateCheckItem(id: string|number, data: any) {
		return await this.api.put(`/check-items/${id}`, data)
	}
	async deleteCheckItem(id: string|number) {
		return await this.api.delete(`/items/${id}`)
	}
}