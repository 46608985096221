import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box } from '@mui/material';
import { Close, Menu } from '@mui/icons-material';

import AppDrawer from '@components/Drawer';
// import BasicTabs from '../../Tabs/BasicTabs';


interface PropType {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	children: any;
	title?: string;
}

export const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogShell(props: PropType) {




	const [open, setOpen] = React.useState<boolean>(false);

	const handleClose = () => {
		props.setOpen(false);
	};




	return (
		<div>
			<Dialog
				fullScreen
				open={props.open}
				onClose={handleClose}
				TransitionComponent={Transition}

			>
				<AppBar
				// sx={{ position: 'relative', width: `calc(100% - ${296}px)`, ml: `${256}px` }}
				>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={() => setOpen(true)}
							aria-label="close"
						>
							<Menu />
						</IconButton>
						{/* <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton> */}
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							{props.title}
						</Typography>
						<IconButton autoFocus color="inherit" onClick={handleClose}>
							<Close />
							{/* Fechar */}
						</IconButton>
					</Toolbar>
				</AppBar>

				<AppDrawer open={open} setOpen={setOpen} />

				<Box
					sx={{
						// border: "solid red", 
						paddingTop: "72px"
					}}>
					{props.children}
				</Box>
			</Dialog>
		</div>
	);
}