import axios, { AxiosInstance } from "axios";

export default class AuditionService {

	constructor(private api: AxiosInstance) {
		this.api = axios.create({ 
			baseURL: process.env.REACT_APP_FILE_MANAGER_API
		})
		
		this.api.interceptors.request.use((config) => {
			let user: any = localStorage.getItem("@application");
			let token: any = localStorage.getItem("token");
		
			if (token && config.headers) {
				user = JSON.parse(user).usuario;
				token = JSON.parse(token);
		
				config.headers["Authorization"] = `Bearer ${token}`;
				config.headers["ra"] = user?.id;
			}
		
			return config;
		});
	}

	async findAll(id: number|string, params?: any) {
		return await this.api.get(`/auditions`, {
			params: {
				activity_id: id,
				activity: 1,
				user: 1,
				recognition: 1,
				transcription: 1,
				...params
			}
		})
	}

	async getAll(ra: string|number, params?: any) {
		return await this.api.get("/auditions",{
			params: {
				user_id: ra ? ra : null,
				...params
			}
		})
	}

	async getOneById( id: string|number) {
		return await this.api.get(`/auditions/${id}`)
	}

	async getOne(code: string|number, id?: string|number, params?: any) {
		return await this.api.get("/auditions",{
			params: {
				code: code,
				activity_id: id,
				activity: 1,
				user: 1,
				recognition: 1,
				transcription: 1
			}
		})
	}

	async create(data: any) {
		return await this.api.post('/auditions', data)
	}

	async update(id: number|string, data: any) {
		return await this.api.put(`/auditions/${Number(id)}`, data);
	}

	async delete(id: number|string) {
		return await this.api.delete(`/auditions/${Number(id)}`)
	}
}