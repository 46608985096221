import { fileManagerApi } from "@api/services/filemanager";
import Auditions from "@components/Auditions";
import MiniInfo from "@components/Dialogs/MiniInfo";
import BasicTable from "@components/Tables/BasicTable";
import useAuth from "@context/auth/AuthHook";
import useSession from "@context/session/SessionHook";
import { Add } from "@mui/icons-material";
import { Box, Button, Divider, Grid, Hidden, Typography, useTheme } from "@mui/material";
import { Container } from "@mui/system";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";

/**
 * 
 * @returns JSX Do Dashboard de usuário
 */
export default function DashboardUI() {

	const theme = useTheme();
	const { user, profile } = useAuth();

	const [sample, setOpenSample] = useState<boolean>(false);
	const { setActiveStep, setVideos, setImages, images, auditions, setAuditions } = useSession();


	useEffect(() => {

		fileManagerApi.get(`/auditions/index`, {
			params: {
				ra: user.ra
			}
		}).then((response: AxiosResponse<any>) => {
			setAuditions(response.data);
			localStorage.setItem("auditions", JSON.stringify(response.data));
		})

		fileManagerApi.get(`/file-retrieve?ra=${user.ra}&type=image`)
			.then((response: AxiosResponse<any>) => {
				// setImages(response.data.files)
				setImages(response.data)
			});

		fileManagerApi.get(`/file-retrieve?ra=${user.ra}&type=video`)
			.then((response: AxiosResponse<any>) => {
				// setVideos(response.data.files)
				setVideos(response.data)
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<Container maxWidth="lg" style={styles.compound}>
				<Grid container spacing={3}>
					<Grid item p={3} sm={12} md={12} mt={3} mb={3}
						style={{
							textAlign: "center",
						}}>
						<Typography variant="h5" component={"h6"} style={styles.title}>
							Olá <br />
							{
								profile
									&& profile.nome
									? <React.Fragment>
										<span style={{ color: theme.palette.primary.main }}>{profile.nome}</span>
										<br />
									</React.Fragment>
									: <></>
							}
							seja bem vindo(a) ao  Palco Virtual
						</Typography>
						<Typography
							variant="body1"
							component={"p"}
						>
							Esse é o espaço destinado a apresentação em vídeo do seu relato de experiência. Siga as etapas para a submissão.
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={1} mt={0}>
						<Box
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								gap: "12px"
							}}>
							<Button
								variant="contained"
								disableElevation
								startIcon={<Add />}
								onClick={() => {
									setOpenSample(true)
								}}>
								Iniciar nova apresentação
							</Button>
							<MiniInfo
								open={sample}
								setOpen={setOpenSample}
								goAction={() => {
									if (images.length >= 5) {
										setActiveStep(1);
										window.location.href = "/recorder";
									} else {
										setActiveStep(0);
										window.location.href = "/biometrics";
									}
								}} />
						</Box>
					</Grid>


					<Grid item md={12} sm={12} xs={12} lg={12} xl={12} mt={3} mb={3}>
						<Box mb={3}>
							{/* <Typography variant="h5" component="p" style={{ marginTop: "24px", marginBottom: "12px", fontWeight: "400" }}> Apresentações</Typography> */}
							<Typography variant="h5" component="div">Listagem de Apresentações</Typography>
							<Typography variant="body1" component="div">Visualize a seguir suas apresentações submetidas para o Palco Virtual</Typography>

							<Divider sx={{ marginTop: "24px" }} />
						</Box>
						<Hidden mdDown>
							<BasicTable videos={auditions && auditions.user_existing_auditions ? auditions.user_existing_auditions : []} />
						</Hidden>
						<Hidden mdUp>
							<Auditions />
						</Hidden>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	)
}

const styles = {
	compound: {
		marginTop: '36px',
		marginBottom: "10vh"
	},
	title: {
		marginBottom: "24px",
	},
	centerText: {
		textAlign: "center",
	},
};