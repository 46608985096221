import axios, { AxiosInstance } from "axios";

class AuthService {

	constructor(private api: AxiosInstance){
		this.api = axios.create({
			baseURL: process.env.REACT_APP_FILE_MANAGER_API
		})
	}
		
	async login(ra: string, password: string) {
		return this.api.post("auth/login", {ra, password})
	}

}

export default AuthService