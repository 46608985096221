import { Backdrop, Box, Button, CircularProgress, Container, Typography } from "@mui/material";
import { useEffect } from "react";

/**
 * @description tela exibida para o usuário na autenticação via URL
 * @returns 
 */
export default function LoadingUI() {

	useEffect(() => {
		setInterval(() => {
			window.location.href = "/"
		}, 3000)
	},[])

	return (
		<Container>
			<Backdrop open={true}>
				<CircularProgress />
			</Backdrop>
			<Box sx={{
				height: "100vh",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}>
				<Box mt={1} mb={1} sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}>
					<img width="50%" style={{ alignSelf: "center" }} src="images/Writing room.svg" alt=""></img>
				</Box>
				<Typography variant="h5" component="div" sx={{ textAlign: "center", marginTop: "12px"}}>Aguarde, estamos redirecionando você para o <br />Palco Virtual</Typography>
				<Button
					sx={{
						marginTop: "12px"
					}}
					variant="outlined"
					onClick={() => window.location.href = "/"}>
					Prosseguir para o Palco Virtual
				</Button>
			</Box>
		</Container>
	)
}