import { useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";

export default function AuthProvider({ children }: any) {

	const [state, setState] = useState<any>(null);

	useEffect(() => {
		const data = JSON.parse(localStorage.getItem("@application") as string)

		if (data) {
			setState(data);
		}

	}, [])

	return (
		<AuthContext.Provider value={{
			state,
			setState
		}}>
			{children}
		</AuthContext.Provider>
	)
}