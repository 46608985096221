import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Container } from '@mui/system';
import { Box, Grid } from '@mui/material';
import { Close, Menu } from '@mui/icons-material';

import AppDrawer from '@components/Drawer';
import BasicTabs from '@components/Tabs/BasicTabs';


interface PropType {
	open: boolean,
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function DebugDialog(props: PropType) {


	const [open, setOpen] = React.useState<boolean>(false);

	const handleClose = () => {
		props.setOpen(false);
	};




	return (
		<div>
			<Dialog
				fullScreen
				open={props.open}
				onClose={handleClose}
				TransitionComponent={Transition}

			>
				<AppBar
				// sx={{ position: 'relative', width: `calc(100% - ${296}px)`, ml: `${256}px` }}
				// sx={{ backgroundColor: theme.palette.primary.main}}
				>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={() => setOpen(true)}
							aria-label="close"
						>
							<Menu />
						</IconButton>
						{/* <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton> */}
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Meus dados
						</Typography>
						<IconButton autoFocus color="inherit" onClick={handleClose}>
							<Close />
							{/* Fechar */}
						</IconButton>
					</Toolbar>
				</AppBar>

				<AppDrawer open={open} setOpen={setOpen} />

				<Container
					maxWidth="md"
					sx={{
						// border: "solid red", 
						paddingTop: "72px"
					}}
				>
					<Grid item md={12} >
						<Box>
							<Box
								mt={3}
								mb={3}
								style={{
									textAlign: "center",
									alignItems: "center",
									// margin: "24px 24px"
								}}>
								{/* <img width="70%" style={{ alignSelf: "center" }} src="https://www.gstatic.com/subscriptions/management/home/welcome_balcony_21x9_09b505fb1e95bcaea553806b5d7eff59.svg" alt=""></img> */}
								<img width="50%" style={{ alignSelf: "center" }} src="images/Writing room.svg" alt=""></img>
								<Typography variant="h5">Apresentações anteriores</Typography>
								<Typography variant="body2">Palco Virtual</Typography>

								<Typography variant="body2" component="div" style={{ marginTop: "12px" }}>
									Esse é o espaço destinado a apresentação em vídeo do seu relato de experiência. Siga as etapas para a submissão.
								</Typography>

								<Box style={{ marginTop: "24px" }}>
									<BasicTabs />
								</Box>
								{/* <Box style={{
                                    marginTop: "24px"
                                }}>
                                    <Button variant="contained" disableElevation>Videos</Button>
                                    <Button>Biometria</Button>
                                    <Button>Amostras</Button>
                                    <Button>Estatísticas</Button>
                                    <Button>Tutoriais</Button>
                                    <Button>Ajuda</Button>
                                </Box> */}
							</Box>

						</Box>
					</Grid>
				</Container>
			</Dialog>
		</div>
	);
}