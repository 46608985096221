import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// import Typography   from '@mui/material/Typography';
import useSession from '@context/session/SessionHook';

const steps = ['Cadastrar biometria', 'Realizar apresentação', 'Revisar apresentação', 'Apresentação submetida'];

export default function ProgressIndicator() {

	const { activeStep, skipped } = useSession();

	const isStepOptional = (step: number) => {
		return step === 1;
	};

	const isStepSkipped = (step: number) => {
		return skipped.has(step);
	};

	// const handleNext = () => {
	//     let newSkipped = skipped;
	//     if (isStepSkipped(activeStep)) {
	//         newSkipped = new Set(newSkipped.values());
	//         newSkipped.delete(activeStep);
	//     }

	//     setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
	//     setSkipped(newSkipped);
	// };

	// const handleBack = () => {
	//     setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
	// };

	// const handleSkip = () => {
	//     if (!isStepOptional(activeStep)) {
	//         // You probably want to guard against something like this,
	//         // it should never occur unless someone's actively trying to break something.
	//         throw new Error("You can't skip a step that isn't optional.");
	//     }

	//     setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
	//     setSkipped((prevSkipped: any) => {
	//         const newSkipped = new Set(prevSkipped.values());
	//         newSkipped.add(activeStep);
	//         return newSkipped;
	//     });
	// };

	// const handleReset = () => {
	//     setActiveStep(0);
	//     window.location.href = "/";
	// };

	return (
		<Box mt={3}>
			<Stepper activeStep={activeStep} alternativeLabel>
				{
					steps.map((label, index) => {
						const stepProps: { completed?: boolean } = {};
						const labelProps: {
							optional?: React.ReactNode;
						} = {};
						if (isStepOptional(index)) {
							labelProps.optional = (
								<span></span>
							);
						}
						if (isStepSkipped(index)) {
							stepProps.completed = false;
						}
						return (
							<Step key={label} {...stepProps}>
								<StepLabel {...labelProps}>{label}</StepLabel>
							</Step>
						);
					})
				}
			</Stepper>
			{
				activeStep === steps.length ? (
					<React.Fragment>
						<Box mb={3}></Box>
					</React.Fragment>
				) : (
					<React.Fragment>
						<Box mb={3}></Box>
					</React.Fragment>
				)
			}
		</Box>
	);
}