import useAuth from "@context/auth/AuthHook";
import SessionProvider from "@context/session/SessionProvider";
import AdminModule from "../admin";
import StudentModule from "../student";
import PanelLoaderComponent from "./panel.loader.component";

export default function PanelModule() {
	const { state } = useAuth();

	if (state.usuario.ra === null) {
		return (
			<SessionProvider>
				<PanelLoaderComponent />
			</SessionProvider>
		);
	} else {
		return (
			<SessionProvider>
				{state.user.professor || state.user.moodle_admin ? (
					<AdminModule />
				) : (
					<StudentModule />
				)}
			</SessionProvider>
		);
	}
}
