
import ActivityProvider from "../activity.provider";
import CheckItemCreateComponent from "./checkitem.create.component";

export default function CreateCheckItemDialog(props: any) {
	return <>
		<ActivityProvider>
			<CheckItemCreateComponent open={props.open} setOpen={props.setOpen} activity_id={props.activity_id} setData={props.setData} />
		</ActivityProvider>
	</>
}