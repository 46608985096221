import axios, { AxiosInstance } from "axios";

// const REKOGNITION_URL = "https://9jjzixt9z2.execute-api.us-east-1.amazonaws.com";

export interface RekognitionServiceInterface {
	compareFaces(payload: any): Promise<any>;
}

export class RekognitionService {

	/* constructor(
		private readonly api: AxiosInstance = axios,
		private readonly recognition: AxiosInstance = axios
	) {
		this.api.defaults.baseURL = REKOGNITION_URL;
		this.recognition = axios.create({
			baseURL: process.env.REACT_APP_FILE_MANAGER_API
		})

		this.api.interceptors.request.use((config) => {
			let user: any = localStorage.getItem("@application");
			let token: any = localStorage.getItem("token");
		
			if (token && config.headers) {
				user = JSON.parse(user).usuario;
				token = JSON.parse(token);
		
				config.headers["Authorization"] = `Bearer ${token}`;
				config.headers["ra"] = user?.id;
			}
		
			return config;
		});
	}*/
	private api: AxiosInstance;
	private recognition: AxiosInstance;

	constructor(){
		
		this.api = axios.create({
			baseURL: process.env.REACT_APP_FILE_MANAGER_API
		})

		this.api.interceptors.request.use((config) => {
			let user: any = localStorage.getItem("@application");
			let token: any = localStorage.getItem("token");
		
			if (token && config.headers) {
				user = JSON.parse(user).usuario;
				token = JSON.parse(token);
		
				config.headers["Authorization"] = `Bearer ${token}`;
				config.headers["ra"] = user?.id;
			}
		
			return config;
		});

		this.recognition = axios.create({
			baseURL: process.env.REACT_APP_FILE_MANAGER_API
		})
	}

	async compareFaces(payload: any): Promise<any> {
		return await this.recognition.post("https://9jjzixt9z2.execute-api.us-east-1.amazonaws.com", {payload});
	}

	async create(payload: any): Promise<any> {
		return this.api.post("/recognitions", payload)
	}

	async update(id: number, payload: any): Promise<any> {
		return this.api.put("/recognitions/", payload )
	}
}