/* eslint-disable react-hooks/exhaustive-deps */
import { fileManagerApi } from "@api/services/filemanager";
import AuthService from "@context/auth/auth.service";
import useAuth from "@context/auth/AuthHook";
import { CameraIndoor, Visibility, VisibilityOff } from "@mui/icons-material";
import {
	Alert,
	AlertTitle,
	Avatar,
	Backdrop,
	CircularProgress,
	IconButton,
	useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AxiosResponse } from "axios";
import * as React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

/**
 * @description Tela de login do webapp
 * @returns
 */
export default function SignIn() {
	const theme = useTheme();
	const params = useParams();

	React.useEffect(() => {
		// alert(JSON.stringify(params, null, 4));
		localStorage.setItem("@recorder", JSON.stringify(params));
	}, [params]);

	const { setState } = useAuth();

	const [open, setOpen] = React.useState<boolean>(false);

	const [showPassword, setShowPassword] = React.useState<boolean>(false);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		setOpen(true);
		event.preventDefault();
		const data = new FormData(event.currentTarget);

		new AuthService(fileManagerApi)
			.login(data.get("ra") as string, data.get("password") as string)
			.then((res: AxiosResponse<any>) => {
				localStorage.setItem("@application", JSON.stringify(res.data));
				localStorage.setItem(
					"@profile",
					JSON.stringify(res.data.usuario)
				);
				localStorage.setItem(
					"token",
					JSON.stringify(res.data.accessToken)
				);
				setState(res.data);
			})
			.catch((err) => {
				toast.error("Não foi possível autenticar no Palco Virtual");
			})
			.finally(() => {
				setOpen(false);
			});
	};

	return (
		<>
			<Backdrop open={open} sx={{ zIndex: 99999 }}>
				<CircularProgress />
			</Backdrop>
			<Container component="main" maxWidth="sm">
				<CssBaseline />

				<Box
					sx={{
						marginTop: 8,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Box mb={3}>
						{/* <img src="https://uninta.edu.br/index_files/logo.svg" alt="" width="96" /> */}
						{/* <Avatar
						sx={{
							width: "96px",
							height: "96px",
							backgroundColor: theme.palette.primary.main,
						}}>
						<DataArray />
					</Avatar> */}
					</Box>
					<Avatar
						sx={{
							m: 2,
							bgcolor: "secondary.main",
							width: "48px",
							height: "48px",
						}}
					>
						<CameraIndoor />
					</Avatar>
					<Typography component="h1" variant="h5">
						Entrar no Palco Virtual
					</Typography>
					<Typography
						component="h1"
						variant="body1"
						sx={{ marginTop: "12px" }}
					>
						{" "}
						Para prosseguir para{" "}
						<span style={{ color: theme.palette.primary.light }}>
							palcovirtual.aiamis.com.br
						</span>{" "}
					</Typography>

					<Box mt={2} mb={1}>
						<Alert severity="info">
							<AlertTitle>Aviso sobre compatibilidade</AlertTitle>
							<Typography variant="body2" component="div">
								Para uma maior compatibilidade utilize os
								navegadores <strong>Google Chrome</strong> ou{" "}
								<strong>Safari</strong>
							</Typography>
						</Alert>
					</Box>
					<Box
						component="form"
						onSubmit={handleSubmit}
						noValidate
						sx={{ mt: 1 }}
					>
						{/* <Box mt={3} mb={3}>
						{
							((state && state.errorStatus && state.errorStatus === 401) || tokenError)
							&& <Alert
								variant='outlined'
								severity="error">
								RA ou Senha incorreta! Por favor, tente novamente.
							</Alert>
						}
					</Box> */}
						<TextField
							role=""
							margin="normal"
							required
							fullWidth
							id="ra"
							label="Ra"
							name="ra"
							autoComplete="email"
							autoFocus
							// helperText={state.error && state.error.errors.find((item: any) => item.field === "ra") && "Ra incorreto"}
							// error={state.error && state.error.errors.find((item: any) => item.field === "ra") ? true : false}
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label="Senha"
							type={showPassword ? "text" : "password"}
							id="password"
							autoComplete="current-password"
							// helperText={state.error && state.error.errors.find((item: any) => item.field === "password") && "Senha incorreta"}
							// error={state.error && state.error.errors.find((item: any) => item.field === "password") ? true : false}
							InputProps={{
								endAdornment: (
									<IconButton
										role="button"
										name="visibility button"
										aria-label="visibility button"
										onClick={() =>
											setShowPassword(!showPassword)
										}
									>
										{showPassword ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</IconButton>
								),
							}}
						/>
						<FormControlLabel
							control={
								<Checkbox value="remember" color="primary" />
							}
							label="Permanecer conectado"
						/>
						<Button
							role="button"
							name="login button"
							color="primary"
							type="submit"
							fullWidth
							variant="contained"
							disableElevation
							size={"large"}
							sx={{ mt: 3, mb: 2 }}
						>
							Login
						</Button>
						<Grid container>
							<Grid item xs>
								<Link href="#" variant="body2">
									Esqueceu sua senha?
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Copyright sx={{ mt: 8, mb: 4 }} />
			</Container>
		</>
	);
}

function Copyright(props: any) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			<Link
				color="inherit"
				href="https://ecossistema.aiamis.com.br/"
				style={{ textDecoration: "none" }}
			>
				Centro Universitário UNINTA
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}
