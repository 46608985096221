import ActivityComponent from "./activity.component";
import ActivityProvider from "./activity.provider";
import Appbar from "@components/Appbar";

export default function ActivityModule() {
	return (
		<ActivityProvider>
			<Appbar />
			<ActivityComponent />
		</ActivityProvider>
	);
}