import { fileManagerApi } from "@api/services/filemanager";
import ProgressIndicator from "@components/ProgressIndicator";
import useSession from "@context/session/SessionHook";
import { CopyAll, DoneAll } from "@mui/icons-material";
import {
	Avatar,
	Box,
	Button,
	Container,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AuditionService from "../student/audition.service";

export default function SubmissionComponent() {
	const params = useParams();
	const { setActiveStep } = useSession();
	const { auditions, browserAgent } = useSession();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));
	const [submission, setSubmission] = React.useState({
		file_key: "",
		file_url: "",
		code: "",
	});

	React.useEffect(() => {
		setActiveStep(4);
		if (params.id)
			new AuditionService(fileManagerApi)
				.getOneById(params.id)
				.then((res: any) => {
					setSubmission(res.data);
				});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<React.Fragment>
				<Container>
					<ProgressIndicator />
				</Container>
				<Container
					maxWidth="lg"
					sx={{
						height: "75vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						marginBottom: "10vh",
					}}
				>
					<Box
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							marginTop: "24px",
							marginBottom: "24px",
						}}
					>
						<Avatar
							sx={{
								m: 2,
								bgcolor: "primary.main",
							}}
						>
							<DoneAll />
						</Avatar>
						<Typography sx={{ mt: 2, mb: 1, textAlign: "center" }}>
							Parabéns o envio da sua apresentação foi realizado
							com sucesso ! copie e envie o código da sua
							apresentação na atividade em sala virtual.
						</Typography>
						<Typography>Código da apresentação</Typography>
						{/* </Box>                */}
						<Box>
							<Box
								p={3}
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									pt: 2,
									gap: 2,
								}}
							>
								{/* <Box sx={{ flex: '1 1 auto' }} /> */}
								<Button
									sx={{ flexGrow: 1, display: "none" }}
									variant="contained"
									disableElevation
									onClick={() => {
										// getVideo(auditions.user_existing_auditions[0].media)
									}}
								>
									Assistir
								</Button>
								<Button
									variant="outlined"
									startIcon={<CopyAll />}
									onClick={() => {
										navigator.clipboard.writeText(
											submission.code
										);
										toast.success(
											"Código copiado com sucesso!",
											{
												position: "top-right",
											}
										);
									}}
								>
									{submission.code}
								</Button>
							</Box>
						</Box>
					</Box>
					<Box
						p={3}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{/* {submission && submission.file_key} */}
						{browserAgent ? (
							<video width="100%" height="auto" autoPlay controls>
								<source
									src={submission.file_url}
									// src={
									// 	"https://dirin.s3.us-east-1.amazonaws.com/" +
									// 	submission.file_key +
									// 	".mp4"
									// }
									type={"video/mp4"}
								/>
							</video>
						) : (
							<video
								src={submission.file_url}
								// src={
								// 	"https://dirin.s3.us-east-1.amazonaws.com/" +
								// 	submission.file_key
								// }
								width="100%"
								height="auto"
								controls
								style={{
									width: "60%",
								}}
							>
								<source
									src={submission.file_key}
									// src={
									// 	"https://dirin.s3.us-east-1.amazonaws.com/" +
									// 	submission.file_key
									// }
									type={"video/mp4"}
								/>
							</video>
						)}
					</Box>
					<Box
						style={{
							display: "flex",
							gap: "12px",
							alignItems: "center",
							justifyContent: "center",
						}}
						mb={3}
					>
						<Button
							sx={{ flexGrow: 0 }}
							variant="contained"
							disableElevation
							onClick={() => (window.location.href = "/")}
						>
							Minhas Apresentações
						</Button>
					</Box>
				</Container>
			</React.Fragment>
		</>
	);
}
