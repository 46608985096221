import Appbar from "@components/Appbar";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StudentComponent from "./student.component";

export default function StudentModule() {
	const history = useNavigate();

	useEffect(() => {
		// history("/student")
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Appbar />
			<StudentComponent />
		</>
	)
}