import { fileManagerApi } from "@api/services/filemanager";
import { ActivityService } from "@modules/activity/activity.service";
import { AddTask, Close } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	IconButton,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function CheckItemCreateComponent(props: any) {
	const theme = useTheme();
	const history = useNavigate();
	const [checkItem, setCheckItem] = useState<any>({});

	async function submitCheckItem() {
		await new ActivityService(fileManagerApi)
			.createCheckItem({
				...checkItem,
				activity_id: Number(props.activity_id),
			})
			.then((res) => {
				setCheckItem({});
				toast.success("Item adicionado com sucesso!");
			})
			.then(async () => {
				return await new ActivityService(fileManagerApi)
					.findOne(Number(props.activity_id))
					.then((res) => {
						props.setData(res.data);
					});
			})
			.then(async () => {
				if (props.parent === "PAGE") {
					history("/activity/" + props.activity_id);
				} else {
					history(`/activity/${props.activity_id}/edit`);
				}
			})
			.then(() => {
				props.setOpen(false);
			})
			.catch((err) => {
				toast.error(
					"Falha ao cadastrar o item, tente novamente mais tarde"
				);
			});
	}

	return (
		<Dialog
			maxWidth="lg"
			open={props.open}
			onClose={() => {
				props.setOpen(false);
			}}
			sx={{
				"& .MuiDialog-container": {
					"& .MuiPaper-root": {
						width: "90%",
						maxWidth: "95%", // Set your width here
					},
				},
			}}
		>
			<Box
				style={{
					backgroundColor: theme.palette.primary.main,
					color: "white",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					gap: "12px",
				}}
			>
				<Box p={1} sx={{ display: "flex", gap: "12px 8px" }}>
					<AddTask />
					<Typography variant="body1" component="div">
						Adicionar nova etapa
					</Typography>
				</Box>
				<Box>
					<IconButton
						sx={{ color: "white", padding: "12px" }}
						onClick={() => props.setOpen(false)}
					>
						<Close />
					</IconButton>
				</Box>
			</Box>
			<DialogContent>
				{/*<Box>*/}
				{/*	<pre>*/}
				{/*		{JSON.stringify(checkItem, null, 4)}*/}
				{/*		{JSON.stringify(props, null, 4)}*/}
				{/*	</pre>*/}
				{/*</Box>*/}
				<Box sx={{ textAlign: "center" }}>
					<Typography variant="h6" component="p">
						Adicionar nova etapa
					</Typography>
					{/* <Typography variant="body1" component="p">Adicionar nova etapa</Typography> */}
				</Box>

				<Box mt={3}>
					<Typography variant="h6" component="div">
						Nome do item de checagem
					</Typography>
					<Divider />
					<Box
						mt={2}
						mb={2}
						sx={{
							padding: "12px",
							border: "solid #ccc 1px",
							minHeight: "20vh",
							position: "relative",
							":focus-within": {
								border: "solid blue",
							},
						}}
					>
						<Typography variant="caption">
							Insira um título do tópico de checagem
						</Typography>
						<div>
							<TextField
								multiline
								rows={2}
								name="atividade_descricao"
								defaultValue={checkItem.title}
								style={{
									marginTop: "24px",
									width: "100%",
									padding: "12px 24px",
									border: "none",
									outline: "none",
								}}
								sx={{
									"& .MuiOutlinedInput-root": {
										"& > fieldset": {
											border: "none",
										},
									},
								}}
								onChange={(e) => {
									setCheckItem({
										...checkItem,
										title: e.target.value,
									});
								}}
							/>
						</div>
						<Box
							sx={{
								position: "absolute",
								bottom: "12px",
								right: "12px",
							}}
						>
							{/*<Typography variant="caption" sx={{ color: checkItem && checkItem.title && checkItem.description.length <= 256 ? "green" : "red" }}>{(checkItem && checkItem.title) ? checkItem.title.length : 0}/256</Typography>*/}
						</Box>
					</Box>

					<Typography variant="h6" component="div">
						Descrição do item de checagem
					</Typography>
					<Divider />
					<Box
						mt={2}
						mb={2}
						sx={{
							padding: "12px",
							border: "solid #ccc 1px",
							minHeight: "20vh",
							position: "relative",
							":focus-within": {
								border: "solid blue",
							},
						}}
					>
						<Typography variant="caption">
							Descrição do item de checagem
						</Typography>
						<TextField
							multiline
							rows={3}
							name="atividade_descricao"
							defaultValue={checkItem.description}
							placeholder="Na apresentação, aborde como se desenvolveu a experiência da ação de extensão, pontos positivos e negativos (utilizar o banner como roteiro de apresentação)."
							style={{
								marginTop: "24px",
								width: "100%",
								padding: "12px 24px",
								border: "none",
								outline: "none",
							}}
							sx={{
								"& .MuiOutlinedInput-root": {
									"& > fieldset": {
										border: "none",
									},
								},
							}}
							onChange={(e) => {
								setCheckItem({
									...checkItem,
									description: e.target.value,
								});
							}}
						/>
						<Box
							sx={{
								position: "absolute",
								bottom: "12px",
								right: "12px",
							}}
						>
							{/*<Typography variant="caption" sx={{ color: checkItem && checkItem.description && checkItem.description.length <= 256 ? "green" : "red" }}>{(checkItem && checkItem.description) ? checkItem.description.length : 0}/256</Typography>*/}
						</Box>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setCheckItem({});
						props.setOpen(false);
						history(`/activity/${props.activity_id}/edit`);
					}}
				>
					Cancelar
				</Button>
				<Button
					variant="contained"
					disableElevation
					disabled={checkItem.description ? false : true}
					onClick={submitCheckItem}
				>
					Salvar
				</Button>
			</DialogActions>
		</Dialog>
	);
}
