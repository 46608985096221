import SignIn from "@pages/public/SignIn/signin.component";
import TokenUI from "@pages/public/Token";
import { createBrowserRouter } from "react-router-dom";

/**
 * @description Rotas não autenticadas do projeto
 */
export const Public = createBrowserRouter([
	{
		path: "/",
		element: <SignIn />,
	},
	{
		path: "/signin/:token?",
		element: <TokenUI />,
	},
	{
		path: "/recorder/:id",
		element: <SignIn />,
	},
]);
