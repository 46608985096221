import DebugDialog from "@components/Dialogs/DebugDialog";
import DialogShell from "@components/Dialogs/DialogShell";
import useAuth from "@context/auth/AuthHook";
import {
	CameraAlt,
	Close,
	Face,
	Home,
	ListAltOutlined,
	PermMedia,
	Reviews,
} from "@mui/icons-material";
import {
	Box,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Typography,
	useTheme,
} from "@mui/material";
import Biometrics from "@pages/private/Biometrics/Biometrics";
import Stage from "@pages/private/Stage/Stage";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PropType } from "./types";

export default function AppDrawer(props: PropType) {
	const theme = useTheme();
	const { state } = useAuth();
	const history = useNavigate();

	const [openBiometria, setOpenBiometria] = useState<boolean>(false);
	const [openPalcoVirtual, setOpenPalcoVirtual] = useState<boolean>(false);
	const [openDebug, setOpenDebug] = useState<boolean>(false);

	return (
		<React.Fragment>
			<Drawer
				sx={{
					width: 256,
					flexShrink: 0,
					"& .MuiDrawer-paper": {
						width: 256,
						boxSizing: "border-box",
					},
				}}
				open={props.open}
				variant="persistent"
				anchor="left"
			>
				<Toolbar
					style={{ backgroundColor: theme.palette.primary.main }}
				>
					<IconButton
						onClick={() => props.setOpen(false)}
						sx={{
							color: "white",
						}}
					>
						<Close />
					</IconButton>
				</Toolbar>
				<Box
					p={1}
					sx={
						{
							// height: "200px",
							// backgroundColor: "red"
						}
					}
				>
					{/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
					<Typography
						dat-testid="drawer-title"
						variant="h6"
						component="div"
					>
						Palco Virtual
					</Typography>
				</Box>
				{/* <Divider /> */}
				<List>
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => (window.location.href = "/")}
						>
							<ListItemIcon>
								<Home />
							</ListItemIcon>
							<ListItemText primary="Início" />
						</ListItemButton>
					</ListItem>
					<ListItem
						disablePadding
						sx={{
							display:
								state.user.professor || state.user.moodle_admin
									? ""
									: "none",
						}}
					>
						<ListItemButton
							onClick={() => (window.location.href = "/review")}
						>
							<ListItemIcon>
								<Reviews />
							</ListItemIcon>
							<ListItemText primary="Revisão" />
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding sx={{ display: "none" }}>
						<ListItemButton
							onClick={() => {
								setOpenBiometria(true);
								props.setOpen(false);
							}}
						>
							<ListItemIcon>
								<Face />
							</ListItemIcon>
							<ListItemText primary="Biometria" />
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding sx={{ display: "none" }}>
						<ListItemButton
							onClick={() => {
								setOpenPalcoVirtual(true);
								props.setOpen(false);
							}}
						>
							<ListItemIcon>
								<CameraAlt />
							</ListItemIcon>
							<ListItemText primary="Gravação" />
						</ListItemButton>
					</ListItem>
					<ListItem
						disablePadding
						sx={
							{
								// display: profile.funcao === "PROFESSOR"
								// 	? profile.is_aluno
								// 		? ""
								// 		: "none"
								// 	: profile.funcao === "ALUNO"
								// 		? ""
								// 		: "none"
							}
						}
					>
						<ListItemButton
							role="button"
							data-testid="mydata-button"
							aria-label="mydata-button"
							onClick={() => {
								history("/myData");
							}}
						>
							<ListItemIcon>
								<PermMedia />
							</ListItemIcon>
							<ListItemText primary="Meus dados" />
						</ListItemButton>
					</ListItem>
					<ListItem
						disablePadding
						sx={{
							display:
								state.user.professor || state.user.moodle_admin
									? ""
									: "none",
						}}
					>
						<ListItemButton
							onClick={() => (window.location.href = "/listagem")}
						>
							<ListItemIcon>
								<ListAltOutlined />
							</ListItemIcon>
							<ListItemText primary="Consulta" />
						</ListItemButton>
					</ListItem>
				</List>
			</Drawer>
			<DialogShell
				open={openBiometria}
				setOpen={setOpenBiometria}
				children={<Biometrics />}
				title={"Biometria"}
			/>
			<DialogShell
				open={openPalcoVirtual}
				setOpen={setOpenPalcoVirtual}
				children={<Stage />}
				title={"Gravação"}
			/>
			<DebugDialog open={openDebug} setOpen={setOpenDebug} />
		</React.Fragment>
	);
}
