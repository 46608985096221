import { fileManagerApi } from "@api/services/filemanager";
import useAuth from "@context/auth/AuthHook";
import { Close, Search } from "@mui/icons-material";
import {
	Backdrop,
	Box,
	CircularProgress,
	Divider,
	FormControl,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import ActivityTable from "../student/tables/activity.table.component";

/**
 *
 * @returns JSX Do Dashboard de usuário
 */
export default function ListComponent() {
	const { state } = useAuth();

	const [allAuditions, setAllAuditions] = useState<any | any[]>([]);
	const [searchRa, setSearchRa] = useState<any>(null);
	// Signals
	const [waiting, setWaiting] = useState<boolean>(false);

	const refSearchRa = useRef<HTMLInputElement | null>(null);

	const handleResetAuditions = () => {
		setWaiting(true);
		setSearchRa(null);
		setAllAuditions([]);
		setWaiting(false);
	};

	const handleAudition = () => {
		/** Habilita o Backdrop, para sinalizar que uma busca está sendo realizada */
		setWaiting(true);

		fileManagerApi
			.get(`/auditions`, {
				params: {
					user_id: searchRa === "" ? null : searchRa,
					activity: 1,
					user: 1,
					recognition: 1,
					transcription: 1,
				},
			})
			.then((response: AxiosResponse<any>) => {
				/** Adiciona o resultado da consulta ao localStorage e ao contexto local;
				 * está pendente o useEffect para buscar dados de consultas armazenados localmente */
				setAllAuditions(response.data.data);
				localStorage.setItem(
					"@all_auditions",
					JSON.stringify(response.data)
				);
				if (response.data.length === 0) {
					toast.error(
						"Nenhuma apresentação encontrada para o RA " +
							JSON.stringify(searchRa as string)
					);
				} else {
					// console.clear();
					// console.log(response.data.data);
					toast.success(
						"Foram encontrados " +
							response.data.data.length +
							" resultados para o código: " +
							JSON.stringify(searchRa as string)
					);
				}
			})
			.catch((err) => {
				setAllAuditions([]);
			})
			.finally(() => {
				/** Remove o backdrop, para sinalizar que a busca foi finalizada */
				setWaiting(false);
			});
	};

	return (
		<React.Fragment>
			<Backdrop open={waiting}>
				<CircularProgress />
			</Backdrop>
			<Box style={styles.compound} p={2}>
				<Grid container spacing={3}>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						mb={1}
						mt={0}
					>
						{(state.user.professor || state.user.moodle_admin) && (
							<React.Fragment>
								<Box mb={3}>
									{/* <Typography variant="h5" component="p" style={{ marginTop: "24px", marginBottom: "12px", fontWeight: "400" }}> Apresentações</Typography> */}
									<Typography variant="h5" component="div">
										Todas as submissões
									</Typography>
									<Typography variant="body1" component="div">
										Visualize a seguir todas as audições
										submetidas para o Palco Virtual
									</Typography>

									<Divider sx={{ marginTop: "24px" }} />
								</Box>
								<Box mb={3}>
									<FormControl fullWidth>
										<TextField
											label="Ra do Estudante"
											variant="outlined"
											ref={refSearchRa}
											value={searchRa ?? ""}
											onChange={(e) =>
												setSearchRa(e.target.value)
											}
											onKeyDown={(e) => {
												if (e.key === "Enter")
													handleAudition();
											}}
											InputProps={{
												endAdornment: (
													<>
														<IconButton
															onClick={() => {
																handleResetAuditions();
																setSearchRa("");
																if (
																	refSearchRa.current
																) {
																	refSearchRa.current.value =
																		"";
																}
															}}
														>
															<Close />
														</IconButton>
														<IconButton
															onClick={
																handleAudition
															}
														>
															<Search />
														</IconButton>
													</>
												),
											}}
										/>
									</FormControl>
								</Box>
								{/* <Box>
										{
											searchRa
											? <BasicTable videos={allAuditions && allAuditions.user_existing_auditions ? allAuditions.user_existing_auditions : []} />
											: <BasicTable videos={allAuditions && allAuditions.length > 0 ? allAuditions : []} />
										}
										
									</Box> */}
							</React.Fragment>
						)}
					</Grid>
					<Grid
						item
						md={12}
						sm={12}
						xs={12}
						lg={12}
						xl={12}
						mt={3}
						mb={3}
					>
						<pre>
							{allAuditions && (
								<ActivityTable
									auditions={allAuditions}
									list={true}
								/>
							)}
						</pre>
					</Grid>
				</Grid>
			</Box>
		</React.Fragment>
	);
}

const styles = {
	compound: {
		marginTop: "36px",
		marginBottom: "10vh",
	},
	title: {
		marginBottom: "24px",
	},
	centerText: {
		textAlign: "center",
	},
};
