import Appbar from "@components/Appbar";
import SessionProvider from "@context/session/SessionProvider";
import ListComponent from "./list.component";

export default function ListModule() {
	return (
		<SessionProvider>
			<Appbar />
			<ListComponent />
		</SessionProvider>
	)
}