import { fileManagerApi } from "@api/services/filemanager";
import useAuth from "@context/auth/AuthHook";
import { Add, InfoRounded } from "@mui/icons-material";
import {
	Box,
	Button,
	Chip,
	Container,
	Divider,
	FormControl,
	Grid,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ActivityService } from "./activity.service";
import CreateCheckItemDialog from "./dialogs/checkitem.create.component";

/**
 * const checking_items = ["Item 1","Item 2","Item 3","Item 5"]
 */

export default function ActivityComponent() {
	const { state } = useAuth();
	const params = useParams();
	const history = useNavigate();
	const [activityDefinition, setActivityDefinition] = useState<any>({
		title: null,
		description: null,
	});

	const [open, setOpen] = useState<boolean>(false);

	useEffect(() => {
		if (params.id) {
			new ActivityService(fileManagerApi)
				.findOne(params.id)
				.then((res) => {
					setActivityDefinition(res.data);
				})
				.catch((err) => {
					toast.error(
						"Falha ao carregar atividade! Tente novamente mais tarde"
					);
				});
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (params.enabled) {
			setOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{/*<Appbar />*/}
			<Container>
				<Grid container spacing={3} sx={{ marginBottom: "20vh" }}>
					<Grid item md={12}>
						<Box mt={3}>
							<Typography
								variant="h5"
								component="div"
								sx={{ textAlign: "center" }}
							>
								Cadastro de Atividade
							</Typography>
						</Box>
						{/* <pre>
							{JSON.stringify(activityDefinition, null, 4)}
						</pre> */}
					</Grid>

					<Grid item md={12}>
						<Box mb={2}>
							<Typography variant="h5" component="div">
								Título da proposta de atividade*
							</Typography>
						</Box>
						<Divider />

						<Box
							mt={2}
							mb={2}
							sx={{
								padding: "12px",
								border: "solid #ccc 1px",
								minHeight: "20vh",
								position: "relative",
								":focus-within": {
									border: "solid blue",
								},
							}}
						>
							<Typography variant="caption">
								{/* Forneça uma descrição detalhada da atividade que deseja cadastrar. */}
								Insira um título breve e descritivo para a
								atividade que deseja cadastrar.
							</Typography>
							<div>
								<input
									required
									type="text"
									name="atividade_titulo"
									// defaultValue="UNIDADE CURRICULAR DE EXTENSÃO - DIREITOS HUMANOS"
									value={
										activityDefinition.title &&
										activityDefinition.title
											? activityDefinition.title
											: ""
									}
									placeholder={
										"UNIDADE CURRICULAR DE EXTENSÃO - DIREITOS HUMANOS"
									}
									onChange={(e: any) => {
										setActivityDefinition(() => {
											return {
												...activityDefinition,
												title: e.target.value,
											};
										});
									}}
									style={{
										marginTop: "24px",
										width: "100%",
										padding: "12px 24px",
										border: "none",
										outline: "none",
									}}
								></input>
							</div>
							<Box
								sx={{
									position: "absolute",
									bottom: "12px",
									right: "12px",
								}}
							>
								<Typography
									variant="caption"
									sx={{
										color:
											activityDefinition &&
											activityDefinition.title &&
											activityDefinition.title.length <=
												200
												? "green"
												: "red",
									}}
								>
									{activityDefinition &&
									activityDefinition.title
										? activityDefinition.title.length
										: 0}
									/200
								</Typography>
							</Box>
						</Box>
					</Grid>

					<Grid item md={12}>
						<Typography variant="h5" component="div">
							Descrição*
						</Typography>
						<Divider />

						<Box
							mt={2}
							mb={2}
							sx={{
								padding: "12px",
								border: "solid #ccc 1px",
								minHeight: "30vh",
								position: "relative",
								":focus-within": {
									border: "solid blue",
								},
							}}
						>
							<Typography variant="caption">
								{/* Insira um título breve e descritivo para a presentação, disciplina ou atividade que deseja cadastrar. */}
								Forneça uma descrição detalhada da atividade que
								deseja cadastrar.
							</Typography>
							<div>
								<FormControl fullWidth>
									<TextField
										required
										multiline
										rows={3}
										name="atividade_descricao"
										// defaultValue="Na apresentação, aborde como se desenvolveu a experiência da ação de extensão, pontos positivos e negativos (utilizar o banner como roteiro de apresentação)."
										value={
											activityDefinition.description &&
											activityDefinition.description
												? activityDefinition.description
												: ""
										}
										placeholder={
											"Na apresentação, aborde como se desenvolveu a experiência da ação de extensão, pontos positivos e negativos (utilizar o banner como roteiro de apresentação)."
										}
										onChange={(e: any) => {
											setActivityDefinition(() => {
												return {
													...activityDefinition,
													description: e.target.value,
												};
											});
										}}
										style={{
											marginTop: "24px",
											width: "100%",
											padding: "12px 24px",
											border: "none",
											outline: "none",
										}}
										sx={{
											"& .MuiOutlinedInput-root": {
												"& > fieldset": {
													border: "none",
												},
											},
										}}
									/>
								</FormControl>
							</div>
							<Box
								sx={{
									position: "absolute",
									bottom: "12px",
									right: "12px",
								}}
							>
								<Typography
									variant="caption"
									sx={{
										color:
											activityDefinition &&
											activityDefinition.description &&
											activityDefinition.description
												.length <= 256
												? "green"
												: "red",
									}}
								>
									{activityDefinition &&
									activityDefinition.description
										? activityDefinition.description.length
										: 0}
									/256
								</Typography>
							</Box>
						</Box>
					</Grid>

					<Grid item md={12}>
						<Box mb={2}>
							<Typography variant="h5" component="div">
								{/* Persona */}
								Persona* (Descreva quais as caracteristicas para
								orientação do avaliardor IA)
							</Typography>
						</Box>
						<Divider />
						<Box
							mt={2}
							mb={2}
							sx={{
								padding: "12px",
								border: "solid #ccc 1px",
								minHeight: "20vh",
								position: "relative",
								":focus-within": {
									border: "solid blue",
								},
							}}
						>
							<Typography variant="caption">
								Você é um:
							</Typography>
							<div>
								<input
									required
									type="text"
									name="atividade_titulo"
									// defaultValue="UNIDADE CURRICULAR DE EXTENSÃO - DIREITOS HUMANOS"
									value={
										activityDefinition.persona &&
										activityDefinition.persona
									}
									placeholder={"Um avaliador bastante empático com os estudantes".toUpperCase()}
									onChange={(e: any) => {
										setActivityDefinition(() => {
											return {
												...activityDefinition,
												persona: e.target.value,
											};
										});
									}}
									style={{
										marginTop: "24px",
										width: "100%",
										padding: "12px 24px",
										border: "none",
										outline: "none",
									}}
								></input>
							</div>
							<Box
								sx={{
									position: "absolute",
									bottom: "12px",
									right: "12px",
								}}
							>
								<Typography
									variant="caption"
									sx={{
										color:
											activityDefinition &&
											activityDefinition.persona &&
											activityDefinition.persona.length <=
												200
												? "green"
												: "red",
									}}
								>
									{activityDefinition &&
									activityDefinition.persona
										? activityDefinition.persona.length
										: 0}
									/200
								</Typography>
							</Box>
						</Box>
					</Grid>

					<Grid item md={12}>
						<Box mb={2}>
							<Typography variant="h5" component="div">
								{/* Tipo de trabalho */}
								Tipo de trabalho* (Descreva qual o tipo de
								trabalho será desenvolvido pelos estudantes)
							</Typography>
						</Box>
						<Divider />
						<Box
							mt={2}
							mb={2}
							sx={{
								padding: "12px",
								border: "solid #ccc 1px",
								minHeight: "20vh",
								position: "relative",
								":focus-within": {
									border: "solid blue",
								},
							}}
						>
							<Typography variant="caption">De um:</Typography>
							<div>
								<input
									required
									type="text"
									name="atividade_titulo"
									// defaultValue="UNIDADE CURRICULAR DE EXTENSÃO - DIREITOS HUMANOS"
									value={
										activityDefinition.type &&
										activityDefinition.type
									}
									placeholder={"E Está revisando a etapa de Apresentação oral".toUpperCase()}
									onChange={(e: any) => {
										setActivityDefinition(() => {
											return {
												...activityDefinition,
												type: e.target.value,
											};
										});
									}}
									onBlur={() => {
										toast("Adicionando atividade...");
										new ActivityService(fileManagerApi)
											.create(
												activityDefinition,
												state.usuario.id
											)
											.then((res: AxiosResponse<any>) => {
												toast.success(
													"Atividade criada com sucesso!"
												);
												return res;
											})
											.then((res: any) => {
												history(
													"/activity/" + res.data.id
												);
												// setActivityDefinition(res.data)
												console.log(res.data);
											})
											.catch(() => {
												toast.error(
													"Não foi possível completar a requisição, tente novamente mais tarde"
												);
											});
									}}
									style={{
										marginTop: "24px",
										width: "100%",
										padding: "12px 24px",
										border: "none",
										outline: "none",
									}}
								></input>
							</div>
							<Box
								sx={{
									position: "absolute",
									bottom: "12px",
									right: "12px",
								}}
							>
								<Typography
									variant="caption"
									sx={{
										color:
											activityDefinition &&
											activityDefinition.type &&
											activityDefinition.type.length <=
												200
												? "green"
												: "red",
									}}
								>
									{activityDefinition &&
									activityDefinition.type
										? activityDefinition.type.length
										: 0}
									/200
								</Typography>
							</Box>
						</Box>
					</Grid>

					<Grid item md={12}>
						<Box mb={2}>
							<Typography variant="h5" component="div">
								{/* Reforço semântico */}
								Reforço semântico (Qual comportamento, do ponto
								de vista semântico, a IA deve adotar)
							</Typography>
						</Box>
						<Divider />
						<Box
							mt={2}
							mb={2}
							sx={{
								padding: "12px",
								border: "solid #ccc 1px",
								minHeight: "20vh",
								position: "relative",
								":focus-within": {
									border: "solid blue",
								},
							}}
						>
							<Typography variant="caption">
								O que você gostaria de descrever para melhorar o
								entendimento da IA:
							</Typography>
							<div>
								<input
									type="text"
									name="atividade_titulo"
									// defaultValue="UNIDADE CURRICULAR DE EXTENSÃO - DIREITOS HUMANOS"
									value={
										activityDefinition.reinforcement &&
										activityDefinition.reinforcement
									}
									placeholder={"Considere a minima experiência do estudante para avaliar positivamente todos os itens".toUpperCase()}
									onChange={(e: any) => {
										setActivityDefinition(() => {
											return {
												...activityDefinition,
												reinforcement: e.target.value,
											};
										});
									}}
									onBlur={() => {
										new ActivityService(fileManagerApi)
											.update({
												id: params.id,
												...activityDefinition,
											})
											.then((res: AxiosResponse<any>) => {
												toast.success(
													"Atividade atualizada com sucesso!"
												);
												return res;
											})
											.catch(() => {
												toast.error(
													"Não foi possível completar a requisição, tente novamente mais tarde"
												);
											});
									}}
									style={{
										marginTop: "24px",
										width: "100%",
										padding: "12px 24px",
										border: "none",
										outline: "none",
									}}
								></input>
							</div>
							<Box
								sx={{
									position: "absolute",
									bottom: "12px",
									right: "12px",
								}}
							>
								<Typography
									variant="caption"
									sx={{
										color:
											activityDefinition &&
											activityDefinition.reinforcement &&
											activityDefinition.reinforcement
												.length <= 200
												? "green"
												: "red",
									}}
								>
									{activityDefinition &&
									activityDefinition.reinforcement
										? activityDefinition.reinforcement
												.length
										: 0}
									/200
								</Typography>
							</Box>
						</Box>
					</Grid>

					<Grid item md={12}>
						<Typography variant="h5" component="div">
							Itens de checagem
						</Typography>
						<Divider />

						<Box
							mt={2}
							mb={2}
							sx={{
								padding: "12px",
								border: "solid #ccc 1px",
								minHeight: "30vh",
								position: "relative",
								":focus-within": {
									border: "solid blue",
								},
							}}
						>
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<Typography variant="caption">
									Lista de checagem
								</Typography>
								<Button
									variant="text"
									startIcon={<Add />}
									onClick={() => {
										setOpen(true);
										history(
											"/activity/" + params.id + "/item/1"
										);
									}}
								>
									Criar novo item de checagem
								</Button>
							</Box>
							<Box
								sx={{
									paddingLeft: "24px",
									marginTop: "24px",
									display: "flex",
									flexWrap: "wrap",
									gap: "12px",
								}}
							>
								{activityDefinition &&
								activityDefinition.item &&
								Array.isArray(activityDefinition.item) &&
								activityDefinition.item.length === 0
									? "Nenhum item de checagem definido para a atividade"
									: ""}
								{activityDefinition &&
									activityDefinition.item &&
									Array.isArray(activityDefinition.item) &&
									activityDefinition.item.map((item: any) => {
										return (
											<Chip
												color="secondary"
												key={item.title}
												label={item.title}
												icon={
													<Tooltip
														title={item.description}
														arrow
													>
														<InfoRounded />
													</Tooltip>
												}
												onDelete={async () => {
													await new ActivityService(
														fileManagerApi
													)
														.deleteCheckItem(
															item.id
														)
														.then(async (res) => {
															new ActivityService(
																fileManagerApi
															)
																.findOne(
																	Number(
																		params.id
																	)
																)
																.then((res) => {
																	setActivityDefinition(
																		res.data
																	);
																	// toast.success("Sucesso ao carregar os dados da atividade " + params.id)
																})
																.catch(() => {
																	toast.error(
																		"Não foi possível encontrar a atividade de id " +
																			params.id +
																			"!"
																	);
																	history(
																		"/activities"
																	);
																});
														})
														.catch(() => {
															toast.error(
																"Falha ao remover item"
															);
														});
												}}
											/>
										);
									})}
							</Box>
						</Box>
					</Grid>

					<Grid
						item
						md={12}
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							gap: "12px",
						}}
					>
						<Button onClick={() => history("/")}>Cancelar</Button>
						<Button
							variant="contained"
							disableElevation
							onClick={() => {
								toast("Adicionando atividade...");
								history("/");
								new ActivityService(fileManagerApi)
									.update({
										id: params.id,
										...activityDefinition,
									})
									.then((res: AxiosResponse<any>) => {
										toast.success(
											"Atividade criada com sucesso!"
										);
										return res;
									})
									.catch(() => {
										toast.error(
											"Não foi possível completar a requisição, tente novamente mais tarde"
										);
									})
									.finally(() => {
										// history("/activity/" + activityDefinition.id);
										// setActivityDefinition(res.data)
										// history("/");
									});
							}}
						>
							Finalizar
						</Button>
					</Grid>
				</Grid>
			</Container>

			<CreateCheckItemDialog
				open={open}
				setOpen={setOpen}
				activity_id={params.id}
				setData={setActivityDefinition}
				parent="PAGE"
			/>
		</>
	);
}
