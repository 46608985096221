import SessionProvider from "@context/session/SessionProvider";
import AuditionV2UI from "./AuditionV2";

export default function AuditionV2({ audition }: any) {
	return (
		<>
			<SessionProvider>
				<AuditionV2UI audition={audition} />
			</SessionProvider>
		</>
	)
}