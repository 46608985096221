import useAuth from "@context/auth/AuthHook";
import { ProfileProvider } from "@context/profile/profile.provider";
import { Private } from "@router/Private";
import { Public } from "@router/Public";
import { RouterProvider } from "react-router-dom";

/**
 * @description Router do projeto, alterna entre rotas públicas e privadas a partir do objeto user
 * @returns
 */
export default function Router() {
	const { state } = useAuth();

	// return (
	// 	<React.Fragment>
	// 		<RouterProvider router={user && user.signed ? Private : Public} />
	// 	</React.Fragment>
	// )

	if (state && state.accessToken) {
		return (
			<ProfileProvider>
				<RouterProvider router={Private} />
			</ProfileProvider>
		);
	} else {
		return <RouterProvider router={Public} />;
	}
}
