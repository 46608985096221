import Appbar from "@components/Appbar";
import SessionProvider from "@context/session/SessionProvider";
import BiometryTemplate from "./biometry.template";

export default function BiometryModule() {
	return (
		<>
			<Appbar />
			<SessionProvider>
				{/* <BiometryComponent /> */}
				<BiometryTemplate />
			</SessionProvider>
		</>
	);
}
