import { fileManagerApi } from "@api/services/filemanager";
import NoContent from "@components/NoContent";
import { useActivity } from "@modules/activity/activity.hook";
import { ActivityService } from "@modules/activity/activity.service";
import CreateCheckItemDialog from "@modules/activity/dialogs";
import {
	Add,
	Close,
	Delete,
	Edit,
	InfoRounded,
	Preview,
	TextFields,
	Visibility,
} from "@mui/icons-material";
import {
	Avatar,
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { indigo } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TasksDialogComponent from "./tasks.dialog.component";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#fff",
		color: "black",
		fontWeight: "semibold",
		fontSize: "14px",
	},
}));

export default function TasksTableComponent(props: any) {
	const theme = useTheme();
	const history = useNavigate();
	const params = useParams();
	const { state, setState } = useActivity();
	const [open, setOpen] = useState<boolean>(false);
	const [openActivityRegister, setOpenActivityRegister] =
		useState<boolean>(false);
	const [openActivityDelete, setOpenActivityDelete] =
		useState<boolean>(false);
	const [openCheckItemDialog, setOpenCheckItemDialog] =
		useState<boolean>(false);
	const [data, setData] = useState<any>({
		title: "",
		description: "",
	});
	const [activityItems, setActivityItems] = useState<any[]>([]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [activityData, setActivityData] = useState<any>(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [ASID, setASID] = useState<any>(null);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [openActivitySubmissions, setOpenActivitySubmissions] =
		useState<boolean>(false);

	const [enableDeleteAction, setEnableDeleteAction] =
		useState<boolean>(false);

	useEffect(() => {
		// alert(JSON.stringify(params, null, 4))
		if (params.id && !params.enabled) {
			new ActivityService(fileManagerApi)
				.findOne(Number(params.id))
				.then((res) => {
					setData(res.data);
					// toast.success("Sucesso ao carregar os dados da atividade " + params.id)
					setOpenActivityRegister(true);
				})
				.catch(() => {
					toast.error(
						"Não foi possível encontrar a atividade de id " +
							params.id +
							"!"
					);
					history("/activities");
				});
		}

		if (params.id && !params.enabled && params.delete) {
			setOpenActivityRegister(false);
			setOpenCheckItemDialog(false);
			setOpenActivityDelete(true);
		}

		if (params.id && params.enabled) {
			setOpenActivityRegister(true);
			setOpenCheckItemDialog(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	function truncateString(str: string) {
		if (str.length <= 6) {
			return str;
		} else {
			return str.substring(0, 10) + "...";
		}
	}

	return (
		<React.Fragment>
			<TableContainer
				component={Box}
				style={{
					overflow: "hidden",
					overflowX: "scroll",
				}}
			>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Cód.</StyledTableCell>
							<StyledTableCell>Título</StyledTableCell>
							<StyledTableCell align="center">
								Descrição
							</StyledTableCell>
							<StyledTableCell align="center">
								Qt. Submissões
							</StyledTableCell>
							<StyledTableCell align="center">
								Submissões
							</StyledTableCell>
							<StyledTableCell align="center">
								Autor
							</StyledTableCell>
							<StyledTableCell align="center">
								Ações
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props &&
						props.activities &&
						props.activities.length === 0 ? (
							<TableRow sx={{ textAlign: "center" }}>
								<TableCell colSpan={6}>
									<NoContent />
								</TableCell>
							</TableRow>
						) : (
							props.activities.map((row: any, index: number) => (
								<TableRow
									key={row.id}
									sx={{
										"&:last-child td, &:last-child th": {
											border: 0,
										},
									}}
								>
									<TableCell component="th" scope="row">
										<Typography variant="caption">
											{" "}
											{row && row.id
												? row.id
												: null ??
												  "Atividade de Sistema".toUpperCase()}{" "}
										</Typography>
									</TableCell>
									<TableCell component="th" scope="row">
										<Typography variant="caption">
											{" "}
											{row && row.title
												? row.title.toUpperCase()
												: null ??
												  "Atividade de Sistema".toUpperCase()}{" "}
										</Typography>
									</TableCell>
									<TableCell
										component="th"
										scope="row"
										align="center"
										colSpan={1}
										sx={{ maxWidth: "64ch" }}
									>
										<Typography variant="body2">
											{" "}
											{(row && row.description) ??
												"Atividade cadastrada pelo sistema"}{" "}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Box
											sx={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<Avatar
												sx={{
													bgcolor: indigo["A700"],
													width: "32px",
													height: "32px",
												}}
											>
												<Typography variant="caption">
													{row &&
														row._count &&
														row._count.audition}
												</Typography>
											</Avatar>
										</Box>
									</TableCell>

									<TableCell align="center">
										<Button
											variant="outlined"
											startIcon={<Preview />}
											onClick={() => {
												history("/list/" + row.id);
												setOpenActivitySubmissions(
													true
												);
												// setASID(row.title)
												// getAuditionsFromActivity(row.id)
											}}
										>
											Submissões
										</Button>
									</TableCell>
									<TableCell align="left">
										<span
											style={{
												minWidth: "96px",
												padding: "6px 8px",
												borderRadius: "4px",
												backgroundColor:
													theme.palette.secondary
														.light,
												color: "white",
											}}
										>
											{row.user_id
												? row.user_id
												: "Sistema"}
										</span>
									</TableCell>

									<TableCell
										align="center"
										sx={{
											minWidth: "200px",
											display: "flex",
											gap: "6px",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<IconButton
											sx={{
												color: "blue",
												border: "solid thin #ddd",
											}}
											onClick={() => {
												setData(row);
												setOpen(true);
											}}
										>
											<Visibility />
										</IconButton>
										<IconButton
											sx={{
												color: "orange",
												border: "solid thin #ddd",
											}}
											onClick={() => {
												history(
													`/activity/${row.id}/edit`
												);
												setOpenActivityRegister(true);
												setData(row);
											}}
										>
											<Edit />
										</IconButton>
										<IconButton
											disabled
											sx={{
												visibility: "hidden",
												color: "red",
												border: "solid thin #ddd",
											}}
											onClick={() => {
												history(
													`/activity/${row.id}/delete/1`
												);
												setOpenActivityDelete(true);
											}}
										>
											<Delete />
										</IconButton>
									</TableCell>
									<TasksDialogComponent
										open={open}
										setOpen={setOpen}
										task={data}
									/>
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</TableContainer>

			<Dialog
				open={openActivityRegister || openActivityDelete}
				maxWidth={"lg"}
				fullWidth
				onClose={() => {
					setOpenActivityRegister(false);
					setOpenActivityDelete(false);
					history("/");
				}}
			>
				<Box
					style={{
						backgroundColor: theme.palette.primary.main,
						color: "white",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						gap: "12px",
					}}
				>
					<Box p={1} sx={{ display: "flex", gap: "12px 8px" }}>
						<TextFields />
						<Typography>
							{openActivityRegister
								? "Editar"
								: openActivityDelete
								? "Deletar"
								: ""}{" "}
							atividade: {data && data.title}
						</Typography>
					</Box>
					<Box>
						<IconButton
							sx={{ color: "white", padding: "12px" }}
							onClick={() => {
								setOpenActivityRegister(false);
								setOpenActivityDelete(false);
								history("/");
							}}
						>
							<Close />
						</IconButton>
					</Box>
				</Box>
				<DialogTitle
					sx={{
						display: "none",
						alignItems: "center",
						justifyContent: "space-between",
						backgroundColor: theme.palette.primary.light,
						color: "white",
					}}
				>
					<Typography>{data && data.title}</Typography>
				</DialogTitle>
				<DialogContent>
					<Grid container>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Box mt={3} mb={3}>
								<Typography variant={"h5"}>
									Atividade: {data.title}
								</Typography>
								<Typography variant={"body1"}>
									Exibindo os dados do componente
								</Typography>
							</Box>
							<Box
								sx={{
									border: "solid thin #ccc",
									borderRadius: "4px",
									minHeight: "20vh",
								}}
							>
								{openActivityDelete ? (
									<>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												justifyContent: "center",
												marginTop: "48px",
											}}
										>
											<Typography variant={"h5"}>
												Remover atividade
											</Typography>
											<Typography>
												Para confirmar a exclusão,
												digite <strong>excluir</strong>{" "}
												no campo de entrada de texto.
											</Typography>
										</Box>
										<Box mt={3} mb={3} p={2}>
											<FormControl fullWidth>
												<TextField
													label=""
													placeholder="Excluir"
													onChange={(e) => {
														e.target.value.toLowerCase() ===
														"excluir"
															? setEnableDeleteAction(
																	true
															  )
															: setEnableDeleteAction(
																	false
															  );
													}}
												></TextField>
											</FormControl>
										</Box>
									</>
								) : (
									<></>
								)}
								{openActivityRegister && !openActivityDelete ? (
									<>
										<Box p={2}>
											<Typography
												variant={"h5"}
												sx={{ marginBottom: "24px" }}
											>
												Editar atividade
											</Typography>
											{/* {JSON.stringify(data, null, 4)} */}
											<FormControl fullWidth>
												<TextField
													label={
														"Título da atividade"
													}
													placeholder={
														"Título da atividade"
													}
													value={data.title ?? ""}
													onChange={(e) => {
														setData({
															...data,
															title: e.target
																.value,
														});
													}}
												></TextField>
											</FormControl>
											<FormControl
												fullWidth
												sx={{ marginTop: "24px" }}
											>
												<TextField
													label={
														"Descrição da atividade"
													}
													placeholder={
														"Descrição da atividade"
													}
													value={
														data.description ?? ""
													}
													multiline
													rows={4}
													onChange={(e) => {
														setData({
															...data,
															description:
																e.target.value,
														});
													}}
												></TextField>
											</FormControl>
											<FormControl
												fullWidth
												sx={{ mt: 3 }}
											>
												<TextField
													label={"Persona"}
													placeholder={"Persona"}
													value={data.persona ?? ""}
													onChange={(e) => {
														setData({
															...data,
															persona:
																e.target.value,
														});
													}}
												></TextField>
											</FormControl>
											<FormControl
												fullWidth
												sx={{ mt: 3 }}
											>
												<TextField
													label={"Tipo de trabalho"}
													placeholder={
														"Tipo de trabalho"
													}
													value={data.type ?? ""}
													onChange={(e) => {
														setData({
															...data,
															type: e.target
																.value,
														});
													}}
												></TextField>
											</FormControl>
											<FormControl
												fullWidth
												sx={{ mt: 3 }}
											>
												<TextField
													label={"Reforço semântico"}
													placeholder={
														"Reforço semântico"
													}
													value={
														data.reinforcement ?? ""
													}
													onChange={(e) => {
														setData({
															...data,
															reinforcement:
																e.target.value,
														});
													}}
												></TextField>
											</FormControl>
										</Box>
									</>
								) : (
									<></>
								)}
								{openActivityRegister && !openActivityDelete ? (
									<Box p={2}>
										<Typography
											variant="h5"
											component="div"
										>
											Itens de checagem
										</Typography>
										<Divider />

										<Box
											mt={2}
											mb={2}
											sx={{
												padding: "12px",
												border: "solid #ccc 1px",
												borderRadius: "4px",
												minHeight: "30vh",
												position: "relative",
												":focus-within": {
													border: "solid blue",
												},
											}}
										>
											<Box
												sx={{
													display: "flex",
													justifyContent:
														"space-between",
													alignItems: "center",
												}}
											>
												<Typography variant="caption">
													Lista de checagem
												</Typography>
												<Button
													variant="text"
													startIcon={<Add />}
													onClick={() => {
														history(
															`/activity/${params.id}/items/1`
														);
														setOpenCheckItemDialog(
															true
														);
													}}
												>
													Criar novo item de checagem
												</Button>
											</Box>
											<Box
												sx={{
													paddingLeft: "24px",
													marginTop: "24px",
													display: "flex",
													flexWrap: "wrap",
													gap: "12px",
												}}
											>
												{/*{JSON.stringify(data.item, null, 4)}*/}
												{data &&
												data.item &&
												Array.isArray(data.item) &&
												data.item.length === 0
													? "Nenhum item de checagem definido para a atividade"
													: ""}
												{data &&
													data.item &&
													Array.isArray(data.item) &&
													data.item.map(
														(item: any) => {
															return (
																<Chip
																	color="secondary"
																	key={
																		item.id
																	}
																	label={
																		item.title
																	}
																	icon={
																		<Tooltip
																			title={
																				item.description
																			}
																			arrow
																		>
																			<InfoRounded />
																		</Tooltip>
																	}
																	onDelete={async () => {
																		await new ActivityService(
																			fileManagerApi
																		)
																			.deleteCheckItem(
																				item.id
																			)
																			.then(
																				async (
																					res
																				) => {
																					new ActivityService(
																						fileManagerApi
																					)
																						.findOne(
																							Number(
																								params.id
																							)
																						)
																						.then(
																							(
																								res
																							) => {
																								setData(
																									res.data
																								);
																								// toast.success("Sucesso ao carregar os dados da atividade " + params.id)
																								setOpenActivityRegister(
																									true
																								);
																							}
																						)
																						.catch(
																							() => {
																								toast.error(
																									"Não foi possível encontrar a atividade de id " +
																										params.id +
																										"!"
																								);
																								history(
																									"/activities"
																								);
																							}
																						);
																				}
																			)
																			.catch(
																				() => {
																					toast.error(
																						"Falha ao remover item"
																					);
																				}
																			);
																	}}
																/>
															);
														}
													)}
											</Box>
											<Box
												sx={{
													position: "absolute",
													bottom: "12px",
													left: "12px",
													marginTop: "72px",
													minWidth: "50%",
													display: "none",
												}}
											>
												<FormControl fullWidth>
													<InputLabel>
														Adicionar mais itens de
														checagem
													</InputLabel>
													<Select
														label="Item de checagem"
														defaultValue="1"
													>
														<MenuItem value={1}>
															Nenhum
														</MenuItem>
														{state &&
															state.items &&
															state.items.map(
																(
																	item: any,
																	index: number
																) => {
																	return (
																		<MenuItem
																			key={
																				item.id
																			}
																			defaultValue={
																				1
																			}
																			onClick={() => {
																				var checking_item =
																					activityItems.find(
																						(
																							el: any
																						) =>
																							el.title ===
																							item.title
																					);
																				if (
																					!checking_item
																				) {
																					setActivityItems(
																						() => {
																							return [
																								...activityItems,
																								item,
																							];
																						}
																					);
																				}
																			}}
																		>
																			{
																				item.title
																			}
																		</MenuItem>
																	);
																}
															)}
													</Select>
												</FormControl>
											</Box>
										</Box>
									</Box>
								) : (
									<></>
								)}
							</Box>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Box
						sx={{
							display: openActivityDelete ? "none" : "",
						}}
					>
						<Button
							variant={"text"}
							onClick={() => {
								setOpenActivityRegister(false);
								setOpenActivityDelete(false);
								history("/");
							}}
						>
							Cancelar
						</Button>
						<Button
							variant={"contained"}
							disableElevation
							onClick={() => {
								new ActivityService(fileManagerApi)
									.update(data)
									.then(() => {
										toast.success(
											"Atividade atualizada com sucesso!"
										);
									})
									.then(async () => {
										await new ActivityService(
											fileManagerApi
										)
											.findAll()
											.then((res) => {
												setState({
													...state,
													activities: res.data,
												});
											})
											.catch(() => {
												history("/");
											});
									})
									.then(() => {
										setOpenActivityRegister(false);
										setOpenActivityDelete(false);
										history("/");
									})
									.catch((err) => {
										toast.error(
											"Falha ao recarregar atividades! Por favor, recarregue a página"
										);
									});
							}}
						>
							Salvar
						</Button>
					</Box>
					<Box
						sx={{
							display: openActivityDelete ? "" : "none",
						}}
					>
						<Button
							variant={"text"}
							onClick={() => {
								toast.warning(
									"Ação de exclusão não finalizada!"
								);
								setOpenActivityDelete(false);
								setOpenActivityRegister(false);
								history("/");
							}}
						>
							Cancelar
						</Button>
						<Button
							disableElevation
							disabled={!enableDeleteAction}
							variant={"contained"}
							onClick={() => {
								if (params.id)
									new ActivityService(fileManagerApi)
										.delete(params.id)
										.then((res) => {
											toast.success(
												"Atividade removida com sucesso!"
											);
										})
										.then(() => {
											new ActivityService(fileManagerApi)
												.findAll()
												.then((res) => {
													setState({
														...state,
														activities: res.data,
													});
												})
												.then(() => {
													history("/");
												})
												.catch((err) => {
													toast.error(
														"Falha ao recarregar atividades! Por favor, recarregue a página"
													);
												});
										})
										.catch((err) => {
											toast.error(
												"Falha ao remover atividade! Tente novamente mais tarde."
											);
										});
							}}
						>
							Excluir
						</Button>
					</Box>
				</DialogActions>
				<CreateCheckItemDialog
					open={openCheckItemDialog}
					setOpen={setOpenCheckItemDialog}
					activity_id={params.id}
					setData={setData}
				/>
			</Dialog>
		</React.Fragment>
	);
}
