import { Backdrop, CircularProgress } from "@mui/material";

export default function Cover(props: any) {
    return(
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
            open={props.open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}