import axios, { AxiosInstance } from "axios";

export default class AuditionService {
	
	constructor(private api: AxiosInstance){
		this.api = axios.create({
			baseURL: process.env.REACT_APP_FILE_MANAGER_API
		})

		this.api.interceptors.request.use((config) => {
			let user: any = localStorage.getItem("@application");
			let token: any = localStorage.getItem("token");
		
			if (token && config.headers) {
				user = JSON.parse(user).usuario;
				token = JSON.parse(token);
		
				config.headers["Authorization"] = `Bearer ${token}`;
				config.headers["ra"] = user?.id;
			}
		
			return config;
		});
	}

	async create(data: any) {		
		return await this.api.post("/auditions", data)
	}

	async update(id: number|string, data: any) {
		return await this.api.put("/auditions/" + id, data)
	}

	async checkFileExists(key: any) {
		return await this.api.get("/s3/check-file",{
			params: {
				key: key
			}
		})
	}
}