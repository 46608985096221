/* eslint-disable react-hooks/exhaustive-deps */
import {
	CameraAlt,
	CenterFocusStrong,
	Close,
	Done
} from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";

import { fileManagerApi } from "@api/services/filemanager";
import { uploadFilesViaLambda } from "@api/services/lambda";
import { Transition } from "@components/Dialogs/DialogShell";
import ProgressIndicator from "@components/ProgressIndicator";
import useAuth from "@context/auth/AuthHook";
import useSession from "@context/session/SessionHook";
import SessionProvider from "@context/session/SessionProvider";
import {
	Alert,
	AlertTitle,
	AppBar,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	Grid,
	IconButton,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import { Container } from "@mui/system";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { poses, refImages } from "./constants";
import { convertBase64ToBlob } from "./helper";

export default function BiometricsUI() {

	const { user } = useAuth();
	const { setActiveStep } = useSession();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('sm'));

	const videoRef = useRef<any>(null);
	const canvasRef = useRef<any>(null);
	// const replaceRef = useRef<any>(null);

	const [pendingImages, setPendingImages] = useState<any | any[]>([]);
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [cameraStatus, setCameraStatus] = useState<boolean>(false);
	const [currentRefImage, setCurrentRefImage] = useState<any>(0);
	const [showBackdrop, setShowBackdrop] = React.useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [replaceableImage, setReplaceableImage] = React.useState<any>(null);

	const getUserVideo = () => {
		const player = videoRef.current;
		setCameraStatus(true);

		const handleSuccess = (stream: MediaStream) => {
			player.srcObject = stream
		}

		navigator.mediaDevices
			.getUserMedia({
				audio: false,
				video: {
					width: window.innerWidth < 800 ? (480 / 2) : (854 / 2),
					height: window.innerWidth < 800 ? (854 / 2) : (480 / 2)
				}
			})
			.then(handleSuccess)
			.then(() => videoRef.current.play())
	}

	const handleCompleteRegister = async () => {
		setCurrentRefImage(5);
		setActiveStep(1);

		await fileManagerApi.get(`/file-retrieve?ra=${user.ra}&type=image`)
			.then((response: AxiosResponse<any>) => {
				//TODO: Corrigir este método pois o then deveria estar fazendo algos
			})
			.finally(() => {
				window.location.href = "/recorder"
			})
	}

	const handleUpdateTargetLabel = async () => {
		if (pendingImages.length === 0) {
			toast("Todas as imagens já foram obtidas, clique para prosseguir")
		} else {

			if (canvasRef.current) {
				setShowBackdrop(true);
				var el: HTMLCanvasElement | any = document.createElement('canvas')

				el.width = videoRef.current.videoWidth
				el.height = videoRef.current.videoHeight

				console.log(matches)
				console.log(videoRef.current.videoHeight)
				console.log(videoRef.current.videoWidth)
				el.getContext('2d').drawImage(videoRef.current, 0, 0, videoRef.current.videoWidth, videoRef.current.videoHeight)

				// Send image to server
				let formData = new FormData()
				formData.append('image', el.toDataURL("image/png"));
				formData.append('ra', user.ra);
				formData.append('label', pendingImages[currentRefImage].label);

				const file = await convertBase64ToBlob(el.toDataURL("image/png"))

				await uploadFilesViaLambda(user, file, "image/png", pendingImages[currentRefImage].label)
					.then(async () => {
						await fileManagerApi.post('/media-path',
							{
								ra: user.ra,
								type: 'image',
								label: pendingImages[currentRefImage].label,
								path: `${process.env.REACT_APP_FILE_LOCATION}/${user.ra}/${pendingImages[currentRefImage].label}`,
							})
							.then(async (response: AxiosResponse<any>) => {
								console.table(response.data)
								await updatePendingBiometrics()
							})
					})
					.then(async () => {
						await updatePendingBiometrics()
					})
					.catch((err) => {
						toast(err.response.message, {
							position: "top-right"
						})
					})
					.finally(() => {
						setShowBackdrop(false);
					})

			}

			setCurrentRefImage(currentRefImage + 1)
			toast("Imagem obtida: " + pendingImages[currentRefImage].label)
		}
	}

	const updatePendingBiometrics = async () => {
		var empty = null;
		fileManagerApi.get(`/biometrics?ra=${user.ra}&type=image`)
			.then((response: AxiosResponse<any>) => {
				const files = response.data
				if (files.length > 0) {
					refImages.forEach((refimage: any, sample: number) => {
						files.forEach((file: any, index: number) => {
							if (file.label === refimage.label) {
								refimage.image = file.path
								refimage.id = file.id
							}
						})
					})
					empty = refImages.filter((refimages: any) => refimages.image === null)
					// setImages(files)
				} else {
					empty = refImages;
				}

				setPendingImages(empty)
				setCurrentRefImage(0)
			})
			.catch((err) => {
				empty = refImages;
				setPendingImages(empty);
				setCurrentRefImage(0);
			})
			.finally(() => {
				console.table({
					status: "finished"
				})
			});
	}

	useEffect(() => {
		// fileManagerApi.get(`/file-retrieve?ra=${user.ra}&type=image`)
		setActiveStep(0);
		updatePendingBiometrics();
	}, []);

	return (
		<SessionProvider>
			<React.Fragment>
				<Container>
					<ProgressIndicator />
					<Box
						sx={{
							marginBottom: "12px",
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-betweeen",
							alignItems: "center",

							gap: "12px",
							minHeight: "75vh",
						}}>
						<Box mt={3}>
							<Box sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center"
							}}>
								<Typography
									variant="h6"
									component="div"
									sx={{
										display: "flex",
										gap: "12px",
										alignItems: "center",
										justifyContent: "center",
										textAlign: "center",
									}}>
									CADASTRO DE BIOMETRIA
								</Typography>
							</Box>
							<Box mt={3}>
								<Typography
									variant="body1"
									component="p"
									sx={{
										textAlign: "justify",
										marginTop: "12px",
									}}>
									Para garantirmos a integridade acadêmica da apresentação do seu trabalho, será necessário a validação da sua biometria facial. Inicie a captura de acordo com as indicações de posição do rosto diante da câmera do seu dispositivo.
								</Typography>
							</Box>
							<Box mt={3} mb={3}>
								<Typography
									variant="body1"
									component={"p"}
									style={{
										textAlign: "center"
									}}>
									Em um ambiente com boa iluminção faça as 5 capturas olhando para:
								</Typography>
							</Box>
							<Box
								mb={3}
								sx={{
									display: "flex",
									gap: "4px",
									alignItems: "center",
									justifyContent: "center",
									flexWrap: "wrap",
								}}>
								{
									poses.map((pose, index) => {
										return (
											<Box key={index}>
												<img src={pose.image} width="96" alt=""></img>
												<Typography variant="body2" component="p" sx={{ textAlign: "center" }}>{pose.label}</Typography>
											</Box>
										)
									})
								}
							</Box>
						</Box>
						<Box
							sx={{
								bottom: "12px",
								display: "flex",
								gap: "12px"
							}}>
							<Button
								variant="outlined"
								size="large"
								onClick={() => {
									window.location.href = "/"
								}}>
								Cancelar
							</Button>
							<Button
								variant="contained"
								disableElevation
								size="large"
								onClick={() => {
									setOpenDialog(true);
								}}>
								Iniciar captura
							</Button>
						</Box>
					</Box>
				</Container>
				{/* DIALOG */}
				<Dialog
					fullScreen={true}
					open={openDialog}
					TransitionComponent={Transition}
					onClose={() => {
						setOpenDialog(false);
					}}>
					{
						showBackdrop
						&& <Backdrop
							sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
							open={showBackdrop}
						>
							<CircularProgress color="inherit" />
						</Backdrop>
					}
					<AppBar>
						<Toolbar sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center"
						}}>
							<IconButton
								sx={{ color: "white" }}
								onClick={() => {
									setOpenDialog(false);
								}}>
								<Close />
							</IconButton>
							<Typography
								variant="h6"
								component="div"
								sx={{
									display: "flex",
									gap: "12px",
									alignItems: "center",
									justifyContent: "center",
									textAlign: "center",
								}}>
								Cadastro de biometria
							</Typography>
							<span></span>
						</Toolbar>
					</AppBar>
					<Container sx={{ marginTop: "56px" }}>
						<Grid container spacing={3}>
							<Grid item p={0} mt={3} md={12}>
								{
									refImages.length > 4
										? <Alert severity="info">
											<Typography variant="body2" component="p">
												Parabéns todas as suas capturas foram realizados com sucesso!.
											</Typography>
										</Alert>
										: <Alert variant="standard" severity="warning">
											<AlertTitle>Informe sobre a biometria</AlertTitle>

											<Typography
												variant="body2"
												component={"p"}
												style={{
													textAlign: "center"
												}}>
												Em um ambiente com boa iluminção faça as 5 capturas olhando para:
											</Typography>
										</Alert>
								}
							</Grid>

							{/* BIOMETRIA */}

							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Typography variant="h5" component="div"> Referências </Typography>
								<Box
									ref={canvasRef}
									style={{
										display: "flex",
										gap: "4px",
										flexWrap: matches ? "wrap" : "nowrap",
										marginBottom: "12px"
									}}>
									{
										refImages.map((item: any, index: number) => {
											return <Box
												onClick={() => {
													setReplaceableImage(item.image)
												}}
												key={index}
												style={{
													width: matches ? "126px" : "64px",
													height: matches ? "126px" : "64px",
													textAlign: "center",
													marginBottom: "20px"
												}}>
												<Box>
													<img
														src={
															item.image !== null ? item.image : poses[index].image
														}
														width="48"
														height="auto"
														alt="sample"
														style={{
															maxWidth: matches ? "126px" : "64px",
															maxHeight: matches ? "126px" : "64px",
															minWidth: matches ? "126px" : "64px",
															minHeight: matches ? "126px" : "64px",
															width: "auto",
															height: "auto",
															borderRadius: "12px",
															objectFit: "contain"
														}} />
													<Typography
														variant="caption"
														component="small"
														sx={{
															textAlign: "center",
															fontSize: ".7em",
														}}>
														{item.label}
													</Typography>
												</Box>
											</Box>
										})
									}
								</Box>
							</Grid>

							{/* CAMERA */}

							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Typography variant="h5" component="div" sx={{ marginBottom: "12px" }}> Câmera </Typography>
								<Box style={{
									backgroundColor: "black",
									marginBottom: "12px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}>
									<video
										ref={videoRef}
										style={{
											height: "60vh",
											width: "100%"
										}}>
									</video>
								</Box>

								<Box
									display={"flex"}
									gap={1}
									mb={2}>
									{
										pendingImages.length === 0
											? <Button
												variant="contained"
												size="medium"
												disableElevation
												sx={{ flexGrow: 1, backgroundColor: indigo[500] }}
												endIcon={<Done />}
												onClick={handleCompleteRegister}>
												Finalizar
											</Button>
											: cameraStatus
												? <Button
													variant="contained"
													endIcon={<CenterFocusStrong />}
													size="large"
													disableElevation
													sx={{ flexGrow: 1 }}
													onClick={handleUpdateTargetLabel}>
													{/* onClick={handleCanvas}> */}
													Capturar
												</Button>
												: <Button
													variant="contained"
													size="large"
													disableElevation
													sx={{ flexGrow: 1 }}
													endIcon={<CameraAlt />}
													onClick={() => {
														getUserVideo()
													}}>
													Abrir câmera
												</Button>
									}
								</Box>
							</Grid>
						</Grid>
					</Container>
				</Dialog>
			</React.Fragment>
		</SessionProvider>
	)
}
