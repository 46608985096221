import DebugDialog from '@components/Dialogs/DebugDialog';
import AppDrawer from '@components/Drawer';
import useAuth from '@context/auth/AuthHook';
import { Home, HomeMax, ListAltOutlined, Logout, PermMedia, Reviews } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Hidden } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/system';
import * as React from 'react';
import { useNavigate } from "react-router-dom";

export default function AppbarUI() {

	const { state } = useAuth();
	const history = useNavigate();
	const [open, setOpen] = React.useState<boolean>(false);
	const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);

	const theme = useTheme();

	if (!state.user) {
		return (
			<React.Fragment>
				<AppBar
					elevation={0}
					position="static"
					style={{
						backgroundColor: theme.palette.primary.main,
						// color: "#fff"
					}}>
					<Toolbar style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center"
					}}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}>
							<Button
								size="large"
								color="inherit"
								aria-label="menu"
								sx={{ mr: 2 }}
								startIcon={<Home />}
								onClick={() => {
									window.location.href = "/"
								}}
							>
								Início
							</Button>
						</div>
						<Button
							color="inherit"
							onClick={() => window.location.href = "/"}
							style={{
								textTransform: "capitalize"
							}}
						>
							Login
						</Button>
					</Toolbar>
				</AppBar>
			</React.Fragment>
		)
	} else {
		return (
			<AppBar
				elevation={0}
				position="static"
				style={{
					backgroundColor: theme.palette.primary.main,
					// color: "#fff"
				}}>
				<Toolbar
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center"
					}}>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}>
						<IconButton
							size="large"
							edge="start"
							color="inherit"
							aria-label="menu"
							sx={{ mr: 2 }}
							onClick={() => {
								setOpenDrawer(true)
							}}
						>
							<MenuIcon />
						</IconButton>
						<IconButton
							sx={{
								// color: "white",
								display: localStorage.getItem("@origin") ? "" : "none"
							}}
							onClick={() => {
								var origin = localStorage.getItem("@origin")
								if (origin) {
									window.location.href = JSON.parse(origin)
								}
							}}>
							<Home />
						</IconButton>
					</div>

					<Hidden mdDown>
						<Box
							display="flex"
							alignItems="center"
							gap={10}>
							<Button
								variant="text"
								startIcon={<HomeMax />}
								onClick={() => {
									// window.location.href = "/"
									history("/")
								}}
								sx={{
									color: "inherit",
								}}>
								Início
							</Button>
							{/* <Button
								variant="text"
								startIcon={<Face />}
								onClick={() => {
									window.location.href = "/biometrics"
								}}
								sx={{
									color: "white"
								}}>
								Biometria
							</Button> */}
							<Button
								variant="text"
								startIcon={<Reviews />}
								onClick={() => {
									// window.location.href = "/review"
									history("/review")
								}}
								sx={{
									color: "inherit",
									display: "none"
									// display: (state.user.professor || state.user.moodle_admin) ? "" : "none"
								}}>
								Revisão
							</Button>
							<Button
								variant="text"
								startIcon={<PermMedia />}
								onClick={() => {
									// window.location.href = "/mydata"
									history("/mydata")
									// setOpen(true)
								}}
								style={{
									color: "inherit",
									// display: profile.funcao === "PROFESSOR"
									// 	? profile.is_aluno
									// 		? ""
									// 		: "none"
									// 	: profile.funcao === "ALUNO"
									// 		? ""
									// 		: "none"
								}}>
								Meus dados
							</Button>
							<Button
								variant="text"
								startIcon={<ListAltOutlined />}
								onClick={() => {
									// window.location.href = "/listagem"
									history("/listagem")
								}}
								sx={{
									color: "inherit",
									display: (state.user.professor || state.user.moodle_admin) ? "" : "none"
								}}>
								Consulta
							</Button>
						</Box>
					</Hidden>

					<React.Fragment>
						<Button
							color="inherit"
							onClick={() => {
								sessionStorage.clear()
								localStorage.clear()
								window.location.href = "/"
							}}
							startIcon={<Logout />}
							style={{
								textTransform: "capitalize"
							}}
						>
							Sair
						</Button>
					</React.Fragment>
				</Toolbar>
				<AppDrawer open={openDrawer} setOpen={setOpenDrawer} />
				<DebugDialog open={open} setOpen={setOpen} />
			</AppBar>
		);
	}
}
