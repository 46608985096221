import useAuth from "@context/auth/AuthHook";
import { Profile } from "@context/auth/AuthServices";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

/**
 * @description Tela que realiza a busca dos dados de perfil do usuário antes de redirecioná-lo para as rotas internas
 * @returns
 */
export default function TokenUI() {
	const { setUser, setProfile } = useAuth();
	const [searchParams] = useSearchParams();

	React.useEffect(() => {
		if (searchParams.get("token")) {
			var data = JSON.parse(
				window.atob(searchParams.get("token") as string)
			);

			localStorage.setItem("token", JSON.stringify(data.token));
			localStorage.setItem("data", JSON.stringify(data));
			localStorage.setItem("@origin", JSON.stringify(data.origin));
			var user = {
				ra: data.ra,
				signed: Boolean(data.token) ? true : false,
				data: {
					AccessToken: data.token,
					ExpiresIn: 86400,
					tipo_auth: "redirect",
					TokenType: "Bearer",
				},
			};
			localStorage.setItem("@user", JSON.stringify(user));

			setUser(user);

			Profile({ ra: data.ra })
				.then((response: any) => {
					if (response.data.nome) {
						response.data.nome = response.data.nome
							.toString()
							.toLowerCase()
							.split(" ")
							.map(
								(name: any) =>
									name.charAt(0).toUpperCase() + name.slice(1)
							)
							.join(" ");
						localStorage.setItem(
							"@profile",
							JSON.stringify(response.data)
						);
						setProfile(response.data);
					}
				})
				.catch((err) => {
					console.log(err.message);
					localStorage.clear();
					sessionStorage.clear();
					toast.error(
						"Não foi possível concluir a autenticação, por favor, insira RA e Senha"
					);
				})
				.finally(() => {
					window.location.reload();
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <></>;
}
