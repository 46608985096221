import {
	Box,
	Button,
	IconButton,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

import { fileManagerApi } from "@api/services/filemanager";
import NoContent from "@components/NoContent";
import useAuth from "@context/auth/AuthHook";
import { CopyAll, Delete, PlayArrow } from "@mui/icons-material";
import { AxiosResponse } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AuditionService from "../audition.service";
import ActivityViewerDialog from "../dialogs/ActivityViewerDialog";
import DeleteActivityDialog from "../dialogs/DeleteActivityDialog";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#fff",
		color: "black",
		fontWeight: "semibold",
		fontSize: "14px",
	},
}));

export interface GPTFeedbackInterface {
	gpt_avg: number;
	gpt_feedback: string;
}

export default function ActivityTable(props: any) {
	const params = useParams();
	const theme = useTheme();
	// const { browserAgent } = useSession();
	const history = useNavigate();
	const { state } = useAuth();
	const [open, setOpen] = useState<boolean>(false);
	const [enabled, setEnabled] = useState<boolean>(false);
	const [deleteConfirmation, setDeleteConfirmation] =
		useState<boolean>(false);
	const [openDelete, setOpenDelete] = useState<boolean>(false);
	const [submission, setSubmission] = useState<any>(null);
	const [grade, setGrade] = useState<number | string>(0);
	const [url, setUrl] = useState<any>(null);
	const [data, setData] = useState<any>({});
	const [showImages, setShowImages] = useState<boolean>(false);

	const matches = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
		if (params.submission) {
			console.log(JSON.stringify(params));
			new AuditionService(fileManagerApi)
				.getOne(params.submission?.toString())
				.then((res: any) => {
					if (res.data && res.data[0] && res.data[0].file_key) {
						setData(res.data[0]);
						getVideo(res.data[0].file_url);
						setOpen(true);
					}
				})
				.catch((err) => {
					console.log("__--__--__--__");
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [analysis, setAnalysis] = useState<GPTFeedbackInterface>({
		gpt_avg: 0,
		gpt_feedback: "",
	});

	const [queryResult, setQueryResult] = useState<"PENDING" | "DONE" | "IDLE">(
		"IDLE"
	);

	const [comprehend, setComprehend] = useState<any>({});

	async function getAIEvaluation(id: string | number) {
		setQueryResult("PENDING");

		await fileManagerApi
			.get("/gpt/" + id)
			.then((res: AxiosResponse<GPTFeedbackInterface>) => {
				setAnalysis(res.data);
			});

		await fileManagerApi
			.get("/comprehend/" + id)
			.then((res: any) => {
				setComprehend(res.data);
				setQueryResult("DONE");
			})
			.finally(() => {
				// toast.info("Done")
				// window.location.href = "/student/submission/" + data.code;
				setQueryResult("IDLE");
			});
	}

	const getVideo = (path: string) => {
		// setUrl("https://dirin.s3.us-east-1.amazonaws.com/" + path);
		setUrl(path);
		setOpen(true);
	};

	function truncateString(str: string, len?: number) {
		if (str === undefined || str === null) {
			return "⚠️ Informação não disponível";
		}

		if (str.length <= 12) {
			return str;
		} else {
			return str.substring(0, len ?? 12) + "...";
		}
	}

	useEffect(() => {
		if (
			data &&
			data.transcription &&
			data.transcription[0] &&
			data.transcription[0].comprehend_sentiment
		) {
			return;
		} else if (data && data.transcription && data.transcription[0]) {
			//EVAL:	"adicionando requisicao para o ChatGPT"
			getAIEvaluation(data.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<React.Fragment>
			<TableContainer
				component={Box}
				style={{
					overflow: "hidden",
					overflowX: "scroll",
					border: "solid thin #ccc",
					borderTopRightRadius: "12px",
					borderTopLeftRadius: "12px",
				}}
			>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							{!props.tutor && (
								<StyledTableCell>ATIVIDADE</StyledTableCell>
							)}
							{/* <StyledTableCell align="left">
								CÓD. ATIVIDADE
							</StyledTableCell> */}
							{!props.tutor && (
								<StyledTableCell align="left">
									DESCRIÇÃO
								</StyledTableCell>
							)}
							{/* <StyledTableCell>RA</StyledTableCell> */}
							{props.tutor && (
								<StyledTableCell align="left">
									RA
								</StyledTableCell>
							)}
							<StyledTableCell align="center">
								APRESENTAÇÃO
							</StyledTableCell>
							{/* <StyledTableCell align="center">Id. Submissão</StyledTableCell> */}
							<StyledTableCell align="center">
								CÓDIGO
							</StyledTableCell>
							<StyledTableCell align="center">
								AÇÕES
							</StyledTableCell>
							<StyledTableCell align="center">
								DATA
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props &&
						props.auditions &&
						Array.isArray(props.auditions) &&
						props.auditions.length === 0 ? (
							<TableRow sx={{ textAlign: "center" }}>
								<TableCell colSpan={8}>
									<NoContent />
								</TableCell>
							</TableRow>
						) : (
							props.auditions &&
							props.auditions.length > 0 &&
							props.auditions.map((row: any) => (
								<TableRow
									key={row.id}
									sx={{
										"&:last-child td, &:last-child th": {
											border: 0,
										},
									}}
								>
									{!props.tutor && (
										<TableCell component="th" scope="row">
											<Typography variant="body2">
												{" "}
												{row?.activity?.title ??
													"⚠️ Informação não disponível"}{" "}
											</Typography>
										</TableCell>
									)}
									{/* <TableCell component="th" scope="row">
										{row.activity_id ??
											"⚠️ Informação não disponível"}
									</TableCell> */}
									{!props.tutor && (
										<TableCell
											component="th"
											scope="row"
											colSpan={1}
										>
											<Typography
												variant="body2"
												sx={{ wordWrap: "break-word" }}
											>
												{truncateString(
													row?.activity?.description,
													40
												) ??
													"⚠️ Descrição não fornecida"}
												{/* {`${row.activity.description !== "" || row.activity.description !== null ? row.activity.description.substring(0, 35)}...`} */}
											</Typography>
										</TableCell>
									)}
									{props.tutor && (
										<TableCell
											component="th"
											scope="row"
											align="left"
										>
											<Button
												variant="text"
												size="small"
												startIcon={<CopyAll />}
												sx={{
													minWidth: "100px",
												}}
												onClick={() => {
													navigator.clipboard.writeText(
														row.user_id
													);
													toast.success(
														"RA copiado com sucesso! ",
														{
															position:
																"top-right",
														}
													);
												}}
											>
												{row.user_id}
											</Button>
											{/* <Typography variant="caption"> {row.user_id} </Typography> */}
										</TableCell>
									)}
									<TableCell align="center">
										<Button
											variant="text"
											onClick={() => {
												getVideo(row.file_url);
												// setAudition(row.code)
												setData(row);
											}}
											size="small"
											// style={{ backgroundColor: theme.palette.secondary.main, color: "white" }}
											startIcon={<PlayArrow />}
										>
											Visualizar
										</Button>
									</TableCell>

									{/* <TableCell align="center" colSpan={1}>
											{row.id}
										</TableCell> */}

									<TableCell align="center" colSpan={1}>
										<Tooltip title={row.code}>
											<Button
												variant="text"
												size="small"
												startIcon={<CopyAll />}
												sx={{
													minWidth: "200px",
												}}
												onClick={() => {
													navigator.clipboard.writeText(
														row.code
													);
													toast.success(
														"Código copiado com sucesso! ",
														{
															position:
																"top-right",
														}
													);
												}}
											>
												{truncateString(row.code)}
											</Button>
										</Tooltip>
									</TableCell>
									<TableCell>
										<Tooltip title="Excluir apresentação">
											<IconButton
												size="small"
												sx={
													{
														// border: "solid thin #ddd",
													}
												}
												onClick={async () => {
													setOpenDelete(true);
													setSubmission(row);
												}}
											>
												<Delete />
											</IconButton>
										</Tooltip>
									</TableCell>
									<TableCell align="left">
										{row.created_at}
									</TableCell>
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</TableContainer>

			<DeleteActivityDialog
				submission={submission}
				openDelete={openDelete}
				setOpenDelete={setOpenDelete}
				setDeleteConfirmation={setDeleteConfirmation}
				deleteConfirmation={deleteConfirmation}
			/>

			<ActivityViewerDialog
				open={open}
				state={state}
				setEnabled={setEnabled}
				setOpen={setOpen}
				data={data}
				enabled={enabled}
				comprehend={comprehend}
				analysis={analysis}
				showImages={showImages}
				setShowImages={setShowImages}
				getAIEvaluation={getAIEvaluation}
				grade={grade}
				setGrade={setGrade}
				queryResult={queryResult}
				url={url}
			/>
		</React.Fragment>
	);
}
