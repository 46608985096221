import { fileManagerApi } from "@api/services/filemanager";
import { useEffect, useState } from "react";
import { ActivityContext, INIT_STATE } from "./activity.context";
import { ActivityService } from "./activity.service";

export default function ActivityProvider({ children }: any) {
	const [state, setState] = useState<any>(
		JSON.parse(localStorage.getItem("@activities") as string) ||
			INIT_STATE.state
	);

	useEffect(() => {
		localStorage.setItem("@activities", JSON.stringify(state));
	}, [state]);

	useEffect(() => {
		const activities = JSON.parse(
			localStorage.getItem("@activities") as string
		);
		if (activities) {
			setState(activities);
		}
	}, []);

	useEffect(() => {
		const api = new ActivityService(fileManagerApi);
		api.findAll().then((res: any) => {
			setState({ ...state, activities: res.data });
		});
	}, []);

	return (
		<ActivityContext.Provider
			value={{
				state,
				setState,
			}}
		>
			{children}
		</ActivityContext.Provider>
	);
}
