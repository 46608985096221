import { PalcoVirtualService } from "@api/classes/v3/PalcoVirtual.service";
import { fileManagerApi } from "@api/services/filemanager";
import Appbar from "@components/Appbar";
import useAuth from "@context/auth/AuthHook";
import { useProfile } from "@context/profile/profile.hook";
import { AddToPhotos, Refresh, Work } from "@mui/icons-material";
import {
	Avatar,
	Box,
	Button,
	Container,
	Grid,
	LinearProgress,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function PanelLoaderComponent() {
	const { user, profile } = useAuth();
	const { profileDispatch } = useProfile();

	const api = new PalcoVirtualService(fileManagerApi);
	const [callState, setCallState] = useState<boolean>(false);
	const [settings, setSettings] = useState<any>({});
	const [health, setHealth] = useState<any>({});

	useEffect(() => {
		setCallState(true);

		try {
			var $settings = JSON.parse(
				localStorage.getItem("$settings") as string
			);
			var $health = JSON.parse(localStorage.getItem("$health") as string);

			if ($settings && $health) {
				if ($settings && $settings.settings) {
					setSettings($settings);
				}

				if (
					$health !== null &&
					$health.status &&
					$health.status === "ok"
				) {
					setHealth($health);
				}
			} else {
				api.getSettings()
					.then((res: AxiosResponse<any>) => {
						localStorage.setItem(
							"$settings",
							JSON.stringify(res.data)
						);
						setSettings(res.data);
					})
					.then(async () => {
						try {
							await api
								.getHealth()
								.then((res: AxiosResponse<any>) => {
									setHealth(res.data);
									localStorage.setItem(
										"$health",
										JSON.stringify(res.data)
									);
									if (res.data.status === "ok") {
										toast.success(
											"Sucesso! Clique para prosseguir para a aplicação"
										);
									} else {
										throw new Error(
											"Falha(s) crítica(s) foram encontradas! Por favor, tente novamente mais tarde"
										);
									}
								})
								.catch((err: any) => {
									toast.error(
										"Falha(s) crítica(s) foram encontradas! Por favor, tente novamente mais tarde"
									);
								})
								.finally(() => {
									return;
								});
						} catch (e: any) {
							toast.error(e.getMessage());
						}
					})
					.finally(() => {
						setTimeout(() => setCallState(false), 2500);
					});
			}
		} catch (e) {
			toast.error(
				"Falha(s) crítica(s) foram encontradas! Por favor, tente novamente mais tarde"
			);
		} finally {
			setCallState(false);
		}
	}, []);

	useEffect(() => {
		if (health && health.status === "ok") {
			profileDispatch({
				payload: {
					email: profile.email ?? `visitante@${user.ra}`,
					nome: user.ra,
					funcao: profile.funcao,
					is_aluno: profile.is_aluno,
				},
				type: "SET_PROFILE",
			});
		}
	}, [health]);

	useEffect(() => {
		console.table(settings);
		console.table(health);
	}, [settings, health]);

	return (
		<React.Fragment>
			<Appbar />
			<Container>
				<Grid container>
					<Grid item md={3}></Grid>
					<Grid item md={6} sx={{ marginTop: "6vh" }}>
						{/*<pre>*/}
						{/*	{JSON.stringify(settings, null, 4)}*/}
						{/*</pre>*/}
						<Box
							mt={3}
							mb={1}
							sx={{
								padding: "12px 48px",
								minHeight: "45vh",
								minWidth: "100%",
								border: "solid thin #dedede",
								borderRadius: "12px",
							}}
						>
							<Box
								mt={3}
								mb={3}
								sx={{
									textAlign: "center",
									display: "flex",
									alignItems: "center",
									flexDirection: "column",
								}}
							>
								<Avatar
									sx={{
										width: "72px",
										height: "72px",
										marginBottom: "24px",
									}}
								>
									A
								</Avatar>
								<Typography
									variant="h5"
									component="h5"
									sx={{ marginBottom: "12px" }}
								>
									{callState ? "Aguarde" : "Tudo pronto!"}
								</Typography>
								<Typography variant="body1" component="div">
									{callState
										? "Estamos carregando seu perfil e sua lista de permissões"
										: "Visualize sua lista de permissões"}
								</Typography>
							</Box>
							{callState ? (
								<LinearProgress color="primary" />
							) : (
								<></>
							)}
							<Box
								mt={1}
								mb={1}
								sx={{
									border: "solid thin #f6f6f6",
									marginTop: "36px",
									borderRadius: "4px",
								}}
							>
								<List>
									<ListItem>
										<ListItemAvatar>
											<Avatar>
												<AddToPhotos />
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={"Atividades"}
											secondary={
												"Crie, visualize e compartilhe atividades do tipo Palco Virtual"
											}
										/>
									</ListItem>
									<ListItem>
										<ListItemAvatar>
											<Avatar>
												<Work />
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={"Biometrias"}
											secondary={
												"Crie, visualize e compartilhe atividades do tipo Palco Virtual"
											}
										/>
									</ListItem>
									<ListItem>
										<ListItemAvatar>
											<Avatar>
												<Work />
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={"Revisões"}
											secondary={
												"Tenha acesso às revisões das submissões da plataforma"
											}
										/>
									</ListItem>
								</List>
							</Box>
							<Button
								fullWidth
								onClick={() => {
									profileDispatch({
										payload: {
											email:
												profile.email ??
												`visitante@${user.ra}`,
											nome: user.ra,
											funcao: profile.funcao,
											is_aluno: profile.is_aluno,
										},
										type: "SET_PROFILE",
									});
								}}
								sx={{
									marginTop: "36px",
									marginBottom: "12px",
								}}
							>
								Continuar com meu RA
							</Button>
						</Box>
						<Box
							p={1}
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Typography variant="caption" component="div">
								Estamos carregando suas permissões
							</Typography>
							<Button
								variant="text"
								disabled
								size="small"
								startIcon={<Refresh />}
							>
								Repetir
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	);
}
