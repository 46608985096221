import Appbar from "@components/Appbar";
import SessionProvider from "@context/session/SessionProvider";
import SubmissionComponent from "./submission.component";

export default function SubmissionModule() {
	return (
		<SessionProvider>
			<Appbar />
			<SubmissionComponent />
		</SessionProvider>
	)
}