import { fileManagerApi } from "@api/services/filemanager";
import GenericDialog from "@components/Dialogs/GenericDialog";
import { CopyAll, PlayArrow } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, ListItem, ListItemText, Typography, useMediaQuery, useTheme } from "@mui/material";
import { formatDistance } from "date-fns";
import { pt } from "date-fns/locale";
import React from "react";
import { toast } from "react-toastify";
// import GenericDialog from "../GenericDialog";

export default function AuditionV2UI({ audition }: any) {

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('sm'));

	const [url, setUrl] = React.useState<any>(null);
	const [openDialog, setOpenDialog] = React.useState<boolean>(false);

	const getVideo = (path: string) => {
		fileManagerApi.get(`/video-retrieve?audition=${path}`)
			.then((response: any) => {
				setUrl(response.data.video);
				setOpenDialog(true);
			});
	}

	return (
		<React.Fragment>
			<ListItem alignItems="flex-start">
				{/* <Hidden mdDown>
                        <ListItemAvatar>
                            <Avatar sx={{ backgroundColor: theme.palette.secondary.main }}>
                                <OndemandVideo />
                            </Avatar>
                        </ListItemAvatar>
                    </Hidden> */}
				<ListItemText
					primary="Palco Virtual"
					secondary={
						<React.Fragment>
							<Typography variant="body2" component="div">
								{/* {new Date().toLocaleDateString()} */}
								{
									audition
										&& audition.createdAt
										? formatDistance(
											new Date(audition.createdAt),
											new Date(), {
											addSuffix: true,
											locale: pt
										}).toString()
										: ""
								}
							</Typography>
							<Typography variant="body2" component="div" style={{ marginTop: "12px" }}>
								{/* Melhore o seu inglês e conheça a cultura dos falantes nativos desse idioma com histórias reais e emocionantes. Elas são narradas em um inglês fácil de entender e incluem comentários em português para ajudar com o contexto */}
								Visualize a gravação enviada por meio do Palco Virtual
							</Typography>
							<Box
								display="flex"
								justifyContent={"space-between"}
								mt={2}>
								<Box>
									{/* <IconButton>
                                                        <Insights />
                                                    </IconButton> */}
									<IconButton
										sx={{
											backgroundColor: theme.palette.secondary.main,
											color: "white"
										}}
										onClick={() => {
											getVideo(audition.audition)
										}}>
										<PlayArrow />
									</IconButton>
								</Box>
								<Button
									startIcon={<CopyAll />}
									onClick={() => {
										navigator.clipboard.writeText(audition.audition)
										toast.success("Código copiado para área de transferência", {
											position: "top-right"
										})
									}}>
									{matches ? audition.audition : audition.audition.substring(0, 12) + "..."}
								</Button>
							</Box>
						</React.Fragment>
					}
				/>
			</ListItem>
			<Divider />
			<GenericDialog open={openDialog} setOpen={setOpenDialog} url={url} />
		</React.Fragment>
	)
}