import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// import SettingsProvider from '@context/settings/Settings.provider';
import { CssBaseline } from "@mui/material";
import { orange, red } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import { ptBR } from "@mui/material/locale";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

declare module "@mui/material/styles" {
	interface Theme {
		status: {
			danger: string;
		};
	}

	// allow configuration using `createTheme`
	interface ThemeOptions {
		status?: {
			danger?: string;
		};
	}
}

const theme = createTheme(
	{
		palette: {
			mode: "light",
			primary: {
				// main: JSON.parse(process.env.REACT_APP_PROD_MODE as string) ? red[900] : indigo['A400']
				main: red[900],
			},
			secondary: {
				// main: JSON.parse(process.env.REACT_APP_PROD_MODE as string) ? orange[500] : pink['A400']
				main: orange[500],
			},
		},
		shape: {
			borderRadius: 12,
		},
	},
	ptBR
);

root.render(
	<React.StrictMode>
		{/* <SettingsProvider> */}
		<ToastContainer theme={"light"} />
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<App />
		</ThemeProvider>
		{/* </SettingsProvider> */}
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
