import Appbar from "@components/Appbar";
import ActivityProvider from "../activity/activity.provider";
import AdminComponent from "./admin.component";

export default function AdminModule() {
	return (
		<ActivityProvider>
			<Appbar />
			<AdminComponent />
		</ActivityProvider>
	);
}
