import { deleteFile, getBiometria } from "@api/services/fileservices";
import NoContent from "@components/NoContent";
import useAuth from "@context/auth/AuthHook";
import { Delete } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Dialog, DialogTitle, Grid, Typography, useTheme } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { toast } from "react-toastify";

export default function BiometricsDebug() {

	const { user } = useAuth()
	const theme = useTheme()

	const [images, setImages] = React.useState<any[]>([]);

	const [openDialog, setOpenDialog] = React.useState<boolean>(false);

	const handleClose = () => {
		setOpenDialog(false);
	}

	useEffect(() => {
		getBiometria(user.ra)
			.then((response: any) => {
				// setImages(response.data.biometrics)
				setImages(response.data)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	if (images.length === 0) {
		return <NoContent />
	} else {
		return (
			<React.Fragment>
				<Grid container spacing={1} sx={{ marginTop: "12px" }}>
					{
						images.map((image, index) => {
							return (
								<Grid
									item
									key={index}
									xs={12}
									sm={12}
									md={6}
									lg={6}
									xl={6}>
									<Box
										sx={{
											backgroundColor: "#FAF9FF",
											// backgroundColor: "#F0F",
											padding: "12px",
											margin: "6px",
											borderRadius: "12px",
										}}>
										<img
											src={image.path}
											alt=""
											style={{
												width: "100%",
												height: "auto",
											}} />
										<Box display="flex" justifyContent="space-between" alignItems="center">
											<Typography
												variant="body1"
												component="div"
												sx={{
													// backgroundColor: "#F00",
													// backgroundColor: "#FFF6F6",
													// color: "#530909",
													padding: "2px 6px",
													// borderRadius: "6px",
													// marginBottom: "12px"
												}}>
												{image.label}
											</Typography>
											<ButtonGroup>

												{/* <Button
													startIcon={<PublishedWithChanges />}
													onClick={() => {
														setOpenDialog(true)
													}}>
													Substituir
												</Button> */}
												<Button
													startIcon={<Delete />}
													onClick={() => {
														deleteFile(image.id)
															.then((response: any) => {
																toast.success("Imagem removida com sucesso!")
															})
															.then(() => {
																setImages(images.filter((item) => item.id !== image.id))
															})
													}}>
													Excluir
												</Button>
											</ButtonGroup>
										</Box>
									</Box>
								</Grid>
							)
						})
					}
				</Grid>
				<BiometricsActionDialog openDialog={openDialog} handleClose={handleClose} theme={theme} context={<ReplaceImageContext />} />
			</React.Fragment>
		)
	}
}

type BiometricDialogActionsProps = {
	openDialog: boolean,
	handleClose: Dispatch<SetStateAction<boolean>>,
	theme: any,
	context: any
}

export function BiometricsActionDialog({ openDialog, handleClose, theme, context }: BiometricDialogActionsProps) {
	return (
		<Dialog open={openDialog} onClose={handleClose}>
			<DialogTitle
				sx={{
					backgroundColor: theme.palette.primary.main,
					color: "white"
				}}>
				<Typography variant="subtitle1" component="div">Confirmação de ação</Typography>
			</DialogTitle>
			<React.Fragment>
				{context}
			</React.Fragment>
		</Dialog>
	)
}
export function DeleteActionContext() {
	return (
		<>
			<Box p={3}>
				<Typography variant="body1" component="div">Você tem certeza que deseja excluir a imagem?</Typography>
				<Button>Excluir</Button>
			</Box>
		</>
	)
}

export function ReplaceImageContext() {
	return (
		<>
			<Box p={3}>
				<Typography variant="body1" component="div">Você está obtendo a imagem para a posição: </Typography>
			</Box>
			<Box sx={{
				backgroundColor: "black",
				width: "100%",
				height: "200px"
			}}></Box>
			<Button>Capturar</Button>
		</>
	)
}