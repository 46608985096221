import Appbar from "@components/Appbar";
import SessionProvider from "@context/session/SessionProvider";
import DashboardUI from "./Dashboard";

export default function DashboardModule() {
	return (
		<SessionProvider>
			<Appbar />
			<DashboardUI />
		</SessionProvider>
	)
}