import Appbar from "@components/Appbar";
import SessionProvider from "@context/session/SessionProvider";
import AuditionComponent from "./audition.component.template";

export default function AuditionModule() {
	return (
		<>
			<Appbar />
			<SessionProvider>
				<AuditionComponent />
			</SessionProvider>
		</>
	)
}