import { fileManagerApi } from "@api/services/filemanager";
import { uploadFilesViaLambda } from "@api/services/lambda";
import MiniInfo from "@components/Dialogs/MiniInfo";
import ProgressIndicator from "@components/ProgressIndicator";
import useAuth from "@context/auth/AuthHook";
import useSession from "@context/session/SessionHook";
import {
	ArrowForward,
	CameraAlt,
	CenterFocusStrong,
	Delete,
} from "@mui/icons-material";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	IconButton,
	Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { convertBase64ToBlob } from "./biometry.helper";
import BiometryService from "./biometry.service";

import { poses as references } from "../mydata/constants";

function getState(user: any) {
	return [
		`${process.env.REACT_APP_FILE_LOCATION}/${user.id}/frente.png`,
		`${process.env.REACT_APP_FILE_LOCATION}/${user.id}/direita.png`,
		`${process.env.REACT_APP_FILE_LOCATION}/${user.id}/esquerda.png`,
		`${process.env.REACT_APP_FILE_LOCATION}/${user.id}/cima.png`,
		`${process.env.REACT_APP_FILE_LOCATION}/${user.id}/baixo.png`,
	];
}

export default function BiometryTemplate() {
	const { state } = useAuth();
	// const history = useNavigate();
	const canvasRef = useRef<any>(null);
	const videoRef = useRef<any>(null);

	const { setActiveStep, images, setImages } = useSession();

	const [firstShotDelay, setFirstShotDelay] = useState<number>(3000);
	const [finalState, setFinalState] = useState<any>([]);
	const [initialState, setInitialState] = useState<any>(() =>
		getState(state.usuario)
	);

	const [open, setOpen] = useState<boolean>(false);
	const [openCamera, setOpenCamera] = useState<boolean>(true);

	const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);

	useEffect(() => {
		loadBiometry();
		getUserVideo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function getUserVideo() {
		setInterval(() => {
			return;
		}, firstShotDelay);

		setFirstShotDelay(0);

		setOpenCamera(true);
		const player = videoRef.current;
		player.setAttribute("playsinline", "");

		const handleSuccess = (stream: MediaStream) => {
			player.srcObject = stream;

			player.addEventListener("loadedmetadata", () => {
				setOpenCamera(false);
			});
		};

		navigator.mediaDevices
			.getUserMedia({
				audio: false,
				video: {
					width: window.innerWidth < 800 ? 480 / 2 : 854 / 2,
					height: window.innerWidth < 800 ? 854 / 2 : 480 / 2,
				},
			})
			.then(handleSuccess)
			.then(() => videoRef.current.play());
	}

	async function capture() {
		if (canvasRef.current) {
			var el: HTMLCanvasElement | any = document.createElement("canvas");

			el.width = videoRef.current.videoWidth;
			el.height = videoRef.current.videoHeight;

			console.log(videoRef.current.videoHeight);
			console.log(videoRef.current.videoWidth);
			el.getContext("2d").drawImage(
				videoRef.current,
				0,
				0,
				videoRef.current.videoWidth,
				videoRef.current.videoHeight
			);

			// canvasRef.current.append(el)
			const file = convertBase64ToBlob(el.toDataURL("image/png"));

			// await uploadFilesViaLambda(user, file, "image/png", segments[-1])
			await uploadFilesViaLambda(
				state.usuario,
				file,
				"image/png",
				initialState[0]
			).then(() => {
				toast.success("Imagem enviada com sucesso");
			});
		}
	}

	async function loadBiometry() {
		await new BiometryService(fileManagerApi)
			.getAll(state.usuario.id)
			.then((res: AxiosResponse<any>) => {
				setImages(res.data);

				const { data } = res;

				data.forEach((item: any) => {
					setInitialState((prevState: any) => {
						return prevState.filter(
							(key: any) => key !== item.file_key
						);
					});
				});
				setFinalState(res.data);

				if (res.data.length >= 5) setActiveStep(1);

				toast.success("Carregando biometria existente");
			})
			.catch((err: Error) => {
				toast.error("Falha ao carregar");
				throw new Error("Falha ao carregar");
			});
	}

	async function submitBiometry() {
		if (initialState.length > 0) {
			setOpenBackdrop(true);
			await capture();
			await new BiometryService(fileManagerApi)
				.create({
					user_id: state.usuario.id,
					file_key: initialState[0],
				})
				.then(async (res: AxiosResponse<any>) => {
					await loadBiometry();
				})
				.catch(async (res: AxiosResponse<any>) => {
					await loadBiometry();
				})
				.finally(() => {
					setOpenBackdrop(false);
					window.location.reload();
				});
		}
	}

	async function deleteBiometry(id: number) {
		setOpenBackdrop(true);
		const bio = finalState.filter((item: any) => item.id === id)[0];
		await new BiometryService(fileManagerApi)
			.delete(id)
			.then(async (res: AxiosResponse<any>) => {
				await loadBiometry();
				setInitialState([...initialState, bio.file_key]);
			})
			.catch(async (res: AxiosResponse<any>) => {
				await loadBiometry();
			})
			.finally(() => {
				setOpenBackdrop(false);
				setActiveStep(0);
			});
	}

	useEffect(() => {
		if (initialState.length === 0 && finalState.length >= 5) {
			setOpen(true);
		}
	}, [finalState.length, initialState.length]);

	useEffect(() => {
		if (images.length >= 5) {
			setOpen(true);
			setActiveStep(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// "homolog-palco-virtual/ 1540002476/Frente"
	function extractFormattedStringOLD(inputString: string): string {
		const lastSlashIndex = inputString.lastIndexOf("/");
		const lastDotIndex = inputString.lastIndexOf(".");

		if (
			lastSlashIndex !== -1 &&
			lastDotIndex !== -1 &&
			lastDotIndex > lastSlashIndex
		) {
			const desiredSubstring = inputString.substring(
				lastSlashIndex + 1,
				lastDotIndex
			);
			const formattedResult =
				desiredSubstring.charAt(0).toLowerCase() +
				desiredSubstring.slice(1).toLowerCase();
			return formattedResult;
		} else {
			throw new Error("Invalid input string");
		}
	}

	function extractFormattedString(inputString: string): string {
		const lastSlashIndex = inputString.lastIndexOf("/");

		if (lastSlashIndex !== -1) {
			const lastSlice = inputString.substring(lastSlashIndex + 1);
			const formattedResult =
				lastSlice.charAt(0).toUpperCase() +
				lastSlice.slice(1).toLowerCase();
			return formattedResult;
		} else {
			throw new Error("Invalid input string");
		}
	}

	return (
		<>
			<Backdrop open={openBackdrop}>
				<CircularProgress />
			</Backdrop>
			<Container maxWidth={"xl"} sx={{ paddingTop: "36px" }}>
				{/* 
				<pre>
					{JSON.stringify(initialState, null, 4)}
					{JSON.stringify(finalState, null, 4)}
				</pre> 
			*/}
				{/* <Grid container>
					<Grid item xs={12} sm={12} md={12} sx={{ height: "100px", padding: "solid 4px red", background: "purple", mb: 3, color: "white" }} order={{ sm: 1, xs: 2 }}><h1>1</h1></Grid>
					<Grid item xs={12} sm={12} md={12} sx={{ height: "100px", padding: "solid 4px red", background: "purple", mb: 3, color: "white" }} order={{ sm: 3, xs: 3 }}><h1>2</h1></Grid>
					<Grid item xs={12} sm={12} md={12} sx={{ height: "100px", padding: "solid 4px red", background: "purple", mb: 3, color: "white" }} order={{ sm: 2, xs: 1 }}><h1>3</h1></Grid>
				</Grid> */}
				<Grid container spacing={1}>
					<Grid item md={12}>
						<ProgressIndicator />
					</Grid>
					<Grid
						item
						md={initialState.length === 0 ? 12 : 6}
						order={{ xs: 3, sm: 3, md: 2 }}
					>
						{initialState.length !== 0 && (
							<Grid container spacing={1} mb={2} mt={2}>
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
								>
									<Typography variant="h5">
										Imagens pendentes - Posição do rosto
									</Typography>
								</Grid>
								{initialState.map(
									(file_key: any, index: number) => {
										return (
											<Grid
												item
												md={2}
												xs={2}
												sm={2}
												key={index}
											>
												<Box
													p={1}
													sx={{
														display: "flex",
														alignItems: "center",
														flexDirection: "column",
														// backgroundColor:
														// 	"#e5e5ff",
														// border: "solid thin #6666ff",
														// borderRadius: "4px",
													}}
												>
													{references.map(
														(
															reference: any,
															index: number
														) => {
															return (
																extractFormattedString(
																	file_key
																).replace(
																	".png",
																	""
																) ===
																	reference.label && (
																	<Box
																		key={
																			index
																		}
																		sx={{
																			display:
																				"flex",
																			flexDirection:
																				"column",
																			alignItems:
																				"center",
																		}}
																	>
																		<img
																			src={
																				reference.image
																			}
																			width="68"
																			alt=""
																		></img>
																		{/* <Typography variant="caption" component="p" sx={{ textAlign: "center" }}>{reference.label}</Typography> */}
																	</Box>
																)
															);
														}
													)}
													<Typography
														sx={{
															textAlign: "center",
															mt: 1,
															mb: 1,
														}}
														variant="caption"
													>
														{/* Olhar voltado para{" "}
														<br />{" "} */}
														{/* {file_key} */}

														{extractFormattedString(
															file_key
														).replace(".png", "")}
													</Typography>
												</Box>
											</Grid>
										);
									}
								)}
							</Grid>
						)}

						<Grid container spacing={2}>
							<Grid item md={12} sx={{ mt: 3, mb: 2 }}>
								<Typography variant="h5">
									Imagens capturadas
								</Typography>
							</Grid>

							<Grid
								item
								md={12}
								spacing={2}
								container
								sx={{
									overflowX: "hidden",
									marginBottom: "20vh",
								}}
							>
								<Box
									sx={{
										// border: "solid thin #ccc",
										display: "flex",
										flexDirection: "row",
										width: "100vw",
										overflow: "hidden",
										overflowX: "scroll",
										flexWrap: "nowrap",
										gap: "12px",
									}}
								>
									{finalState.map(
										(media: any, index: number) => {
											return (
												<Box
													key={index}
													sx={{
														// margin: "6px",
														minWidth: "172px",
														borderRadius: "8px",
														border: "solid thin #ccc",
														overflow: "hidden",
													}}
												>
													<Box
														p={1}
														sx={{
															backgroundColor:
																"#ffffff",
															borderRadius: "4px",
														}}
													>
														<img
															src={
																"https://dirin.s3.us-east-1.amazonaws.com/" +
																media.file_key
															}
															alt="item"
															width="150px"
														/>
														<Box
															sx={{
																display: "flex",
																flexDirection:
																	"row",
																alignItems:
																	"center",
																justifyContent:
																	"space-between",
																mb: 1,
															}}
														>
															<Typography
																variant="caption"
																sx={{
																	border: "none",
																}}
															>
																{extractFormattedString(
																	media.file_key
																).toUpperCase()}
															</Typography>
															<IconButton
																sx={{
																	border: "solid thin #ccc",
																}}
																size="small"
																onClick={async () => {
																	await deleteBiometry(
																		media.id
																	).finally(
																		() => {
																			window.location.reload();
																		}
																	);
																}}
															>
																<Delete />
															</IconButton>
														</Box>
													</Box>
												</Box>
											);
										}
									)}
								</Box>
								{/* <pre>
									{JSON.stringify(finalState, null, 4)}
								</pre> */}
							</Grid>
						</Grid>
					</Grid>
					{initialState.length !== 0 && (
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							lg={6}
							xl={6}
							order={{ xs: 2, sm: 2, md: 3 }}
						>
							<Grid container>
								<Grid
									item
									md={12}
									xs={12}
									sm={12}
									lg={12}
									xl={12}
									sx={{
										mt: 3,
									}}
								>
									<Typography variant="h5">Câmera</Typography>
								</Grid>
								<Grid
									item
									md={12}
									// xs={12}
									// sm={12}
									sx={{ mt: "12px" }}
								>
									<Box
										sx={{
											minHeight: "480px",
											minWidth: "180px",
											backgroundColor: "black",
											borderRadius: "12px",
											mb: 2,
											mt: 1,
											overflow: "hidden",
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<video
											ref={videoRef}
											style={{
												width: "100%",
												height: "100%",
											}}
										></video>
									</Box>

									<Button
										onClick={getUserVideo}
										variant="outlined"
										startIcon={<CameraAlt />}
									>
										Abrir câmera
									</Button>
									<Button
										disabled={openCamera}
										onClick={submitBiometry}
										variant="outlined"
										startIcon={<CenterFocusStrong />}
									>
										Capturar imagem
									</Button>
								</Grid>
								<Grid
									item
									md={12}
									sx={{ mt: 2, mb: 1, display: "none" }}
								>
									<Typography variant="h5">
										Posições de rosto
									</Typography>
								</Grid>
								<Grid
									item
									md={12}
									sx={{
										display: "none",
										// display: "flex",
										alignItems: "center",
									}}
								>
									{references.map(
										(reference: any, index: number) => {
											return (
												<Box
													key={index}
													sx={{
														display: "flex",
														flexDirection: "column",
														alignItems: "center",
													}}
												>
													<img
														src={reference.image}
														width="64"
														alt=""
													></img>
													<Typography
														variant="caption"
														component="p"
														sx={{
															textAlign: "center",
														}}
													>
														{reference.label}
													</Typography>
												</Box>
											);
										}
									)}
								</Grid>
							</Grid>
						</Grid>
					)}

					<MiniInfo
						open={open}
						setOpen={setOpen}
						goAction={() => {
							if (images.length >= 5) {
								setActiveStep(1);
								window.location.href = "/recorder";
							} else {
								setActiveStep(0);
								window.location.href = "/biometrics";
							}
						}}
					/>
					{initialState.length === 0 && finalState.length === 5 && (
						<Grid
							item
							md={12}
							sx={{
								minHeight: "20vh",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Button
								endIcon={<ArrowForward />}
								variant="outlined"
								onClick={() => {
									// history("/recorder")
									setOpen(true);
								}}
							>
								Ir para a apresentação
							</Button>
						</Grid>
					)}

					<Grid item md={12}>
						<Box ref={canvasRef}></Box>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}
