import { List, ListSubheader } from "@mui/material";
import useSession from "@context/session/SessionHook";
// import AuditionItem from "../AuditionItem";
import AuditionV2 from "@components/AuditionV2";



export default function Auditions() {

	const { auditions } = useSession();

	return (
		<List
			component="nav"
			aria-labelledby="nested-list-subheader"
			subheader={
				<ListSubheader component="div" id="nested-list-subheader">
					Apresentações submetidas ao Palco Virtual
				</ListSubheader>
			}>
			{
				auditions
				&& auditions.user_existing_auditions
				&& auditions.user_existing_auditions.map((audition: any) => {
					// return <Audition key={audition.id} audition={audition}/>                        
					// return <AuditionItem key={audition.id} audition={audition}/>
					return <AuditionV2 key={audition.id} audition={audition} />
				})
			}
		</List>
	);
}