import { createBrowserRouter } from "react-router-dom";

/**
 * Import application modules
 */
import ActivityModule from "@modules/activity";
import AuditionModule from "@modules/audition";
import BiometryModule from "@modules/biometry";
import ListModule from "@modules/list";
import PanelModule from "@modules/panel";
import RevisionModule from "@modules/revision";
import SubmissionModule from "@modules/submission";
import DashboardModule from "@pages/private/Dashboard";

/**
 * General use case pages
 */
import Submissions from "@modules/submissions";
import ErrorPage from "@pages/layouts/Error";
import LoadingUI from "@pages/public/Loading";

/**
 * @description Rotas internas do projeto
 */
export const Private = createBrowserRouter([
	{
		path: "/",
		element: <PanelModule key={window.location.href} />,
	},
	{
		path: "/list/:submission",
		element: <Submissions key={window.location.href} />,
	},
	{
		path: "/list/:submission/reload",
		element: <PanelModule key={window.location.href} />,
	},
	{
		path: "/student",
		element: <PanelModule />,
	},
	{
		path: "/student/submission/:submission",
		element: <PanelModule />,
	},
	{
		path: "/err$",
		element: <ErrorPage />,
	},
	{
		path: "/dashboard",
		element: <DashboardModule />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/listagem",
		element: <ListModule />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/signin/:token?",
		element: <LoadingUI />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/recorder/:activity_id?",
		element: <AuditionModule />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/biometrics",
		element: <BiometryModule />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/mydata",
		element: <BiometryModule />,
		// element: <MyDataModule />,
		// errorElement: <ErrorPage />
	},
	{
		path: "/submission/:id",
		element: <SubmissionModule />,
	},
	{
		path: "/review",
		element: <RevisionModule />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/activity/:id?",
		element: <ActivityModule key={window.location.href} />,
	},
	{
		path: "/activity/:id?/item/:enabled",
		element: <ActivityModule key={window.location.href} />,
	},
	{
		path: "/activity/:id/edit",
		element: <PanelModule />,
	},
	{
		path: "/activity/:id/items/:enabled",
		element: <PanelModule key={window.location.href} />,
	},
	{
		path: "/activity/:id/delete/:delete?",
		element: <PanelModule />,
	},
]);
